import RequestActionType from '@base-utils/requestAction';

export type PaymentPageNodeIdTypes = 'paymentSuccessPageNodeId' | 'paymentFailPageNodeId' | 'paymentCancelPageNodeId';

export type PaymentPageNode = { [key in PaymentPageNodeIdTypes]?: string};

export type IRedeemVoucherState = PaymentPageNode & {
  premiumExpiry?: Date;
  premiumContentPageUrl: string;
};

export const SET_PREMIUM_EXPIRY_DATE = 'SET_PREMIUM_EXPIRY_DATE';
export const SET_PREMIUM_CONTENT_PAGE_URL = 'SET_PREMIUM_CONTENT_PAGE_URL';
export const SET_PAYMENT_PAGE_NODE_ID = 'SET_PAYMENT_PAGE_NODE_ID';

export const GET_SUCCESS_PAGE_NODE_CONTENT = new RequestActionType('GET_SUCCESS_PAGE_NODE_CONTENT');

export interface ISetPremiumExpiryDateAction {
  type: typeof SET_PREMIUM_EXPIRY_DATE;
  payload: Date;
}

export interface ISetPremiumContentUrlAction {
  type: typeof SET_PREMIUM_CONTENT_PAGE_URL;
  payload: string;
}

export interface ISetSuccessPageNodeIdAction {
  type: typeof SET_PAYMENT_PAGE_NODE_ID;
  payload: PaymentPageNode;
}

export interface IGetSuccessPageNodeContentAction {
  type: typeof GET_SUCCESS_PAGE_NODE_CONTENT.success;
  payload: string;
}

export type RedeemVoucherActionTypes =
ISetPremiumExpiryDateAction
| ISetPremiumContentUrlAction
| IGetSuccessPageNodeContentAction
| ISetSuccessPageNodeIdAction;
