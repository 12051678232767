import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import {
  VERIFY_CODE,
  UserActionTypes,
  ONBOARDING_CLOSE,
} from '@base-store/user/types';
import backendAPI from '@base-api/backendAPI';
import { InvalidCodeError } from '@base-models/errors';
import { setIsOnboardingModalEnable } from '@base-utils/browserUtils';


function* verifyCode(action: UserActionTypes) {
  try {
    // @ts-ignore
    const { connectionCode } = action.payload;
    const data = yield call(backendAPI.verifyCode, connectionCode);
    if (!data.success) {
      throw new InvalidCodeError('invalid connection code');
    }
    yield put({
      type: VERIFY_CODE.success,
    });
  } catch (e) {
    console.log('verify connection code fail', e); // eslint-disable-line
    yield put({
      type: VERIFY_CODE.fail,
      payload: {
        loadingError: e,
        data: null,
      },
    });
  }
}

function onboardingClose() {
  setIsOnboardingModalEnable('false');
}

function* patientSaga() {
  yield takeLatest(VERIFY_CODE.request, verifyCode);
  yield takeLatest(ONBOARDING_CLOSE, onboardingClose);
}

export default patientSaga;
