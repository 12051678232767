import React, { ReactNode } from 'react';
import cn from 'classnames';

import LanguageSwitch from '@base-components/LanguageSwitch';
import Watermark from '@base-components/Watermark';
import CCLogo from '@base-assets/images/logo.png';
import { IConsentPageData } from '@base-store/content/types';
import { getImageUrl } from '@base-constants/urls';
import { rtlLanguages } from '@base-constants/languages';

import './style.scss';
import { cmsApiConnection } from '@base-api/cmsApiConnection';

interface IProps {
  data: IConsentPageData
  children: ReactNode
  consentButton: ReactNode
  projectName: string
  language: string
}

const ConsentsFormContainer: React.SFC<IProps> = ({
  data,
  children,
  consentButton,
  projectName,
  language,
}) => {
  const getImgSrc = fieldName => `${getImageUrl(projectName, data.uuid, fieldName)}?token=${cmsApiConnection.authToken}&lang=${language}`;

  return (
    <div className={cn('customConsentPage-container', { 'lang-RTL': rtlLanguages.includes(language) })}>
      <div className={cn('customConsentPage', { 'customConsentPage-scaled': data.image })}>
        <div className="top-bar">
          <div className="logo-container">
            <img
              className="logo"
              src={data?.logo?.fileName ? getImgSrc('logo') : CCLogo}
              alt="logo"
            />
          </div>
          <div className="languageSwitch-container">
            <LanguageSwitch inverted />
          </div>
        </div>
        <form className="form">
          <div className="scroll">
            {data.image && (
              <img
                className="hospital-image"
                src={getImgSrc('image')}
                alt="ConnectedCare Hospital"
              />
            )}
            <div className="content">
              <h1 className="heading">
                {data.heading}
              </h1>
              <p
                className={cn(
                  'app-info',
                  (data.appInfo?.length >= 300) && 'overflow',
                )}
              >
                {data.appInfo}
              </p>
              {children}
            </div>
          </div>
          <div className="button-container">
            {consentButton}
          </div>
        </form>
        <Watermark />
      </div>
    </div>
  );
};

export default ConsentsFormContainer;
