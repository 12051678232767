
import {
  AUTHENTICATION,
  LOGOUT,
  LANGUAGE_CHANGE,
  FETCH_CONFIGURATION,
  SettingsActionTypes,
  ACTIVE_PREMIUM_UPGRADE_CHANGE,
  FONT_SIZE_CHANGE,
  FontSize,
  SHOW_APP_DIALOG,
  HIDE_APP_DIALOG,
} from '@base-store/settings/types';

export function authenticate(): SettingsActionTypes {
  return {
    type: AUTHENTICATION.request,
    payload: null,
  };
}

export function logout(): SettingsActionTypes {
  return {
    type: LOGOUT.request,
    payload: null,
  };
}

export function changeLanguage(newLanguage: string): SettingsActionTypes {
  return {
    type: LANGUAGE_CHANGE.request,
    payload: {
      language: newLanguage,
    },
  };
}

export function changeFontSize(fontSize: FontSize): SettingsActionTypes {
  return {
    type: FONT_SIZE_CHANGE.request,
    payload: {
      fontSize,
    },
  };
}

export function fetchConfiguration(): SettingsActionTypes {
  return {
    type: FETCH_CONFIGURATION.request,
    payload: null,
  };
}

export function fetchActivePremiumUpgrade(): SettingsActionTypes {
  return {
    type: ACTIVE_PREMIUM_UPGRADE_CHANGE.request,
    payload: null,
  };
}

export function showAppDialog(mainContentText: string, subText: string): SettingsActionTypes {
  return {
    type: SHOW_APP_DIALOG,
    payload: {
      dialog: {
        mainContentText,
        subText,
        show: true,
      },
    },
  };
}

export function hideAppDialog(): SettingsActionTypes {
  return {
    type: HIDE_APP_DIALOG,
    payload: {
      dialog: {
        mainContentText: '',
        subText: '',
        show: false,
      },
    },
  };
}
