/**
 * @module Node
 */

import Image from '@base-models/Image';
import {
  INode, BreadcrumbItem, IPrimitiveField,
} from './types';

/**
 * A generic class for Node, can be of any type depending on [[Schema]]
 * Constructor works as a builder (should be splitted in future probably) and
 * and maps all the fields.
 */
export class Node implements INode {
  uuid = '';

  language = 'en';

  availableLanguages: string[] = [];

  parent = '';

  isContainer = false;

  displayName = '';

  name = '';

  image: Image | null = null;

  icon: Image | null = null;

  breadcrumb: BreadcrumbItem[] = [];

  schema = { name: '' };

  fields: Record<string, IPrimitiveField> = {};
}
