/**
 * @module Folder
 */

import { OneOfTypes } from '@base-models/Data/NodeFactory';
import { Node } from './Node';

/**
 * A standard Folder node. Can be any type of the Container.
 * Stores a children data + (optionally) some content
 */
export class Folder extends Node {
  isContainer = true;

  children: OneOfTypes[] = [];
}
