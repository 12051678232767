
export const TEMPLATE_ACTION_PREFIX = 'TEMPLATE_ACTION';
export const templateActionName = (name: string) => `${TEMPLATE_ACTION_PREFIX}.${name}`;

export const TEMPLATE_NAVIGATE_TO_UUID = templateActionName('NAVIGATE_TO_UUID');
export const TEMPLATE_CHANGE_FONT_SIZE = templateActionName('FONT_SIZE_CHANGE');
export const TEMPLATE_FETCH_EPG = templateActionName('FETCH_EPG');
export const TEMPLATE_FETCH_CURRENT_CHANNEL_EPG = templateActionName('FETCH_CURRENT_CHANNEL_EPG');

export const VIDEO_PLAYER_HTML = `<video-js
controls
id="mediaPlayer"
class="vjs-default-skin vjs-big-play-centered"
>
</video-js>`;
