import {
  IAuthenticationModule,
  IBaseAuthentication,
  IAuthConfig,
  ITokenData,
} from '@base-models/Data/types';
import { JWTVerificationError } from '@base-models/errors';
import jwtDecode from 'jwt-decode';

export type AccesTokens = {
  cmsAccessToken?: string | null;
  backendAccessToken?: string | null;
  logicalApiAccessToken?: string | null;
};

export default abstract class AbstractAuthenticationModule
implements IAuthenticationModule, IBaseAuthentication {
  /**
   * Checks if the passed params are defining this type of auth flow
   * @param params - init params (URL, state, etc) from the Router
   */

  authType!: string;

  static AuthModuleStorage = 'AuthModule';
  // eslint-disable-next-line
  static ConfirmAuthFlow = (params?): boolean => false;

  // @ts-ignore
  // eslint-disable-next-line class-methods-use-this
  public get authenticated(): boolean { return false; }
  // eslint-disable-next-line
  constructor(authConfig: IAuthConfig) { }


  public init = (): Promise<boolean> => Promise.resolve(false);

  public getAccessToken = (): Promise<string> => Promise.resolve('');

  public getAccessTokens = (): Promise<AccesTokens> => Promise.resolve({});

  public authenticate = (): Promise<boolean> => Promise.resolve(false);

  private decodeAccessToken = (jwtString: string): ITokenData => {
    try {
      const tokenData: ITokenData = jwtDecode(jwtString || '');
      return tokenData;
    } catch (e) {
      throw new JWTVerificationError((e as Error).message);
    }
  };

  protected verifyAccessToken = (jwtString: string | null | undefined) => {
    try {
      const token = jwtString ?? '';
      const tokenData = this.decodeAccessToken(token);
      return this.calculateLifeTime(tokenData) > 0;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return false;
    }
  };

  private calculateLifeTime = (tokenData: ITokenData): number => {
    if (typeof tokenData.exp !== 'undefined') {
      const now = Date.now().valueOf();
      const expirationDate = parseInt(tokenData.exp, 10) * 1000;
      return Math.max(
        0,
        Math.floor((expirationDate - now) / 1000),
      ); // only return 0+, no negatives;
    }
    throw new JWTVerificationError('Token is missing the expiration date');
  };

  // Returns the amount of seconds before the token is expired
  protected getTokenLifetimeLeft = async (token: string) => {
    try {
      const decoded = this.decodeAccessToken(token);
      return this.calculateLifeTime(decoded);
      // TODO: Cover NBF part of the token if will be required
    } catch (e) {
      if (e instanceof JWTVerificationError) {
        // eslint-disable-next-line
        console.error(e);
        return 0;
      }
      // other possible errors
      return 0;
    }
  };

  abstract logout();
}
