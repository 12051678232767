import { authModule } from '@base-auth/2.0/AuthRouterProvider';
import { portalContextClient } from '@base-context/PortalContext';
import { cmsApiConnection } from './cmsApiConnection';

export type QueryResult<T> = {
  nodes: {
    elements: T;
  };
};

class CmsApi {
  graphQLRequest = async <T>(
    name: string,
    query: string,
    previewMode: boolean,
    variables?: { [key: string]: string | number },
  ) => {
    await this.getAccessTokens();
    const res = await cmsApiConnection.post(
      `${name}/graphql?version=${previewMode ? 'draft' : 'published'}`,
      {
        query,
        variables,
      },
    );
    return res.data.data as QueryResult<T>;
  };

  fetchRootNodes = async (name: string) => {
    await this.getAccessTokens();
    return cmsApiConnection.get(`${name}/`);
  };

  fetchProjectSchemas = async (name: string) => {
    await this.getAccessTokens();
    return cmsApiConnection.get(`${name}/schemas`);
  };

  private getAccessTokens = async () => {
    if (portalContextClient.isNative) {
      return cmsApiConnection.setAuthToken(
        portalContextClient.context?.accessToken!,
      );
    }
    return authModule?.getAccessTokens();
  };
}

const cmsApi = new CmsApi();

export { cmsApi };
