import { FontSize } from '@base-store/settings/types';

export const defaultFontSize = FontSize.Standard;
export const baseFontSizeKey = 'baseFontSize';
const format = /^\d{1,}\.?\d+?px$/;

export const getUserFontSize = (): FontSize => {
  const userFontSize = localStorage.getItem(baseFontSizeKey) as FontSize;

  const baseFontSize = userFontSize || defaultFontSize;
  return baseFontSize;
};

export const setBaseFontSize = (fontSize: FontSize) => {
  if (!format.test(fontSize)) {
    throw new Error(`fontSize ${fontSize} has incorrect format please use: [1-9]px`);
  }

  const baseFontSize = fontSize || getUserFontSize();
  localStorage.setItem(baseFontSizeKey, baseFontSize);
  document.documentElement.style.fontSize = baseFontSize;

  return baseFontSize;
};
