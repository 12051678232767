import Authentication from '@base-auth/Authentication';
import { displayError } from '@base-utils/Notifications';
import { history } from '@base-store/index';
import { cmsApiConnection } from '@base-api/cmsApiConnection';
import AbstractAuthenticationModule from './AbstractAuthenticationModule';

export default class UrlParamsAuthentication extends AbstractAuthenticationModule {
  static ConfirmAuthFlow = (location = window.location) => {
    const queryParams = new URLSearchParams(location.search);
    return (
      queryParams.has('username') || location.hash.includes('access_token=')
    );
  };

  static StorageKey = 'urlParamsLogin';

  private storage: WindowLocalStorage['localStorage'];

  private _authenticated: boolean = false;

  public get authenticated() {
    return this._authenticated;
  }

  constructor(authConfig, storage) {
    super(authConfig);
    this.storage = storage;
    this.authType = UrlParamsAuthentication.StorageKey;
  }

  public authenticate = async () => {
    try {
      const { hash } = window.location;
      const queryParams = new URLSearchParams(window.location.search);
      const username = queryParams.get('username');
      const password = queryParams.get('password');
      const skipConsent = queryParams.get('skipConsent');
      if (username && password) {
        Authentication.Login(username, password, '/');
      }

      if (skipConsent) {
        Authentication.GiveConsent(true);
      }

      if (hash.includes('access_token=')) {
        // redirected after login
        const tokenData = await Authentication.ParseHash(hash);
        Authentication.SaveTokenData(tokenData!);
        if (tokenData?.accessToken) {
          cmsApiConnection.setAuthToken(tokenData?.accessToken);
        }
        history.replace('/log-in');
        this._authenticated = true;
        return this._authenticated;
      }
      await Authentication.CheckSession();
      this._authenticated = Authentication.isAuthenticated;
      return this._authenticated;
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
      this._authenticated = false;
      displayError({ e: e as Error });
      throw new Error('Failed to authenticate');
    }
  };

  public getAccessToken = async () => {
    await Authentication.CheckSession();
    const accessToken = this.storage.getItem('accessToken') as string;
    cmsApiConnection.setAuthToken(accessToken);
    return accessToken;
  };

  public getAccessTokens = async () => {
    await Authentication.CheckSession();
    const cmsAccessToken = this.storage.getItem('accessToken') as string;
    cmsApiConnection.setAuthToken(cmsAccessToken);
    return {
      cmsAccessToken,
    };
  };

  public logout = () => {
    if (this.storage) {
      this.storage.removeItem(AbstractAuthenticationModule.AuthModuleStorage);
      this.storage.clear();
    }
    Authentication.Logout();
  };
}
