export const baseUrl = `${process.env.API_URL}/api/v2/`;
export const auth = 'auth/login';
export const projectsUrl = `${baseUrl}projects`;
export const getImageUrl = (projectName: string, nodeUuid: string, fieldName: string) => `${baseUrl}${projectName}/nodes/${nodeUuid}/binary/${fieldName}`;
export const defaultTemplatesUrl = '/templates/default.json';
export const mainPageUUID = 'home';
export const backendApiUrl = process.env.BACKEND_API_URL;
export const purchaseApiUrl = process.env.PURCHASES_API_URL;
export const epgApiUrl = process.env.EPG_API_URL;
export const pinApiUrl = process.env.PIN_API_URL;
export const ccAppleStoreUrl = process.env.REACT_APP_CC_APPLE_STORE_URL;
export const ccGooglePlayUrl = process.env.REACT_APP_CC_GOOGLE_PLAY_URL;
export const shareMagazinesUrl = process.env.REACT_APP_SHARE_MAGAZINES_URL;
export const notFoundUrl = '/not-found';
export const redeemVoucherSuccessUrl = '/node/redeem-voucher-success';
export const helpCentreUrl = process.env.REACT_APP_HELP_CENTRE_URL;
export const idmServiceUrl = process.env.IDM_API_URL;
