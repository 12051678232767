import PubSub from '@base-utils/PubSub';
import { store } from '@base-store/index';
import { TemplateEvent } from './templateEvent';
import eventsList from './EventsList';

export class TemplateEventHandler {
  private eventsManager: PubSub<any>; // Injected in constructor

  public eventsRunner: Record<string, Function>; // Injected in constructor

  private dispatch: Function = () => false; // Injected in runtime

  constructor(eventsManager: PubSub<any>, eventsRunner: (n: (a: any) => any) => any) {
    this.eventsManager = eventsManager;
    this.eventsManager.subscribe(this.onEvent.bind(this));
    this.eventsRunner = eventsRunner(this.emit.bind(this));
  }

  public onEvent(event: TemplateEvent) {
    this.dispatch(event);
  }

  public linkDispatch(dispatch: Function) {
    this.dispatch = dispatch;
  }

  public emit(a: TemplateEvent) {
    this.eventsManager.notify(a);
  }
}

// eslint-disable-next-line max-len
const templateEventHandler = new TemplateEventHandler(new PubSub<TemplateEvent>(), eventsList(TemplateEvent));
templateEventHandler.linkDispatch(action => store.dispatch({ ...action }));
Object.defineProperty(window, 'TemplateEvents', {
  get: () => templateEventHandler.eventsRunner,
});
