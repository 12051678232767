import { createTrackingMiddleware, trackingConnectors } from '@bewatec-berlin/cc-metrics-tools/src';
import { IEvent } from '@bewatec-berlin/cc-metrics-tools/src/types';
import { AppState } from '@base-reducers/index';
import { navigationDictionary } from './dictionaries/navigation';

const matomoConfig = {
  type: `${process.env.REACT_APP_MATOMO_URL as string}`,
  apiID: process.env.REACT_APP_MATOMO_SITE_ID as string,
};

// We shouldn't even inject the tracking scipt before we know it's required
const buildMiddleware = () => createTrackingMiddleware(
  trackingConnectors.MatomoConnector,
  matomoConfig,
  [navigationDictionary],
);

type ReduxStore = {
  getState: () => AppState
};

type Middleware =
  (store: ReduxStore) =>
  (next: (action: IEvent) => void) =>
  (action: IEvent) => void;

// By default it's disabled
let middleware: Middleware | null = null;

// A middleware that checks that tracking is enabled for this project
// and calls the tracking middleware if so.
const middlewareRunner: Middleware = store => next => action => {
  const { settings } = store.getState();
  if (settings.projectConfig.analyticsTool?.type !== null) {
    // This project is configured to use analytics
    if (middleware == null) {
      // Init the analytics, we can pass params from config here later
      middleware = buildMiddleware();
    }
    return middleware(store)(next)(action);
  }

  next(action);
};

export default middlewareRunner;
