import { Locale } from '@base-api/purchaseAPI/types';

export interface ResolveIframeParams {
  className: string
  url: string
  urlParams: string[]
  language: Locale
  errorServiceUnavailable: string
  errorMissingData: string
  allowfullscreen: boolean
  serviceType: ServiceType
}

export type ServiceCallResolverParams = Omit<ResolveIframeParams, 'serviceType'>;

export enum ServiceType {
  BestServiceCall = 'BEST_Service_Call',
  CliniserveServiceCall = 'Cliniserve_Service_Call',
}
