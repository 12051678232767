
export class AuthenticationError extends Error {
  constructor(message) {
    super(message);
    this.name = 'AuthenticationError';
  }
}

export class InitialisationError extends Error {
  constructor(message) {
    super(message);
    this.name = 'InitialisationError';
  }
}

export class NoKISAccessError extends Error {
  constructor(message) {
    super(message);
    this.name = 'NoKISAccessError';
  }
}

export class KISDataMissingError extends Error {
  constructor(message) {
    super(message);
    this.name = 'KISDataMissingError';
  }
}

export class InvalidCodeError extends Error {
  constructor(message) {
    super(message);
    this.name = 'InvalidCodeError';
  }
}

export class NoKISConnectionVerified extends Error {
  constructor(message) {
    super(message);
    this.name = 'NoKISConnectionVerified';
  }
}

export class GraphQLRequestError extends Error {
  constructor(message) {
    super(message);
    this.name = 'GraphQLRequestError';
  }
}

export class JWTVerificationError extends Error {
  constructor(message) {
    super(message);
    this.name = 'JWTVerificationError';
  }
}
