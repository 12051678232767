import { portalContextClient } from '@base-context/PortalContext';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import style from './style.module.scss';

// prettier-ignore
const NotFoundImage: FC = () => (
  <svg className={style.notFoundImage} xmlns="http://www.w3.org/2000/svg" width="240" height="240" fill="none" viewBox="0 0 240 240">
    <circle cx="120" cy="120" r="118" fill="#fff" stroke="#90ACC9" strokeWidth="2" />
    <circle cx="120.165" cy="120.165" r="97.165" fill="#E8EFF7" />
    <path stroke="#FF91A3" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M173.031 136.34l7.071 7.071M180.103 136.34l-7.072 7.071" />
    <path stroke="#F53B59" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M87.6 179.273l-8.485 8.486M87.6 187.76l-8.485-8.486" />
    <path stroke="#FF5A75" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M109.743 51.916l-2.971 2.971m-5.277 5.277l-2.971 2.971M109.743 63.135l-2.909-2.909m-5.401-5.402l-2.91-2.909" />
    <rect width="31.733" height="60.492" x="117.26" y="99.135" fill="#fff" rx="8" transform="rotate(45 117.26 99.135)" />
    <path fill="#E8EFF7" d="M131.795 113.67l2.246 2.246c3.125 3.125 3.125 8.19 0 11.314l-31.46 31.461c-3.124 3.124-8.19 3.124-11.314 0l-2.246-2.247 42.774-42.774z" />
    <path fill="#fff" d="M130.84 107.832c7.514 7.514 19.167 8.366 27.62 2.556 1.821-1.251 1.805-3.822.243-5.385l-25.035-25.034c-1.562-1.562-4.133-1.578-5.384.243-5.81 8.453-4.958 20.106 2.556 27.62z" />
    <path fill="#E8EFF7" d="M130.84 107.832c7.495 7.495 19.108 8.362 27.555 2.601 1.85-1.262 1.811-3.876.196-5.428l-4.858-4.672c-14.456 14.456-22.893 7.499-22.893 7.499z" />
    <path stroke="#90ACC9" strokeWidth="2" d="M157.894 109.564c-8.063 5.542-19.18 4.728-26.347-2.439-7.167-7.167-7.981-18.284-2.439-26.347.824-1.2 2.638-1.317 3.853-.102l25.035 25.035c1.215 1.215 1.097 3.028-.102 3.853z" />
    <circle cx="150.351" cy="87.876" r="1.975" stroke="#90ACC9" strokeWidth="2" transform="rotate(45 150.351 87.876)" />
    <circle cx="99.754" cy="128.731" r="1.488" fill="#FF5A75" transform="rotate(45 99.754 128.731)" />
    <circle cx="95.547" cy="132.939" r="1.488" fill="#F53B59" transform="rotate(45 95.547 132.939)" />
    <circle cx="91.34" cy="137.147" r="1.488" fill="#F53B59" transform="rotate(45 91.34 137.147)" />
    <circle cx="87.133" cy="141.353" r="1.488" fill="#90ACC9" transform="rotate(45 87.133 141.353)" />
    <path stroke="#E8EFF7" strokeLinecap="round" strokeWidth="2" d="M132.553 101.251c-2.431-4.403-2.861-9.616-1.29-14.306" />
    <path stroke="#90ACC9" strokeWidth="2" d="M148.948 89.278l-3.506 3.506M131.418 106.81l-3.506 3.506" />
    <path fill="#F53B59" d="M172.285 75.388c.051-.246-.027-.501-.206-.68l-3.692-3.692 5.712-5.712c.245-.245.283-.631.09-.926-.196-.3-.565-.417-.889-.295l-13.568 5.127c-.232.087-.404.29-.453.533-.05.245.027.501.205.68l3.693 3.692-5.712 5.712c-.245.245-.283.631-.09.926.03.044.062.085.098.121.205.205.513.278.791.174l13.567-5.127c.233-.09.404-.288.454-.534z" />
    <rect width="29.733" height="47.583" x="109.052" y="162.049" fill="#90ACC9" stroke="#E8EFF7" strokeWidth="2" rx="7" transform="rotate(-45 109.052 162.049)" />
    <path stroke="#E8EFF7" strokeWidth="2" d="M113.247 145.22l39.969 39.969M146.906 157.842l-21.036 21.036M155.32 166.256l-21.036 21.036M138.491 149.428l-21.036 21.036" />
    <rect width="29.733" height="47.583" x="43.138" y="96.135" fill="#90ACC9" stroke="#E8EFF7" strokeWidth="2" rx="7" transform="rotate(-45 43.138 96.135)" />
    <path stroke="#E8EFF7" strokeWidth="2" d="M53.645 85.617l39.267 39.268M80.992 91.928l-21.036 21.036M89.406 100.342L68.37 121.378M72.577 83.513l-21.036 21.036" />
    <rect width="29.733" height="58.492" x="117.26" y="100.549" stroke="#90ACC9" strokeWidth="2" rx="7" transform="rotate(45 117.26 100.549)" />
  </svg>
);

const NotFound: FC = () => (
  <div className={style.notFoundContainer}>
    <div className={style.content}>
      <NotFoundImage />
      <h1 className={style.headline} data-test-id={404}>
        Die gewünschte Seite kann nicht aufgerufen werden
      </h1>
      <p className={style.text}>
        <Link
          to={
            portalContextClient.context?.isNative
              ? portalContextClient.context.startingRoute!
              : '/'
          }
        >
          Zurück zur Startseite
        </Link>
      </p>
    </div>
  </div>
);

export default NotFound;
