import { INode } from '@base-models/Data/types';
import handlebars from 'handlebars';

const byOrder = (a: INode, b: INode) => (
  (a.fields.order?.value ?? Number.MAX_SAFE_INTEGER)
  - (b.fields.order?.value ?? Number.MAX_SAFE_INTEGER)
);

const byName = (a: INode, b: INode) => (
  a.name?.toLowerCase().localeCompare(b.name?.toLowerCase())
);

const byDate = (a: INode, b: INode) => (
  +new Date(b.fields?.time?.value) - +new Date(a.fields?.time?.value)
);

const eachSorted = (array: INode[], options: unknown) => (
  handlebars.helpers.each(array.sort(byName).sort(byOrder), options)
);

const eachSortedByTime = (array: INode[], options) => {
  const sorted = array.sort(byName).sort(byOrder).sort(byDate);
  return handlebars.helpers.each(sorted, options);
};

export { eachSorted, eachSortedByTime };
