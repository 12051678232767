type ResolvedElement = HTMLElement | HTMLInputElement | HTMLFormElement;
type PromisedElement = Promise<ResolvedElement>;

export default (selector: string, maxAttempts = 3): PromisedElement =>
  new Promise((resolve, reject) => { // eslint-disable-line
    const findNode = (attempt = 0) => {
      const node = document.querySelector<ResolvedElement>(selector);
      if (node) {
        return resolve(node);
      }
      if (attempt > maxAttempts) {
        return reject();
      }
      setTimeout(() => findNode(attempt + 1), 100);
    };
    findNode();
  });
