import {
  NavigationActionTypes,
  FETCH_NODE_CONTENT,
} from '@base-store/navigation/types';
import { ILinkedNode } from '@base-models/Data/types';

export function fetchNodeContent(node: ILinkedNode | string): NavigationActionTypes {
  return {
    type: FETCH_NODE_CONTENT.request,
    payload: node,
  };
}
