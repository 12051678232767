import React, { FC, useMemo } from 'react';
import TemplateProcessor from '@base-utils/TemplateProcessor';
import { CurrentNode } from '@base-store/navigation/types';
import { InternalNavLink } from '@base-models/Data/NavigationItem';
import { ICompiledTemplate } from '@base-models/Data/types';
import { Schema } from '@base-models/Data/Schema';
import { SchemaNames } from '@base-constants/schemas';
import { getNodeTitle } from '@base-containers/Page/getNodeTitle';
import BackButton from '@base-components/BackButton';
import LanguageSwitch from '@base-components/LanguageSwitch';
import { usePortal } from '@base-context/PortalContext';
import cn from 'classnames';
import style from './style.module.css';
import { useNavigationItemQuery } from '../../hooks/useNavigationItemQuery';
import { useAppSelector } from '../../hooks/useAppSelector';

interface IProps {
  schemas: Record<string, Schema>;
  templates: Record<string, ICompiledTemplate>;
  currentNode: CurrentNode;
}

const NavigationBar: FC<IProps> = ({ schemas, templates, currentNode }) => {
  const { data, status } = useNavigationItemQuery();
  const portal = usePortal();
  const title = useMemo(() => getNodeTitle(currentNode), [currentNode]);
  const schemasLoading = useAppSelector(state => state.schemas.loading);
  const availableLanguages = useAppSelector(
    state => state.settings.projectConfig.availableLanguages,
  );

  if (portal?.isNative && currentNode?.schema.name === SchemaNames.MainPage) {
    return <div />;
  }

  if (status === 'loading' || status === 'error' || !data || schemasLoading) {
    return <div />;
  }

  return (
    <>
      <header className="app-header">
        <div className="node-title">{title}</div>
      </header>
      {!portal?.isNative && (
        <nav className={style.navigation}>
          {data.map(item => {
            const nodeUuid = item instanceof InternalNavLink ? item.node?.uuid : null;
            return (
              <TemplateProcessor
                node={{ ...item, active: nodeUuid === currentNode?.uuid }}
                key={item.uuid}
                schema={schemas[SchemaNames.NavigationItem]}
                templates={templates}
              />
            );
          })}
        </nav>
      )}
      {availableLanguages.length > 1 && (
        <LanguageSwitch className="app-language-switch" />
      )}
      <div className={cn(style.buttonContainer, 'app-back-button-wrapper')}>
        <BackButton navigationItems={data} />
      </div>
    </>
  );
};

export default NavigationBar;
