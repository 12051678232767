import React from 'react';
import { Transition } from 'react-transition-group';

import './style.scss';

interface NotificationProps {
  show: boolean;
  timeout?: number;
}

const defaultStyle = {
  transition: 'all 300ms ease-in-out',
  opacity: 0,
};

const transitionStyles = {
  entering: { opacity: 1, transform: 'translateX(0px)' },
  entered: { opacity: 1, transform: 'translateX(0px)' },
  exiting: { opacity: 0, transform: 'translateX(80px)' },
  exited: { opacity: 0, transform: 'translateX(80px)' },
};

const Notification: React.FC<NotificationProps> = ({
  children,
  show,
  timeout,
}) => {
  const [shouldShow, setShouldShow] = React.useState(show);

  React.useEffect(() => {
    if (show) {
      setShouldShow(true);
    }
  }, [show]);

  React.useEffect(() => {
    if (shouldShow && timeout) {
      const timer = setTimeout(() => {
        setShouldShow(false);
      }, timeout);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [shouldShow]);

  return (
    <div className="notification-container" onClick={() => setShouldShow(false)}>
      <Transition in={shouldShow} timeout={1000} unmountOnExit>
        {state => (
          <div style={{ ...defaultStyle, ...transitionStyles[state] }}>
            <div className="notification">{children}</div>
          </div>
        )}
      </Transition>
    </div>
  );
};

export default Notification;
