import { setProgramVerification } from '@base-actions/programVerification';
import { store } from '@base-store/index';

export class ProgramVerificationManager {
  public static storeProgramVerification(programId: string, channelId: string) {
    store.dispatch(setProgramVerification(programId, channelId));
  }

  public static isProgramVerified(programId: string, channelId: string) {
    const verifiedPrograms = store.getState().programVerification
      .verifiedPrograms as Record<string, string>;
    return verifiedPrograms[channelId] === programId;
  }
}
