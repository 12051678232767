
export interface IRequestActionType {
  request: string;
  success: string;
  fail: string;
}

/**
 * Represents the typical ReduxAction for fetching data.
 * Contains 3 sub-actions - request, success and fail
 * @param name Basic name of the action, will be used as prefix
 */
export default class RequestActionType implements IRequestActionType {
  public request: string;

  public success: string;

  public fail: string;

  constructor(name: string) {
    this.request = `${name}_REQUEST`;
    this.success = `${name}_SUCCESS`;
    this.fail = `${name}_FAIL`;
  }
}
