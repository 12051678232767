import React, { useEffect, useRef } from 'react';
import {
  INode,
  ISchema,
  ICompiledTemplate,
  INavigationItem,
} from '@base-models/Data/types';
import { displayError } from '@base-utils/Notifications';
import { injectTemplate } from './injector';

export interface ITemplateProcessorProps {
  node: INode | INavigationItem;
  schema: ISchema;
  templates: Record<string, ICompiledTemplate>;
}

function TemplateProcessor({
  node,
  schema,
  templates,
}: ITemplateProcessorProps) {
  const domNode = useRef(null);

  useEffect(() => {
    const template = templates[schema?.name];
    if (!template) {
      displayError({ e: `No template found for ${schema?.name}` });
    } else {
      const targetNode = domNode.current as HTMLDivElement | null;
      if (targetNode !== null) {
        injectTemplate(targetNode, template, node);
      }
    }
  }, [node]);
  return <div ref={domNode} />;
}

export default TemplateProcessor;
