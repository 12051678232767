import { setCurrentlyPlayingVideo } from '@base-actions/mediaViewer';
import { SchemaNames } from '@base-constants/schemas';
import { VIDEO_PLAYER_HTML } from '@base-constants/templates';
import { IImage } from '@base-models/Data/types';
import { store } from '@base-store/index';
import { startMediaPlayback } from '@base-utils/MediaPlayer';
import handlebars from 'handlebars';
import { skyPinVerificationEnabled } from '../../../feature-toggles-init';
import { loadImage } from './loadImage';
import { triggerPinVerification } from './verifySkyPin';

export interface IMediaOptions {
  src: string;
  autoplay?: boolean | string;
  type?: string;
}

export interface IVideoOptions extends IMediaOptions {
  channelId?: string
  schema: string
}

export interface IAudioOptions extends IMediaOptions {
  uuid: string;
  image: IImage;
}

export interface IPlayerOptions extends IAudioOptions {
  schema: string;
  channelId?: string;
}

export const renderMediaViewerVideoPlayer = ({
  src,
  autoplay,
  type,
  channelId,
  schema,
}: IVideoOptions) => {
  store.dispatch(setCurrentlyPlayingVideo({
    src, autoplay, type, schema,
  }));
  triggerPinVerification(channelId as string, () => {
    startMediaPlayback({
      src, autoplay, type, schema,
    });
  });
  return new handlebars.SafeString(VIDEO_PLAYER_HTML);
};

export const renderVideoPlayer = ({
  src,
  autoplay,
  type,
  schema,
}: IVideoOptions) => {
  startMediaPlayback({
    src, autoplay, type, schema,
  });
  return new handlebars.SafeString(VIDEO_PLAYER_HTML);
};

export const renderAudioPlayer = ({
  src,
  autoplay,
  uuid,
  image,
}: IAudioOptions) => new handlebars.SafeString(`
  ${loadImage(uuid, image, 'radio-banner')}
  <audio
    id="mediaPlayer"
    controls class="radio-player"
    autoplay=${autoplay}
  >
    <source src="${src}">
  </audio>
`);
// todo change banner

export const renderYoutubePlayer = ({
  src,
}: IMediaOptions) => new handlebars.SafeString(`
  <iframe
    id="mediaPlayer"
    class="video-player youtube-player"
    src=${src}
    frameborder="0"
    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
  >
  </iframe>
`);

export const renderMediaPlayer = ({
  src,
  autoplay,
  type,
  schema,
  uuid,
  image,
  channelId,
}: IPlayerOptions) => {
  switch (schema) {
    case SchemaNames.MediaVideo:
      if (skyPinVerificationEnabled()) {
        return renderMediaViewerVideoPlayer({
          src, autoplay, type, channelId, schema,
        });
      }
      return renderVideoPlayer({
        src, autoplay, type, schema,
      });
    case SchemaNames.MediaAudio:
      return renderAudioPlayer({
        src, autoplay, uuid, image,
      });
    case SchemaNames.MediaYTVideo:
      return renderYoutubePlayer({ src });
    default:
      return null;
  }
};
