import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';

import {
  FETCH_EPG,
  FETCH_CURRENT_CHANNEL_EPG,
  IFetchCurrentChannelEpgAction,
} from '@base-store/epg/types';
import {
  TEMPLATE_FETCH_EPG,
  TEMPLATE_FETCH_CURRENT_CHANNEL_EPG,
} from '@base-constants/templates';
import { Notifications } from '@base-constants/notifications';
import { displayError } from '@base-utils/Notifications';
import epgAPI from '@base-api/epgAPI';

function* fetchEpgData() {
  try {
    const data = yield call(epgAPI.fetchEpg);
    if (data.message) {
      const { message } = data;
      yield call(displayError, { message });
      yield put({
        type: FETCH_EPG.fail,
        payload: data.message,
      });
    } else {
      yield put({
        type: FETCH_EPG.success,
        payload: {
          epgData: data,
        },
      });
    }
  } catch (e) {
    const message = Notifications.epgLoadErr;
    yield call(displayError, { message, e });
    yield put({
      type: FETCH_EPG.fail,
      payload: message,
    });
  }
}

function* fetchCurrentChannelEpgData(action: IFetchCurrentChannelEpgAction) {
  try {
    const channelId = action.payload?.channelId as string;
    const data = yield call(epgAPI.fetchCurrentChannelEpg, channelId);
    if (data.message) {
      const { message } = data;
      yield call(displayError, { message });
      yield put({
        type: FETCH_CURRENT_CHANNEL_EPG.fail,
        payload: data.message,
      });
    } else {
      yield put({
        type: FETCH_CURRENT_CHANNEL_EPG.success,
        payload: {
          currentChannelEpgData: data,
        },
      });
    }
  } catch (e) {
    const message = Notifications.epgCurrentLoadErr;
    yield call(displayError, { message, e });
    yield put({
      type: FETCH_CURRENT_CHANNEL_EPG.fail,
      payload: message,
    });
  }
}

function* epgSaga() {
  yield takeLatest(FETCH_EPG.request, fetchEpgData);
  yield takeLatest(TEMPLATE_FETCH_EPG, fetchEpgData);
  yield takeLatest(FETCH_CURRENT_CHANNEL_EPG.request, fetchCurrentChannelEpgData);
  yield takeLatest(TEMPLATE_FETCH_CURRENT_CHANNEL_EPG, fetchCurrentChannelEpgData);
}

export default epgSaga;
