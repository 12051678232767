
import { IPatient, IHospitalization, IKISAuthentication } from '@base-models/Data/types';

export default class KISDataProvider {
  public constructor(protected authentication: IKISAuthentication) {}

  /**
   * Resolves KIS data from 2 endpoints, merges it
   * @throws if the core patient data request failed
   */
  public getKISData = async (kisApiUrl: string): Promise<IPatient | null> => {
    try {
      await this.authentication.authenticate();
      const [patientData, hospitalization] = await Promise.all([
        this.getPatientData(kisApiUrl),
        this.getHospitalizationData(kisApiUrl),
      ]);

      if (patientData === null) {
        throw new Error('KIS data fetch failed');
      }
      const patient = {
        ...patientData,
        hospitalization,
      };

      return patient;
    } catch (e) {
      return null;
    }
  };

  protected getRequestHeaders(): Headers {
    return new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: `Bearer ${this.authentication.getAccessToken()}`,
      'X-Device-ID': '42',
    });
  }

  protected getPatientData = async (kisApiUrl: string): Promise<IPatient | null> => {
    try {
      const response = await fetch(
        `${kisApiUrl}/patient`,
        {
          headers: this.getRequestHeaders(),
        },
      );
      return await response.json();
    } catch (e) {
      return null;
    }
  };

  protected getHospitalizationData = async (
    kisApiUrl: string,
  ): Promise<IHospitalization | null> => {
    try {
      const response = await fetch(
        `${kisApiUrl}/hospitalization`,
        {
          headers: this.getRequestHeaders(),
        },
      );
      return await response.json();
    } catch (e) {
      return null;
    }
  };
}
