import {
  PatientActionTypes,
  FETCH_PATIENT_KIS_DATA,
  FETCH_PATIENT_ME,
} from '@base-store/patient/types';

export function fetchPatientKISData(): PatientActionTypes {
  return {
    type: FETCH_PATIENT_KIS_DATA.request,
    payload: {
      loadingError: null,
      data: null,
    },
  };
}

export function fetchPatientMe(): PatientActionTypes {
  return {
    type: FETCH_PATIENT_ME.request,
    payload: {
      loadingError: null,
      data: null,
    },
  };
}
