import React, { FC } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import ReactCodeInput from 'react-verification-code-input';

import Button from '@base-components/Button';
import Spinner from '@base-components/Spinner';
import ErrorDialog from '@base-components/ErrorDialog';
import { AppState } from '@base-reducers/index';
import { verifyCode as verifyCodeAction } from '@base-actions/user';
import logo from '@base-assets/images/logo.png';

import styles from './style.module.scss';
import './body-overflow-fix.scss';

const CODE_LENGTH = 6;

interface ConnectionCodeProps {
  verifyCode: typeof verifyCodeAction;
  loading: boolean;
  onAddLaterClick: Function;
  error: boolean | null;
  isFirstTime?: boolean;
}

const ConnectionCode: FC<ConnectionCodeProps> = ({
  verifyCode,
  loading,
  onAddLaterClick,
  error,
  isFirstTime,
}) => {
  const [code, setCode] = React.useState<string>('');

  function onCodeInput(value) {
    setCode(value);
  }

  React.useEffect(() => {
    if (code.length === CODE_LENGTH) {
      verifyCode(code);
    }
  }, [code]);

  return (
    <div className={styles.container}>
      {!isFirstTime ? (
        <p className={styles.title}>Action required</p>
      ) : (
        <img src={logo} alt="connected-care-logo" className={styles.logo} />
      )}
      <div>
        <h2 className={styles.subtitle}>Connect your Personal Code</h2>
        {isFirstTime ? (
          <div className={styles.content}>
            <p>
              Entering your Personal Connection code now will give you immediate access to
              additional services, such as food ordering and your treatment calendar.
            </p>
            <p>You can add your Personal code later in Settings.</p>
          </div>
        ) : null}
        <div className={styles.codeContainer}>
          <ReactCodeInput
            className={styles.codeInput}
            fields={CODE_LENGTH}
            autoFocus
            onChange={onCodeInput}
          />
        </div>
        {error ? (
          <ErrorDialog text="The code you entered doesn't match your account. Please try again." />
        ) : null}
        <p className={classNames(styles.paragraph, styles.info)}>
          You can find your 6-digit Personal Connection Code on a slip received from the Front Desk
          during your admission.
        </p>
        <div className={styles.actions}>
          <Button id="enterCode" className={styles.button} disabled={code.length !== CODE_LENGTH}>
            {loading ? <Spinner small /> : 'Continue'}
          </Button>
          <Button id="skipCode" className={styles.skip} onClick={onAddLaterClick}>
            {isFirstTime ? 'Add later' : 'Cancel'}
          </Button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: state.user.loading,
  error: state.user.error,
});

const mapDispatchToProps = {
  verifyCode: verifyCodeAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectionCode);
