import {
  IMediaViewerState,
  MediaViewerActionTypes,
  SET_CURRENTLY_PLAYING_VIDEO,
  SET_CURRENT_AGE_RESTRICTION,
} from '@base-store/mediaViewer/types';

const initialState: IMediaViewerState = {
  currentlyPlayingVideo: null,
  currentAgeRestriction: 0,
};

export default (state = initialState, action: MediaViewerActionTypes): IMediaViewerState => {
  switch (action.type) {
    case SET_CURRENTLY_PLAYING_VIDEO: {
      return {
        ...state,
        currentlyPlayingVideo: action.payload,
      };
    }

    case SET_CURRENT_AGE_RESTRICTION: {
      return {
        ...state,
        currentAgeRestriction: action.payload,
      };
    }

    default:
      return state;
  }
};
