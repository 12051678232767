import RequestActionType from '@base-utils/requestAction';
import { IImage } from '@base-models/Data/types';

export interface IConsentPageData {
  uuid: string
  name: string
  displayName: string
  order: number
  image: IImage
  logo: IImage
  heading: string
  appInfo: string
  buttonText: string
  ppCheckbox: string
  ppLink: string
  ppCheckboxHidden: boolean
  tcCheckbox: string
  tcLink: string
  tcCheckboxHidden: boolean
}

export interface IContentState {
  consentPageData: IConsentPageData | null
  loading: boolean
  loadingError: string | null
  previewMode: boolean
}

export const FETCH_CONSENT_PAGE = new RequestActionType('FETCH_CONSENT_PAGE');

export const TOGGLE_PREVIEW_MODE = 'TOGGLE_PREVIEW_MODE';
export interface FetchConsentAction {
  type: typeof FETCH_CONSENT_PAGE.request
  payload: Partial<IContentState> | null
}

export interface TogglePreviewModeAction {
  type: typeof TOGGLE_PREVIEW_MODE
  payload: boolean;
}

export type ContentActionTypes = FetchConsentAction | TogglePreviewModeAction;
