import { EpgData, IEpgChannelData } from '@base-models/Data/types';
import RequestActionType from '@base-utils/requestAction';

export interface IEpgState {
  channelId: string
  epgData: EpgData | null
  currentChannelEpgData: IEpgChannelData | null
  loading: boolean
  error: string | null
}

export const FETCH_EPG = new RequestActionType('FETCH_EPG');
export const FETCH_CURRENT_CHANNEL_EPG = new RequestActionType('FETCH_CURRENT_CHANNEL_EPG');

export interface IFetchEpgAction {
  type: typeof FETCH_EPG.request
  payload: Partial<IEpgState> | null
}

export interface IFetchCurrentChannelEpgAction {
  type: typeof FETCH_CURRENT_CHANNEL_EPG.request
  payload: Partial<IEpgState> | null
}

export type EpgActionTypes = IFetchEpgAction
| IFetchCurrentChannelEpgAction;
