import Authentication from '@base-auth/Authentication';
import ServiceAuthentication from '@base-auth/ServiceAuthentication';
import { Services } from '@base-auth/ServiceAuthentication/Services';
import { getLocalStorage } from '@base-utils/browserUtils';
import { store } from '@base-store/index';
import BestServiceCallAPIConfig from './BESTConfig';
import BestServiceCallKISDataProvider from './BestServiceCallKISDataProvider';

export const bestAuth = new ServiceAuthentication(
  getLocalStorage(), BestServiceCallAPIConfig, Services.Best,
);

Authentication.OnBeforeLogout(() => bestAuth.clearServiceConsent());
Authentication.OnLogin(() => bestAuth.clearServiceConsent());

const createBestServiceCallAPI = () => new BestServiceCallKISDataProvider(bestAuth, store);

export default createBestServiceCallAPI;
