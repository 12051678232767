import BackendTokenAuthentication from '@base-auth/2.0/BackendTokenAuthentication';
import { backendApiUrl } from '@base-constants/urls';
import { Services } from '@base-models/Data/Services';
import {
  IBackendAPIAuthentication,
  IHospitalData,
  IServiceAuthData,
  IErrorResponse,
  ISuccessResponse,
  IPatient,
} from '@base-models/Data/types';
import { getHospitalId } from './tokenDecode';

export default class BackendAPIDataProvider {
  public hospitalData: IHospitalData | null = null;

  public constructor(private authentication: IBackendAPIAuthentication) {}

  public getHospitalData = async (): Promise<IHospitalData> => {
    if (this.hospitalData !== null) {
      return this.hospitalData;
    }
    await this.authentication.authenticate();
    const hospitalId = getHospitalId(this.authentication.getAccessToken() as string);
    const response = await fetch(
      `${backendApiUrl}/hospital/${hospitalId}`,
      {
        headers: this.getRequestHeaders(),
      },
    );
    const hospitalData: IHospitalData = await response.json();
    this.hospitalData = hospitalData;
    return hospitalData;
  };

  public verifyCode = async (
    connectionCode: string,
  ): Promise<ISuccessResponse | IErrorResponse> => {
    await this.authentication.authenticate();
    const response = await fetch(`${backendApiUrl}/connection/connect`, {
      body: JSON.stringify({
        connectionCode,
      }),
      headers: this.getRequestHeaders(),
      method: 'POST',
    });
    const result = await response.json();
    return result;
  };

  public getMeData = async (): Promise<IPatient> => {
    if (BackendTokenAuthentication.isSharedLogin()) {
      throw new Error('This api is not available for shared login');
    }
    await this.authentication.authenticate();
    const response = await fetch(`${backendApiUrl}/patient/me`, {
      headers: this.getRequestHeaders(),
    });
    const result = await response.json();
    if (result.permissions) {
      result.permissions = result.permissions.map(permission => permission.permissionName);
    }
    if (result.roles) {
      result.roles = result.roles.map(role => role.name.toLowerCase());
    }
    return result;
  };

  public getServiceAuthData = async (
    hospitalId: string,
    service: Services,
  ): Promise<IServiceAuthData | IErrorResponse> => {
    const response = await fetch(`${backendApiUrl}/hospital/${hospitalId}/services/${service}/auth`, {
      headers: this.getRequestHeaders(),
    });
    const result = await response.json();
    return result;
  };

  private getRequestHeaders(): Headers {
    return new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: `Bearer ${this.authentication.getAccessToken()}`,
      'X-Device-ID': '42',
    });
  }
}
