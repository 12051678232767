import decodeJWT from 'jwt-decode';

export const getHospitalId = (token: string) => {
  const tokenData = decodeJWT(token) as Record<string, string>;
  const hospitalIDKey = Object.keys(tokenData).find(key => key.includes('/hospital.id'));
  if (!hospitalIDKey) {
    throw new Error('No hospitalID found in the token');
  }
  return tokenData[hospitalIDKey] as string;
};

export const getNamefromToken = (idToken: string) => {
  const tokenData = decodeJWT(idToken) as Record<string, string>;

  return {
    firstName: tokenData.given_name?.trim() || '',
    lastName: tokenData.family_name?.trim() || '',
  };
};

export const getOrganizationIdFormToken = (idToken: string) => {
  const tokenData = decodeJWT(idToken) as Record<string, string>;
  return tokenData.org_id;
};
