export interface IService {
  scope: string
  storageKey: string
  name: string
}

export const Services = {
  Best: {
    name: 'bestServiceCall',
    scope: 'patient:get hospitalization:get',
    storageKey: 'bestConsentGiven',
  },
  Cliniserve: {
    name: 'cliniserveServiceCall',
    scope: 'patient:get hospitalization:get',
    storageKey: 'cliniserveConsentGiven',
  },
};
