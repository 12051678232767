import { getImageUrl } from '@base-constants/urls';
import { IImage, IRawImage } from './Data/types';

/**
 * Represents Image object, contains all the data required to display it.
 * Resolves image URL automatically
 * Used to map raw objects coming from graphQL
 */
export default class Image implements IImage {
  url = '';

  fileName = '';

  dominantColor = '#fff';

  width = 0;

  height = 0;

  public constructor(projectName: string, rawImage: IRawImage, nodeUuid: string, fieldName = 'image') {
    this.fileName = rawImage.fileName;
    this.dominantColor = rawImage.dominantColor ? rawImage.dominantColor : '#fff';
    this.width = rawImage.width;
    this.height = rawImage.height;
    this.url = getImageUrl(projectName, nodeUuid, fieldName);
  }
}
