import image from './image';

export default `
... on navigationItem {
  fields {
      ${image}
      name
      order
      url
      displayName
      node {
        uuid
        schema {
          name
        }
      }
  }
}
`;
