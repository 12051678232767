/**
 * @param fontFamilyName  Param that defines name that will be used for the font in css
 * @param path  Param that defines font to be used and its path i.e. Muli-Light.ttf
 * @param format  Param that defines format type for included font i.e. truetype
 * @param fontWeight  Param that defines font weight
 */

type FontWeight = 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;

interface injectParams {
  fontFamilyName: string;
  path: string;
  format: string;
  fontWeight?: FontWeight;
}

function injectFontFace({
  fontFamilyName,
  path,
  format,
  fontWeight,
}: injectParams): void {
  const bucketUrl = process.env.FONTS_URL;
  const newStyle = document.createElement('style');
  newStyle.appendChild(
    document.createTextNode(
      `\
      @font-face {
        font-family: ${fontFamilyName};
        font-weight: ${fontWeight};
        src: url('${bucketUrl}${path}') format('${format}');
      }
  `,
    ),
  );
  document.head.appendChild(newStyle);
}

/**
 * @function loadFonts Injects custom generated font-face into document head tag
 */

function loadFonts(): Promise<void> {
  injectFontFace({
    fontFamilyName: 'Muli',
    path: '/Muli/Muli-Regular.ttf',
    format: 'truetype',
    fontWeight: 100,
  });
  injectFontFace({
    fontFamilyName: 'Muli',
    path: '/Muli/Muli-Bold.ttf',
    format: 'truetype',
    fontWeight: 800,
  });
  injectFontFace({
    fontFamilyName: 'SG-Muli',
    path: '/Muli/Muli-Regular.ttf',
    format: 'truetype',
  });
  injectFontFace({
    fontFamilyName: 'SG-Muli-Bold',
    path: '/Muli/Muli-Bold.ttf',
    format: 'truetype',
  });
  if ('fonts' in document) {
    // @ts-ignore
    return document.fonts.ready;
  }
  return Promise.reject();
}

export default loadFonts();
