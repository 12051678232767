import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, AnyAction } from 'redux';
import cn from 'classnames';

import NavigationBar from '@base-components/NavigationBar';
import Preloader from '@base-components/Preloader';
import { authenticate } from '@base-actions/settings';
import { AppState } from '@base-reducers/index';
import { CurrentNode } from '@base-store/navigation/types';
import { Schema } from '@base-models/Data/Schema';
import {
  INavigationItem,
  ICompiledTemplate,
} from '@base-models/Data/types';
import PreviewModeNotification from '@base-components/PreviewModeNotification';
import Onboarding from '@base-components/Onboarding';
import ContentDialog from '@base-components/Dialog';
import { SchemaNames } from '@base-constants/schemas';
import { rtlLanguages } from '@base-constants/languages';
import SWManager from '@base-utils/SWManager';
import { getCurrentHlsStream, detroyCurrentHlsStream } from '@base-utils/MediaPlayer';
import style from './app.module.css';
import Nodes from './nodes';

interface IProps {
  login: Function;
  language: string;
  availableLanguages: string[];
  navigationItems: (INavigationItem)[];
  loadingNavigation: boolean;
  loadingContent: boolean;
  loadingSchemas: boolean;
  currentNode: CurrentNode;
  schemas: Record<string, Schema>;
  templates: Record<string, ICompiledTemplate>;
  history: {
    push: (url: string) => void;
    go: (url: string) => void;
    goBack: () => void;
  };
  globalStyle: string;
  hasVerifiedCode: boolean;
  location: Location;
}

export class App extends React.Component<IProps> {
  componentDidMount() {
    const { login } = this.props;
    login();
    SWManager.resetCache();
  }

  componentDidUpdate(prevProps) {
    const { currentNode: prevNode } = prevProps;
    const { currentNode } = this.props;
    if (getCurrentHlsStream() && currentNode?.uuid !== prevNode?.uuid) {
      detroyCurrentHlsStream();
    }
  }

  getPreloader() {
    const { loadingNavigation, loadingContent } = this.props;
    const isHidden = !(loadingContent || loadingNavigation);
    return (
      <Preloader
        className={cn(style.preloader, 'app-preloader')}
        hidden={isHidden}
      />
    );
  }

  render() {
    const {
      loadingSchemas,
      currentNode,
      schemas,
      templates,
      globalStyle,
      language,
      location,
    } = this.props;
    const isMainPage = currentNode && currentNode.schema.name === SchemaNames.MainPage;
    return (
      <div className={cn(
        style.app,
        'app-container',
        { 'lang-RTL': rtlLanguages.includes(language) },
        { [style.loading]: loadingSchemas },
        { 'home-page': isMainPage },
      )}
      >
        <NavigationBar
          currentNode={currentNode}
          schemas={schemas}
          templates={templates}
        />
        <main className={cn(style.contentArea, 'app-content-area')}>
          {this.getPreloader()}
          <Nodes />
          <PreviewModeNotification location={location} />
        </main>
        <style>{globalStyle}</style>
        {isMainPage && <Onboarding />}
        <ContentDialog />
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  language: state.settings.language,
  loadingContent: state.navigation.loadingContent,
  currentNode: state.navigation.currentNode,
  loadingSchemas: state.schemas.loading,
  schemas: state.schemas.schemas,
  templates: state.settings.projectTemplates.templates as Record<string, ICompiledTemplate>,
  globalStyle: state.settings.projectTemplates.globalStyle,
  hasVerifiedCode: state.user.hasVerifiedCode,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators(
  {
    login: authenticate,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(App);
