import Portals from '@ionic/portals';

const OPEN_CHECKOUT = '@global:open_checkout';
const OPEN_IN_APP_BROWSER = '@global:open_in_app_browser';

const openCheckoutNativeScreen = (productId: string) => {
  Portals.publish({ topic: OPEN_CHECKOUT, data: { productId } });
};

const openInAppBrowser = (url: string) => {
  Portals.publish({ topic: OPEN_IN_APP_BROWSER, data: { url } });
};


export { openCheckoutNativeScreen, openInAppBrowser };
