import handlebars from 'handlebars';
import LanguageProvider from '@base-utils/LanguageProvider';
import { IImage } from '@base-models/Data/types';
import { cmsApiConnection } from '@base-api/cmsApiConnection';

const MAX_IMAGE_WIDTH_PX = 2000;

const getImageUrl = (
  image: IImage,
  fixedWidth?: number,
  supportRetina: boolean = true,
) => {
  // If no fixed width provided - limit it with browser window width,
  // capping with 2000px (limit set in CMS is 2048)
  let imageWidth = Math.min(window.innerWidth, MAX_IMAGE_WIDTH_PX);
  if (fixedWidth && typeof fixedWidth === 'number') {
    imageWidth = fixedWidth;
    // For higher pixel-density displays we need proportionally bigger resolution
    // This will also give a good support for browsers built-in zoom
    if (typeof supportRetina === 'boolean' && supportRetina) {
      imageWidth *= window.devicePixelRatio;
    }
  }
  const token = cmsApiConnection.authToken;
  const url = image?.url;
  const lang = LanguageProvider.currentLanguage;
  return `${url}?lang=${lang}&w=${imageWidth}&token=${token}`;
};


const loadImage = (
  uuid: string,
  image: IImage,
  className: string,
  tag?: string,
  fixedWidth?: number,
  supportRetina: boolean = true,
) => {
  // If no fixed width provided - limit it with browser window width,
  // capping with 2000px (limit set in CMS is 2048)
  const url = getImageUrl(image, fixedWidth, supportRetina);
  const tagVal = typeof tag !== 'string' ? 'div' : tag;
  const classname = typeof className !== 'string' ? '' : className;
  return new handlebars.SafeString(`
    <${tagVal}
      style='background-image: url(${url})'
      class='${classname}'
      id='image-${uuid}'
    >
    </${tagVal}>
  `);
};

export { loadImage, getImageUrl };
