import { pinApiUrl } from '@base-constants/urls';
import {
  IPinAPIAuthentication, IPinValidityResponse, PinValidity, IGetPersonalPinResponse, PIN_NOT_FOUND,
} from '@base-models/Data/types';

export default class PinAPIDataProvider {
  public constructor(private authentication: IPinAPIAuthentication) {}

  public verifyPin = async (
    pin: string,
    programAgeRestriction: number,
    hospitalId: string): Promise<PinValidity> => {
    await this.authentication.authenticate();

    const response = await fetch(
      `${pinApiUrl}/pin/verify?pin=${pin}&ageRestriction=${programAgeRestriction}&hospitalId=${hospitalId}`,
      {
        headers: this.getRequestHeaders(),
      },
    );

    const pinValidityResponse: IPinValidityResponse = await response.json();
    return pinValidityResponse.validity;
  };

  public getPersonalPin = async (): Promise<string> => {
    await this.authentication.authenticate();

    const response = await fetch(
      `${pinApiUrl}/pin`,
      {
        headers: this.getRequestHeaders(),
      },
    );

    const pinValidityResponse: IGetPersonalPinResponse = await response.json();
    if (pinValidityResponse.name === 'NotFoundError') {
      return PIN_NOT_FOUND;
    }
    return pinValidityResponse.pin;
  };

  private getRequestHeaders(): Headers {
    return new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: `Bearer ${this.authentication.getAccessToken()}`,
    });
  }
}
