/**
 * @module NodeFactory
 */
import { SchemaNames } from '@base-constants/schemas';
import { resolvePlaceholder } from '@base-utils/PlaceholderValueTools/placeholderValueResolver';
import { displayError } from '@base-utils/Notifications';
import { InternalNavLink, ExternalNavLink } from './NavigationItem';
import { Node } from './Node';
import { Folder } from './Folder';
import { Schema } from './Schema';
import {
  IRawNavigationItem,
  IRawNode,
  IInternalNavLink,
  IExternalNavLink,
} from './types';
import { PrimitiveField } from './Primitives';
import Image from '../Image';

export type OneOfRawTypes = IRawNavigationItem | IRawNode;
export type OneOfNavigationItems = IInternalNavLink | IExternalNavLink;
export type OneOfTypes = OneOfNavigationItems | Node | Folder;

/**
 * Factory for nodes bulk-building.
 * Splits the nodes by 4 types: [[InternalNavLink]],[[ExternalNavLink]], [[Folder]], [[Node]]
 */
export class NodeFactory {
  static schemas: Record<string, Schema> = {};

  static InitNode(projectName: string, Class: new (a: IRawNode) => Node | Folder, item: IRawNode) {
    const newNode = new Class(item);
    const {
      uuid,
      language,
      availableLanguages,
      parent,
      isContainer,
      displayName,
      breadcrumb,
      schema,
      fields,
    } = item;
    newNode.uuid = uuid;
    newNode.language = language;
    newNode.availableLanguages = availableLanguages;
    newNode.parent = parent.uuid;
    newNode.isContainer = isContainer;
    newNode.displayName = displayName;
    newNode.breadcrumb = breadcrumb;
    newNode.name = fields.name;
    newNode.schema = schema;
    const nodeSchema = NodeFactory.schemas[item.schema.name];
    if (!!fields.image) {
      newNode.image = new Image(projectName, fields.image, uuid);
    }
    if (!!fields.icon) {
      newNode.icon = new Image(projectName, fields.icon, uuid, 'icon');
    }
    newNode.fields = nodeSchema.fields.reduce(
      (result, fieldSchema) => ({
        ...result,
        [fieldSchema.name]: new PrimitiveField(
          projectName,
          fields[fieldSchema.name],
          fieldSchema,
          newNode,
        ),
      }),
      {},
    );
    // eslint-disable-next-line no-restricted-syntax
    for (const field of Object.values(newNode.fields)) {
      if (field.type === 'string') {
        field.value = resolvePlaceholder(field.value);
      }
    }
    return newNode;
  }

  static BuildInternalNavLink(projectName: string, item: IRawNavigationItem): InternalNavLink {
    const newItem = new InternalNavLink();
    const { name, image, node } = item.fields;
    newItem.title = name;
    newItem.uuid = item.uuid;
    newItem.language = item.language;
    newItem.node = node;
    if (!!image) {
      newItem.image = new Image(projectName, image, item.uuid);
    }
    return newItem;
  }

  static BuildExternalNavLink(projectName: string, item: IRawNavigationItem): ExternalNavLink {
    const newItem = new ExternalNavLink();
    const { name, image, url } = item.fields;
    newItem.title = name;
    newItem.uuid = item.uuid;
    newItem.language = item.language;
    newItem.url = url;
    if (!!image) {
      newItem.image = new Image(projectName, image, item.uuid);
    }
    return newItem;
  }

  static BuildFolder(projectName: string, item: IRawNode): Folder {
    const newNode = NodeFactory.InitNode(projectName, Folder, item) as Folder;
    return newNode;
  }

  static BuildNode(projectName: string, item: IRawNode): Node {
    const newNode = NodeFactory.InitNode(projectName, Node, item) as Node;
    return newNode;
  }

  static BuildNavigationItem(projectName: string, item: IRawNavigationItem): OneOfNavigationItems {
    if (item.fields.url) {
      return NodeFactory.BuildExternalNavLink(projectName, item as IRawNavigationItem);
    }
    return NodeFactory.BuildInternalNavLink(projectName, item as IRawNavigationItem);
  }

  static MapBySchema(
    projectName: string,
    item: OneOfRawTypes,
  ): OneOfTypes {
    try {
      if (item.schema.name === SchemaNames.NavigationItem) {
        return NodeFactory.BuildNavigationItem(projectName, item as IRawNavigationItem);
      }
      if (item.schema.name === SchemaNames.ContentPointer && !!item.fields.node) {
        // Recursively dive inside
        const node = item.fields.node as IRawNode;
        if (typeof item.fields.order !== 'undefined' && item.fields.order !== null) {
          node.fields.order = item.fields.order;
        }
        return NodeFactory.MapBySchema(projectName, node);
      }
      if (item.isContainer) {
        return NodeFactory.BuildFolder(projectName, item as IRawNode);
      }
      return NodeFactory.BuildNode(projectName, item as IRawNode);
    } catch (e) {
      displayError({ e });
      return new Node;
    }
  }

  static MapList(
    projectName: string,
    list: OneOfRawTypes[],
  ): OneOfTypes[] {
    return list.map(item => NodeFactory.MapBySchema(projectName, item));
  }
}
