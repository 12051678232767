import handlebars from 'handlebars';

import './style.scss';

export const renderToggleButton = (className: string) => new handlebars.SafeString(`
  <span class="toggle ${className}-toggle"></span>
`);

export const renderNextButton = () => new handlebars.SafeString(`
  <span class="next app-icon"></span>
`);
