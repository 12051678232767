/**
 * @module NavigationItem
 */

import {
  IExternalNavLink, IInternalNavLink, INavigationItem, IImage, ILinkedNode,
} from './types';

/**
 * Standard NavigationItem. Has no content but is linked to
 * another node or external link.
 */
export abstract class NavigationItem implements INavigationItem {
  public title: string = '';

  public uuid: string = '';

  public image: IImage | null = null;

  public language: string = 'en';

  public isExternalLink: boolean = false;
}

/**
 * Internal Link is a NavigationItem linked to another node.
 */
export class InternalNavLink extends NavigationItem implements IInternalNavLink {
  public node: ILinkedNode | null = null;
}

/**
 * External link is a NavigationItem with hyperlink linked to external url.
 */
export class ExternalNavLink extends NavigationItem implements IExternalNavLink {
  public url: string | null = null;

  public isExternalLink: boolean = true;
}
