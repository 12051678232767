import { WebAuth } from 'auth0-js';
import Auth0Authentication from '@bewatec-berlin/cc-js-utils/src/auth/index';
import { getLocalStorage } from '@base-utils/browserUtils';
import KISDataProvider from '@base-utils/KISDataProvider';
import authConfig from './config';
import kisConfig from './KIS/KISConfig';
import KISAuthentication from './KIS/KISAuthentication';


// @ts-ignore
const auth = new Auth0Authentication(getLocalStorage(), authConfig, WebAuth);


const queryParams = new URLSearchParams(window.location.search);
let organization = queryParams.get('organization');
if (!organization) {
  const Auth0OrganizationIdKey = 'auth0OrganizationId';
  organization = window.localStorage.getItem(
    Auth0OrganizationIdKey,
  );
}

export const kisAuth = new KISAuthentication(kisConfig, organization || undefined);


export const kisDataProvider = new KISDataProvider(kisAuth);

export default auth;
