import React, { useEffect, useState } from 'react';
import { changeLanguage, changeFontSize } from '@base-actions/settings';
import { FontSize } from '@base-store/settings/types';
import BackendTokenAuthentication from '@base-auth/2.0/BackendTokenAuthentication';
import { cmsApiConnection } from '@base-api/cmsApiConnection';
import { useAppDispatch } from '../hooks/useAppDispatch';
import {
  PortalSubscriptionType,
  usePortalSubscription,
} from '../hooks/usePortalSubscription';

export type PortalInitialContext = {
  startingRoute?: string;
  accessToken?: string;
  isNative?: boolean;
  fontSize?: FontSize;
  language?: string;
  sharedLogin?: string;
} | null;

const PortalContext = React.createContext<PortalInitialContext>(null);
PortalContext.displayName = 'PortalContext';

export const usePortalContext = () => React.useContext(PortalContext);

export function PortalProvider(props: PortalInitialContext) {
  const dispatch = useAppDispatch();
  const [accessToken, setAccessToken] = useState(props?.accessToken);

  usePortalSubscription<string>(PortalSubscriptionType.language, result => {
    dispatch(changeLanguage(result.data.message));
  });

  usePortalSubscription<FontSize>(PortalSubscriptionType.fontSize, result => {
    dispatch(changeFontSize(result.data.message));
  });

  usePortalSubscription<string>(PortalSubscriptionType.renewToken, result => {
    setAccessToken(result.data.message);
  });

  const value = React.useMemo(() => ({ ...props, accessToken }), [props, accessToken]);

  portalContextClient.setContext(value);

  if (value.accessToken) {
    cmsApiConnection.setAuthToken(value.accessToken);
  }

  if (props?.fontSize) {
    dispatch(changeFontSize(props.fontSize));
  }

  if (props?.sharedLogin) {
    BackendTokenAuthentication.setSharedLogin(props.sharedLogin);
  }

  useEffect(() => {
    if (props?.isNative) {
      document.body.classList.add('app-is__native');
    } else {
      document.body.classList.remove('app-is__native');
    }
  }, [props?.isNative]);

  return <PortalContext.Provider value={value} {...props} />;
}

export function usePortal() {
  const context = React.useContext(PortalContext);
  if (context === undefined) {
    throw new Error('usePortalContext must be used within a PortalProvider');
  }
  return context;
}

class PortalContextClient {
  private _context: PortalInitialContext | undefined;

  setContext(context: PortalInitialContext) {
    this._context = context;
  }

  get context() {
    return this._context;
  }

  get isNative() {
    return this._context?.isNative;
  }
}
export const portalContextClient = new PortalContextClient();
