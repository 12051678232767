import React, { FC } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { togglePreviewMode as togglePreviewModeAction } from '@base-actions/content';
import Notification from '@base-components/Notification';
import PreviewMode from '@base-utils/PreviewMode';
import { AppState } from '@base-reducers/index';

import style from './styles.module.scss';

interface PreviewModeNotificationProps {
  togglePreviewMode: typeof togglePreviewModeAction;
  isPreviewMode: boolean;
  location: Location;
}

const PreviewModeNotification: FC<PreviewModeNotificationProps> = ({
  togglePreviewMode,
  isPreviewMode,
  location,
}) => {
  const isPreview = PreviewMode.isEnabled(location);
  const history = useHistory();
  React.useEffect(() => {
    togglePreviewMode(isPreview);
  }, [isPreview]);
  return (
    <Notification show={isPreviewMode}>
      <div className={style['preview-mode']}>
        <span className={style['preview-mode-text']}>You are in preview mode.{'  '}</span>
        <span
          onClick={() => {
            const params = new URLSearchParams();
            params.delete('cms-editor-preview');
            history.push({ search: params.toString() });
            togglePreviewMode(false);
          }}
          data-test-id="switch-preview"
          className={style['action-link']}
        >
          Switch to live mode
        </span>
      </div>
    </Notification>
  );
};

const mapStateToProps = (state: AppState) => ({
  isPreviewMode: state.content.previewMode,
});

const mapDispatchToProps = {
  togglePreviewMode: togglePreviewModeAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(PreviewModeNotification);
