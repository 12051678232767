import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import style from './style.module.scss';

interface IProps {
  inverted?: boolean;
  title?: string;
  className?: string;
  hidden?: boolean;
}

export default (props: IProps) => {
  const [visible, setVisible] = useState(false);
  const [fading, setFading] = useState(false);
  const [fadeTimer, setFadeTimer] = useState(0);
  const {
    hidden,
    className,
    inverted,
  } = props;
  useEffect(() => {
    if (hidden) {
      const time = (new Date()).getTime() - fadeTimer;
      const timeout = time >= 300 ? 0 : 300;

      setTimeout(() => {
        setFading(true);
        setTimeout(() => {
          setFading(false);
          setVisible(false);
        }, 300);
      }, timeout);
    } else {
      setFadeTimer((new Date()).getTime());
      setVisible(true);
    }
  }, [hidden]);

  const classname = cn(
    style.container, className,
    {
      [style.fadingOut]: fading,
    },
  );

  return visible ? (
    <div className={classname}>
      <div className={cn(style.loader, { [style.inverted]: inverted })} />
    </div>
  ) : null;
};
