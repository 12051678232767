import getDomNode from '@base-utils/getDomNode';
import { displayError } from '@base-utils/Notifications';
import languageProvider from '@base-utils/LanguageProvider';
import { cmsApiConnection } from '@base-api/cmsApiConnection';
import getPdfJs from './getPdfJs';


export default (url: string, uuid: string, className: string) => {
  const authHeaders = {
    Authorization: `Bearer ${cmsApiConnection.authToken}`,
  };

  const containerId = `PdfDocument_${uuid}`;
  (async () => {
    try {
      const pdfJs = await getPdfJs();
      const loadingTask = pdfJs.getDocument({
        url: `${url}?lang=${languageProvider.currentLanguage}`,
        httpHeaders: authHeaders,
        withCredentials: true,
      });

      const pdfDocument = await loadingTask.promise;
      const { numPages } = pdfDocument;

      const node = await getDomNode(`#${containerId}`);
      const page = await pdfDocument.getPage(1);
      renderPages(pdfDocument, page, numPages, 1, node);
    } catch (e) {
      displayError({ e });
    }
  })();

  return `<div id="${containerId}" class="${className}"></div>`;
};

const renderPages = (pdfDocument, page, numPages, currentPage, node) => {
  const viewport = page.getViewport({ scale: 2 });
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  canvas.height = viewport.height;
  canvas.width = viewport.width;
  canvas.id = `${node.id}_page_${currentPage}`;
  canvas.className = 'pdf-page-canvas';

  page.render({
    canvasContext: context,
    viewport,
  });

  node.appendChild(canvas);

  if (pdfDocument !== null && currentPage < numPages) {
    pdfDocument.getPage(currentPage + 1)
      .then(newPage => renderPages(pdfDocument, newPage, numPages, currentPage + 1, node));
  }
};
