import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, AnyAction } from 'redux';

import UserInfo from '@base-components/UserInfo';
import { AppState } from '@base-reducers/index';
import { fetchPatientKISData, fetchPatientMe as fetchPatientMeAction } from '@base-actions/patient';
import { IPatientState } from '@base-store/patient/types';

interface IProps {
  patient: IPatientState;
  fetchPatientData: () => void;
  fetchPatientMe: typeof fetchPatientMeAction;
  kisEnabled: boolean;
}

const Profile = ({
  patient,
  fetchPatientData,
  fetchPatientMe,
  kisEnabled,
}: IProps) => {
  useEffect(() => {
    if (kisEnabled) {
      fetchPatientMe();
    }
  }, [kisEnabled]);

  useEffect(() => {
    if (patient.kisConnectionVerified) {
      fetchPatientData();
    }
  }, [patient.kisConnectionVerified]);

  return <UserInfo patientInfo={patient} />;
};

const mapStateToProps = (state: AppState) => ({
  patient: state.patient,
  kisEnabled: state.settings.KIS.enabled,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
  fetchPatientData: fetchPatientKISData,
  fetchPatientMe: fetchPatientMeAction,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
