import React from 'react';
import {
  ISchema,
  INode,
  ICompiledTemplate,
} from '@base-models/Data/types';
import TemplateProcessor from '@base-utils/TemplateProcessor';
import { mainPageUUID } from '@base-constants/urls';
import { SchemaNames } from '@base-constants/schemas';

import style from './app.module.css';

interface IProps {
  loadingContentError: string | null
  currentNode: INode | null
  loading: boolean
  routedNodeId: string
  schemas: Record<string, ISchema>
  templates: Record<string, ICompiledTemplate>
  requestNode: () => void
  goHome: () => void
}

interface IState {
  lastRenderedNode: INode | null
}

export default class PageViewport extends React.PureComponent<IProps, IState> {
  state: IState = {
    lastRenderedNode: null,
  };

  static getDerivedStateFromProps(newProps: IProps, state: IState) {
    const {
      loading, currentNode, requestNode, routedNodeId, loadingContentError, goHome,
    } = newProps;
    if (loadingContentError) { // Panic!
      if (routedNodeId && routedNodeId !== mainPageUUID) {
        if (window.navigator.onLine) { // Connection is ok, access problem
          goHome();
        }
      } else {
        return {
          ...state,
          lastRenderedNode: null,
        };
      }
    }
    const { lastRenderedNode } = state;
    // First load by URL
    if (!loading && !currentNode && routedNodeId) {
      requestNode();
      return state;
    }

    // Request when URL changed
    if (
      !loading
      && routedNodeId
      && currentNode
      && currentNode.uuid !== routedNodeId
      && !(routedNodeId === 'home' && currentNode.schema.name === SchemaNames.MainPage)
    ) {
      requestNode();
      return {
        ...state,
        lastRenderedNode: null,
      };
    }

    if (!loading && currentNode !== lastRenderedNode) {
      return {
        ...state,
        lastRenderedNode: currentNode,
      };
    }

    return state;
  }

  render() {
    const {
      loadingContentError,
      schemas,
      templates,
    } = this.props;
    if (loadingContentError) {
      return (
        <p className={`${style.error} app-error`}>
          {loadingContentError}
        </p>
      );
    }

    const { lastRenderedNode } = this.state;
    return lastRenderedNode && (
      <TemplateProcessor
        node={lastRenderedNode}
        schema={schemas[lastRenderedNode.schema.name]}
        templates={templates}
      />
    );
  }
}
