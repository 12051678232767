
export const SchemaNames = {
  NavigationItem: 'navigationItem',
  MainPage: 'MainPage',
  ContentPointer: 'ContentPointer',
  ConsentPage: 'ConsentPage',
  MediaVideo: 'media_video',
  MediaYTVideo: 'media_youtube_video',
  MediaAudio: 'media_radio',
  PremiumUpgrade: 'PremiumUpgrade_Request',
  MediaViewer: 'MediaViewer',
  MyPremiumEntertainment: 'MyPremiumEntertainment',
  Folder: 'folder',
} as const;

export type SchemaType = keyof typeof SchemaNames;
