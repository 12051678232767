import RequestActionType from '@base-utils/requestAction';
import { Node } from '@base-models/Data/Node';
import { Folder } from '@base-models/Data/Folder';
import { ILinkedNode } from '@base-models/Data/types';

export type CurrentNode = Node | Folder | null;
export interface INavigationState {
  loadingContent: boolean
  currentNode: CurrentNode
  loadingContentError: string | null
}

export const FETCH_FOLDER_CONTENT = new RequestActionType('FETCH_FOLDER_CONTENT');
export const FETCH_NODE_CONTENT = new RequestActionType('FETCH_NODE_CONTENT');

export interface FetchFolderAction {
  type: typeof FETCH_FOLDER_CONTENT.request
  payload: string | Folder
}

export interface FetchNodeAction {
  type: typeof FETCH_NODE_CONTENT.request
  payload: ILinkedNode | Node | string
}

export type NavigationActionTypes = FetchFolderAction | FetchNodeAction;
