import purchaseAPI from '@base-api/purchaseAPI';
import { displayError } from '@base-utils/Notifications';
import { Notifications } from '@base-constants/notifications';
import { setPremiumExpiryDate } from '@base-actions/redeemVoucher';
import { fetchPatientMe } from '@base-actions/patient';
import { fetchActivePremiumUpgrade } from '@base-actions/settings';
import Portals from '@ionic/portals';
import { history, store } from '@base-store/index';
import { redeemVoucherSuccessUrl } from '@base-constants/urls';
import { openCheckoutNativeScreen } from '@base-utils/PortalPublishEvent';
import { portalContextClient } from '@base-context/PortalContext';
import i18n from '../../../i18n/init';
import { PortalSubscriptionType } from '../../../hooks/usePortalSubscription';

export type IUpgradeToPremiumParams = {
  productId: string;
};

export const upgradeToPremium = async ({
  productId,
}: IUpgradeToPremiumParams) => {
  if (portalContextClient.context?.isNative) {
    const subscription = await Portals.subscribe(
      { topic: PortalSubscriptionType.openPaymentSucess },
      () => {
        const { paymentSuccessPageNodeId } = store.getState().redeemVoucher;
        store.dispatch(fetchPatientMe());
        store.dispatch(fetchActivePremiumUpgrade());
        history.push(`/node/${paymentSuccessPageNodeId}`, { replace: true });
        if (subscription !== undefined) {
          Portals.unsubscribe(subscription);
        }
      },
    );
    return openCheckoutNativeScreen(productId);
  }
  if (!productId) {
    displayError({ message: Notifications.missingIdErr });
    return;
  }
  history.push(`/node/payment/${productId}`);
};

const showInvalidVoucherError = () => {
  const inputVoucher = document.querySelector<HTMLInputElement>(
    '#redeem-voucher input',
  );
  const labelVoucher = document.querySelector<HTMLInputElement>(
    '#redeem-voucher label',
  );
  const errorMessage = document.querySelector<HTMLInputElement>(
    'span.text-hint.error',
  );

  if (inputVoucher && labelVoucher && errorMessage) {
    inputVoucher.classList.add('error-input');
    labelVoucher.classList.add('error');
    errorMessage.classList.remove('hide');
  }
};

export const redeemVoucher = async (voucherCode: string) => {
  try {
    const result = await purchaseAPI.redeemVoucher(
      voucherCode.replaceAll('-', ''),
    );
    if (result.redeemVoucher.error) {
      if (result.redeemVoucher.error === 'InvalidVoucher') {
        showInvalidVoucherError();
      }
    } else {
      const date = result.redeemVoucher.validUntil as string;
      store.dispatch(setPremiumExpiryDate(new Date(date)));
      history.push(redeemVoucherSuccessUrl);
    }
  } catch (e) {
    displayError({
      message: i18n.t('premiumUpgrade.redeemVoucherUnexpectedError'),
      timeout: 8000,
    });
  }
};

export const hideRedeemVoucher = () => {
  const upgradePremium = document.querySelector<HTMLElement>('#upgrade-premium');
  if (upgradePremium) {
    upgradePremium.classList.remove('hide');
  }
  const voucherSection = document.querySelector<HTMLElement>(
    '#redeem-voucher-box',
  );
  if (voucherSection) {
    voucherSection.classList.add('hide');
  }
  const paymentRadio = document.querySelector<HTMLElement>('#payment-radio');
  if (paymentRadio) {
    paymentRadio.style.display = 'none';
  }
  const voucherInfo = document.querySelector<HTMLElement>('#voucher-info');
  if (voucherInfo) {
    voucherInfo.classList.add('hide');
  }
};
