import handlebars from 'handlebars';
import { getNewsList } from '@base-utils/getNewsList';

const loadNews = (uuid: string, language: string) => getNewsList(uuid)
  .then(newsList => {
    const newsContainer = document.querySelector<HTMLElement>('.news');
    const headline = newsContainer?.querySelector<HTMLElement>('.news-title');
    const spinner = document.querySelector<HTMLElement>('.spinner-container');
    const introDetails = document.querySelector<HTMLElement>(
      '.intro-details',
    );
    const readMoreLink = newsContainer?.querySelector<HTMLElement>(
      '.news-read-more'
    );
    const newsItemContainer = newsContainer?.querySelector<HTMLElement>(
      '.newsList',
    );

    if (spinner) {
      spinner.style.display = 'none';
    }

    if (!newsList?.articles.length) {
      if (introDetails) {
        introDetails.style.display = 'block';
      }
      return;
    }

    if (headline) {
      headline.textContent = newsList?.displayName ?? '';
    }

    if (readMoreLink) {
      readMoreLink.textContent = newsList?.readMoreText ?? 'read more';
      readMoreLink.addEventListener('click', () => {
        window.TemplateEvents.navigateToId(`${newsList?.newsListUUID}`);
      });
    }

    newsList.articles.forEach(article => {
      const newsListItemTemplate = newsItemContainer?.querySelector(
        '.news_article'
      );
      const newsListItem = newsListItemTemplate?.cloneNode(true);

      if (newsListItem && newsItemContainer) {
        const currentNewsListItem = newsItemContainer.appendChild<HTMLElement>(
          newsListItem as HTMLElement,
        );

        currentNewsListItem.style.display = 'block';
        currentNewsListItem.setAttribute('data-test-id', 'news_list_item');
        currentNewsListItem.addEventListener('click', () => {
          window.TemplateEvents.navigateToId(`${article.uuid}`);
        });

        newsItemContainer.appendChild(currentNewsListItem);

        const currentNewsListItemHeadline = currentNewsListItem.querySelector(
          '.news-article-headline',
        );
        const currentNewsListItemSubHeadline = currentNewsListItem.querySelector(
          '.news-article-sub-headline',
        );

        if (currentNewsListItemHeadline) {
          currentNewsListItemHeadline.textContent = `
        ${handlebars.helpers['format_date'](article.time, language)}
        ${article.news_article_headline}
      `;
        }

        if (currentNewsListItemSubHeadline) {
          currentNewsListItemSubHeadline.textContent = article.shortDescription;
        }
      }
    });

    if (newsContainer) {
      newsContainer.style.display = 'block';
    }
    // eslint-disable-next-line no-console
  }).catch(e => console.log('getNewsList::ERROR', e));

export { loadNews };
