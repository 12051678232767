import React, { FC, useCallback } from 'react';
import purchaseAPI from '@base-api/purchaseAPI';
import { Amount } from '@base-api/purchaseAPI/types';
import { useTranslation } from 'react-i18next';
import Preloader from '@base-components/Preloader';
import { useAppSelector } from '../../../hooks/useAppSelector';
import style from './style.module.scss';
import { useAsync } from '../../../hooks/useAsync';

type SummaryCardProps = {
  itemAmount: Amount;
};

const SummaryCard: FC<SummaryCardProps> = ({ itemAmount }) => {
  const { t } = useTranslation();
  const displayedItemAmount = {
    currency: itemAmount?.currency,
    value: (itemAmount?.value / 100),
  };

  const hospitalId = useAppSelector(state => state?.settings?.hospitalUuid);
  const getPaymentConfig = useCallback(
    () => purchaseAPI.getPaymentConfig({
      hospitalId,
    }),
    [hospitalId],
  );
  const { data, status } = useAsync(getPaymentConfig);

  if (status === 'pending') {
    return <Preloader />;
  }
  if (!data) {
    return null;
  }
  const { hospitalDetails } = data;

  return (
    <div className={style.sidebarContainer}>
      <h4 className={style.heading}>{t('checkoutPage.summary')}</h4>
      <div className={style.cardContainer}>
        <div className={style.section}>
          <span className={style.label}>{t('checkoutPage.item')}:</span>
          Premium Entertainment
        </div>
        <div className={style.section} data-test-id="price-amount">
          <span className={style.label} data-test-id="price-label">{t('checkoutPage.price')}:</span>
          {t('checkoutPage.itemAmount',
            {
              value: displayedItemAmount,
            })
            }
        </div>
        <div className={`${style.section} ${style.vertical}`}>
          <span className={style.label} data-test-id="entity-label">
            {t('checkoutPage.entityDetails')}:
          </span>
          <ul>
            <li data-test-id="legal-entity-name">{hospitalDetails?.legalEntityName}</li>
            <li data-test-id="registration-number">{hospitalDetails?.registrationNumber}</li>
            {hospitalDetails?.tradingName && <li>{hospitalDetails?.tradingName}</li>}
            {hospitalDetails?.extraDetails && (
              <>
                {hospitalDetails?.extraDetails.map((field, index) => (
                  <li key={index}>{field}</li>
                ))}
              </>
            )}
            <li data-test-id="location">{hospitalDetails?.location}</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SummaryCard;
