import React from 'react';
import Checkout from '@base-pages/Payment/Checkout';
import SummaryCard from '@base-pages/Payment/SummaryCard';
import { Locale } from '@base-api/purchaseAPI/types';
import { useTranslation } from 'react-i18next';
import { useCheckoutSession } from '../../hooks/useCheckoutSession';

import './style.scss';

function Payment() {
  const { data, language } = useCheckoutSession();
  const { t } = useTranslation();
  return (
    <div className="payment-page">
      <h1 className="app-page-title"> {t('checkoutPage.title')} </h1>
      {data && (
        <div className="payment-wrapper">
          <Checkout sessionData={data} locale={Locale[language] ?? Locale.de} />
          <SummaryCard itemAmount={data?.getPaymentSession?.amount} />
        </div>
      )}
    </div>
  );
}

export default Payment;
