import React from 'react';
import errorImage from '@base-assets/images/icons/error@2x.png';

import styles from './style.module.scss';

interface ErrorDialogProps {
  text: string;
}

export default function ErrorDialog({ text }: ErrorDialogProps) {
  return (
    <div className={styles.container}>
      <img src={errorImage} alt="error" />
      <p className={styles.error}>{text}</p>
    </div>
  );
}
