import React from 'react';
import { Route } from 'react-router-dom';
import { useAuth0 } from './2.0/AuthRouterProvider';

interface IProps {
  [key: string]: any
}

const PrivateRoute: React.FC<IProps> = ({
  component: Component, path, location, ...rest
}) => {
  const {
    isAuthenticated,
    isConsentGiven,
  } = useAuth0();
  const render = (props: any) => (
    isAuthenticated && isConsentGiven ? <Component {...props} />
      : null
  );

  return <Route path={path} render={render} location={location} {...rest} />;
};

export default PrivateRoute;
