import React, { useState, useEffect } from 'react';
import { IPatientState } from '@base-store/patient/types';
import Spinner from '@base-components/Spinner';
import ConnectionCode from '@base-components/ConnectionCode';
import { IPatient } from '@base-models/Data/types';
import BackendTokenAuthentication from '@base-auth/2.0/BackendTokenAuthentication';

import style from './style.module.css';

interface IProps {
  patientInfo: IPatientState;
}

const UserInfo = ({ patientInfo }: IProps) => {
  const [patientData, setPatientData] = useState<IPatient | null>(null);

  useEffect(() => {
    const resolveData = async () => {
      const data = await patientInfo.resolve;
      setPatientData(data);
    };
    resolveData();
  }, [patientInfo.loading]);

  if (patientInfo.loading) {
    return (
      <div className={style.profileContent}>
        <div className={style.wrapper}>
          <Spinner />
        </div>
      </div>
    );
  }

  if (!patientInfo.kisConnectionVerified && !BackendTokenAuthentication.isSharedLogin()) {
    return (
      <ConnectionCode
        onAddLaterClick={() => {
          window.location.replace(localStorage.getItem('previousUrl') as string);
        }}
      />
    );
  }

  return (
    <div className={style.profileContent}>
      <div className={style.wrapper}>
        {patientInfo.loadingError && 'Loading patient profile failed'}
        {!patientInfo.loading && !patientInfo.loadingError && patientData && (
          <ul>
            <li>
              <b>Medical Case ID:</b> {patientData.medicalCaseId}
            </li>
            <li>
              <b>Name:</b> {patientData.firstName} {patientData.lastName}
            </li>
            <li>
              <b>Date of birth:</b> {patientData.birthDate}
            </li>
            <li>
              <b>Address:</b>
              {patientData.address.postalCode} {patientData.address.city}, &nbsp;
              {patientData.address.line1} {patientData.address.line2}
            </li>
            <li>
              <b>Phone number:</b> {patientData.phoneNumber}
            </li>
            <li>
              <b>Insurance type:</b> {patientData?.insurance?.type}
            </li>
            {patientData.hospitalization && (
              <li>
                <b>Hospitalization info:</b>
                <ul>
                  {patientData.hospitalization.building && (
                    <li>
                      <b>Building:</b> {patientData.hospitalization.building}
                    </li>
                  )}
                  {patientData.hospitalization.department && (
                    <li>
                      <b>Department:</b> {patientData.hospitalization.department}
                    </li>
                  )}
                  {patientData.hospitalization.room && (
                    <li>
                      <b>Room:</b> {patientData.hospitalization.room}
                    </li>
                  )}
                  {patientData.hospitalization.bed && (
                    <li>
                      <b>Bed:</b> {patientData.hospitalization.bed}
                    </li>
                  )}
                </ul>
              </li>
            )}
          </ul>
        )}
      </div>
    </div>
  );
};

export default UserInfo;
