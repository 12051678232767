import { epgApiUrl } from '@base-constants/urls';
import {
  IEpgAPIAuthentication,
  EpgData,
} from '@base-models/Data/types';

export default class EpgAPIDataProvider {
  public epgData: EpgData | null = null;

  public constructor(private authentication: IEpgAPIAuthentication) {}

  public fetchEpg = async (): Promise<EpgData> => {
    await this.authentication.authenticate();
    const response = await fetch(
      `${epgApiUrl}/channel`,
      {
        headers: this.getRequestHeaders(),
      },
    );
    const epgData: EpgData = await response.json();
    return epgData;
  };

  public fetchCurrentChannelEpg = async (channelId: string): Promise<EpgData> => {
    await this.authentication.authenticate();
    const response = await fetch(
      `${epgApiUrl}/channel/${channelId}/current`,
      {
        headers: this.getRequestHeaders(),
      },
    );
    const epgData: EpgData = await response.json();
    return epgData;
  };

  private getRequestHeaders(): Headers {
    return new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: `Bearer ${this.authentication.getAccessToken()}`,
    });
  }
}
