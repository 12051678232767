import { useCallback, useEffect, useState } from 'react';
import Portals, { PortalSubscription } from '@ionic/portals';

export type Message<T> = {
  data: {
    message: T;
  };
  topic: string;
};

export enum PortalSubscriptionType {
  language = '@global:langChange',
  fontSize = '@global:fontSizeChange',
  openPaymentSucess = '@global:openPaymentSucess',
  renewToken = '@global:renewToken',
  navigateToHome = '@global:navigateToHome'
}

export const usePortalSubscription = <T>(
  topic: PortalSubscriptionType,
  fn: (result: Message<T>) => void,
) => {
  const [subscription, setSubscription] = useState<PortalSubscription>();
  const callback = useCallback(fn, []);

  useEffect(() => {
    const subscribe = async () => {
      const portalSubscription = await Portals.subscribe({ topic }, callback);
      setSubscription(portalSubscription);
    };
    subscribe();
    return () => {
      if (subscription !== undefined) {
        Portals.unsubscribe(subscription);
      }
    };
  }, [topic, callback]);
};
