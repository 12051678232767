import SWManager from '@base-utils/SWManager';
import { errorTracking } from './errorTracking/index';

import '@i18n/init';
import './index.css';
import './styles/global.scss';
import '@base-utils/FontFaceInjector';
import './feature-toggles-init';
import '@base-utils/TemplateEventsHandler';

errorTracking.init();

window['env'] = process.env;
if ('serviceWorker' in navigator && process.env.REACT_APP_NO_SW !== 'true') {
  const swUrl = `${process.env.PUBLIC_URL}/service.worker.js`;
  window.addEventListener('load', () => {
    async function setupWorker() {
      // It will just connect if the worker is alredy there
      await SWManager.registerWorker(swUrl, { scope: '/' });
      SWManager.runStrategy();
    }
    setupWorker();
  });
}
