
export enum Locale {
  'de' = 'de_DE',
  'en' = 'en_US',
}

export enum CountryCode {
  'de' = 'de',
}

export type Amount = {
  currency: Currency,
  value: number,
};

export enum Currency {
  EUR = 'EUR',
  USD = 'USD',
}

export type GetPaymentSessionRequestData = {
  productId: string;
  locale?: Locale;
  countryCode?: string;
};

export type GetPaymentSessionResponseData = {
  getPaymentSession: {
    expiresAt: string,
    sessionId: string,
    merchantAccount: string,
    reference: string,
    callbackUrl: string,
    sessionData: string,
    adyenClientApiKey: string,
    adyenEnvironment: string,
    amount: Amount,
  }
};

export type RedeemVoucherRequestData = {
  voucherCode: string
};

export type RedeemVoucherResponseData = {
  redeemVoucher: {
    validUntil?: string;
    error?: string;
  }
};

export type GetPaymentConfigRequestData = {
  hospitalId: string;
};

export type GetPaymentConfigResponseData = {
  getPaymentConfig: PaymentConfig;
};

export type HospitalLegalDetails = {
  legalEntityName: string;
  location: string;
  tradingName?: string;
  registrationNumber: string;
  extraDetails?: string[];
};

export type PaymentConfig ={
  redeemVoucher: boolean;
  hospitalDetails: HospitalLegalDetails;
};

export type GetActivePremiumUpgradeResponseData = {
  getActivePremiumUpgrade: {
    endDate?: string;
  }
};

export type UpdatePurchaseStatusRequestData = {
  id: string,
  status: string,
};
export type UpdatePurchaseStatusResponseData = {
  updatePurchaseStatus: {
    status?: string;
    error?: string;
  }
};
