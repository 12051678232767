import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Profile from '@base-pages/profile';
import RedeemVoucherSuccess from '@base-pages/RedeemVoucherSuccess/RedeemVoucherSuccess';
import Payment from '@base-pages/Payment';
import { redeemVoucherSuccessUrl } from '@base-constants/urls';
import Node from './node';
import { AdyenRedirect } from '../../pages/Payment/AdyenRedirect';
import { useAppSelector } from '../../hooks/useAppSelector';


function Nodes() {
  const { path } = useRouteMatch();
  const schemas = useAppSelector(state => state.schemas.schemas);
  const configLoading = useAppSelector(state => state.settings.configLoading);

  if (!schemas || configLoading) {
    return null;
  }

  return (
    <Switch>
      <Route exact path="/node/profile" component={Profile} />
      <Route exact path="/node/payment/adyen" component={AdyenRedirect} />
      <Route exact path="/node/payment/:productId" component={Payment} />
      <Route
        exact
        path={redeemVoucherSuccessUrl}
        component={RedeemVoucherSuccess}
      />
      <Route exact path={`${path}/:nodeId?`} component={Node} />
    </Switch>
  );
}

export default Nodes;
