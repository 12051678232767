import handlebars from 'handlebars';
import {
  ITemplate, ICompiledTemplate, INode, INavigationItem,
} from '@base-models/Data/types';
import { registerHelpers } from './Helpers';

registerHelpers();

export function compileSingleTemplate(template: ITemplate) {
  const { html } = template;
  return {
    ...template,
    html: handlebars.compile(html),
  };
}

export function compileTemplates(
  templates: Record<string, ITemplate>,
): Record<string, ICompiledTemplate> {
  return Object.keys(templates).reduce((result, name) => ({
    ...result,
    [name]: compileSingleTemplate(templates[name]),
  }), {});
}

export function populateTemplate(template: ICompiledTemplate, node: INode | INavigationItem) {
  const basicHtml = template.html(node);
  const styles = template.css ? `<style>${template.css}</style>` : '';

  const newNode = document.createElement('div');
  newNode.innerHTML = basicHtml + styles;

  if (template.js) {
    const scriptNode = document.createElement('script');
    scriptNode.appendChild(
      document.createTextNode(template.js),
    );
    newNode.appendChild(scriptNode);
  }

  return newNode;
}
