import React, { ReactNode } from 'react';

import './style.scss';

interface IIconLabelProps {
  text: ReactNode
  icon: string
  className?: string
}

const IconLabel = ({
  icon,
  text,
  className,
}: IIconLabelProps) => (
  <div className="label">
    <img src={icon} alt="" className={`label-icon ${className || ''}`} />
    <p className="label-text">
      {text}
    </p>
  </div>
);

export default IconLabel;
