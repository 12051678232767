import createAuth0Client, {
  Auth0Client,
  Auth0ClientOptions,
  GetTokenSilentlyOptions,
} from '@auth0/auth0-spa-js';
import { WebAuth } from 'auth0-js';
import { IAuthConfig } from '@base-models/Data/types';
import Authentication from '@base-auth/Authentication';
import { displayError } from '@base-utils/Notifications';
import { getOrganizationIdFormToken } from '@base-api/backendAPI/tokenDecode';
import { getIsOnboardingModalEnable, setIsOnboardingModalEnable } from '@base-utils/browserUtils';
import idmServiceAPI from '@base-api/idmServiceAPI';
import { cmsApiConnection } from '@base-api/cmsApiConnection';
import AbstractAuthenticationModule from './AbstractAuthenticationModule';

export type AppState = {
  returnTo?: string;
  [key: string]: any;
};

const onRedirectCallback = (appState?: AppState): void => {
  window.history.replaceState(
    {},
    document.title,
    appState?.returnTo || window.location.pathname,
  );
};

export default class UniversalLoginAuthentication extends AbstractAuthenticationModule {
  static ConfirmAuthFlow = (location: Location) => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.has('code');
  };

  static IdToken = 'idToken';

  static StorageKey = 'isUniversalLogin';

  static Auth0OrganizationIdKey = 'auth0OrganizationId';

  private PromtLoginOrganizations = ['']; // Todo: add relevant orgId list from a config

  private auth0!: Auth0Client;

  private _authenticated = false;

  private auth0ClientOptions: Auth0ClientOptions;

  private organizationId: string | null;

  private connectionName: string;

  private codeHint:string;

  public get authenticated() {
    return this._authenticated;
  }

  constructor(
    private readonly authConfig: IAuthConfig,
    private readonly storage: Storage,
  ) {
    super(authConfig);
    this.authType = UniversalLoginAuthentication.StorageKey;
    this.organizationId = this.getOrganizationId();
    this.connectionName = this.getQueryParam('connection');
    this.codeHint = this.getQueryParam('codehint');
    let redirectUri = window.location.origin;
    if (this.organizationId) {
      redirectUri = this.connectionName ? `${redirectUri}/?organization=${this.organizationId}&connection=${this.connectionName}` : `${redirectUri}/?organization=${this.organizationId}`;
    }
    const [uiLocales] = navigator.language.split('-');

    this.auth0ClientOptions = {
      domain: this.authConfig.domain,
      client_id: this.authConfig.clientID,
      audience: this.authConfig.audience,
      ui_locales: uiLocales,
      redirect_uri: redirectUri,
      ...(this.codeHint && { access_type: this.codeHint }),
      ...(this.organizationId && { organization: this.organizationId }),
      ...(this.connectionName && { connection: this.connectionName }),
      ...(this.organizationId && this.PromtLoginOrganizations.includes(this.organizationId) && { prompt: 'login' }),
      ...(window.location.hash === '#reset-password-error' && { fragment: 'reset-password-error' }),
    };
  }

  private getOrganizationId() {
    const queryParams = new URLSearchParams(window.location.search);
    return queryParams.get('organization')
      ?? this.storage.getItem(UniversalLoginAuthentication.Auth0OrganizationIdKey);
  }

  // eslint-disable-next-line class-methods-use-this
  private getQueryParam(param:string) {
    const queryParams = new URLSearchParams(window.location.search);
    return queryParams.get(param) ?? '';
  }

  public init = async () => {
    try {
      Authentication.init(
        // @ts-ignore
        WebAuth, (this.organizationId ? { organization: this.organizationId } : {}),
      );
      this.auth0 = await createAuth0Client(this.auth0ClientOptions);
      return true;
    } catch (e) {
      displayError({ e: (e as Error).message });
      throw new Error('Failed to initialize Auth0Client');
    }
  };

  public getAccessToken =
  async (options?:GetTokenSilentlyOptions) => this.getTokenSilently(options);

  public getIdToken = async () => {
    const idToken = await this.auth0.getIdTokenClaims();
    return idToken.__raw as string;
  };

  public getAccessTokens = async (options?:GetTokenSilentlyOptions) => {
    try {
      const cmsAccessToken = await this.getTokenSilently(options);
      cmsApiConnection.setAuthToken(cmsAccessToken);
      return {
        cmsAccessToken,
      };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return {};
    }
  };

  public authenticate = async () => {
    const queryParams = new URLSearchParams(window.location.search);
    const isAllowedIP = await (async () => {
      if (this.organizationId) {
        return idmServiceAPI.verifyIPAccessbyOrganization(this.organizationId);
      }
      return true;
    })();

    const isAccessDenied = queryParams.has('error') && queryParams.has('error_description')
      && queryParams.get('error') === 'access_denied';
    if ((isAccessDenied && queryParams.has('organization')) || !isAllowedIP) {
      throw new Error('access_denied');
    }

    const saveIdToken = async () => {
      const idToken = await this.getIdToken();
      this.storage.setItem(UniversalLoginAuthentication.IdToken, idToken);
    };

    if (queryParams.has('code') && queryParams.has('state')) {
      const organizationId = queryParams.get('organization');
      if (organizationId) {
        this.storage.setItem(
          UniversalLoginAuthentication.Auth0OrganizationIdKey,
          organizationId,
        );
      }
      const { appState } = await this.auth0.handleRedirectCallback();
      onRedirectCallback(appState);
      await Promise.all([this.getAccessTokens(), saveIdToken()]);
      this._authenticated = await this.auth0.isAuthenticated();
      return this._authenticated;
    }

    this._authenticated = await this.auth0.isAuthenticated();
    if (!this._authenticated) {
      await this.auth0.loginWithRedirect(this.auth0ClientOptions);
      return false;
    }

    await Promise.all([this.getAccessTokens(), saveIdToken()]);
    return this._authenticated;
  };


  public logout = async () => {
    try {
      const isOnboardingModalEnable = getIsOnboardingModalEnable();
      const returnTo = await this.getReturnUrl();
      if (this.storage) {
        this.storage.clear();
      }
      if (isOnboardingModalEnable) {
        setIsOnboardingModalEnable(isOnboardingModalEnable);
      }
      this.auth0.logout({
        returnTo,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  private getTokenSilently =
  async (options?: GetTokenSilentlyOptions) => this.auth0.getTokenSilently({
    audience: this.authConfig.audience,
    scope: this.authConfig.defaultScope,
    ...(this.organizationId ? { organization: this.organizationId } : {}),
    ...options,
  });

  private getReturnUrl = async () => {
    try {
      const idToken = await this.getIdToken();
      const organization = getOrganizationIdFormToken(idToken);
      let returnTo = `${window.location.origin}/`;
      if (organization) {
        returnTo = `${returnTo}?organization=${organization}`;
      }
      return returnTo;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error((error as Error).message);
    }
  };
}
