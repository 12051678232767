import React from 'react';
import { connect } from 'react-redux';
import { fetchEpg as fetchEpgAction } from '@base-actions/epg';
import { AppState } from '@base-reducers/index';
import { EpgData, IEpgChannelData } from '@base-models/Data/types';
import Spinner from '@base-components/Spinner';
import Button from '@base-components/Button';

import './style.scss';

interface IEpgPreviewProps {
  epgData: EpgData | null
  fetchEpg: typeof fetchEpgAction
  loading: boolean
  error: string | null
}

const EpgPreview = ({
  epgData,
  fetchEpg,
  loading,
  error,
}: IEpgPreviewProps) => (
  <div className="epg">
    <h1>EPG DATA</h1>
    {!epgData && (
      <Button
        className="epg-btn"
        actionButton
        onClick={() => fetchEpg()}
        testId="fetch-epg-data"
        disabled={loading}
      >
        {loading ? <Spinner small /> : 'SHOW EPG'}
      </Button>
    )}
    <div className="epg-data">
      {!error && (
        epgData?.map((epg: IEpgChannelData) => (
          <div className="epg-details" key={epg.channelId}>
            <h2>{epg.channelName}</h2>
            <p><b>Current Show:</b> {epg.currentProgram?.title}</p>
            <p><b>Duration:</b> {epg.currentProgram?.duration}</p>
            <p><b>End:</b> {epg.currentProgram?.end}</p>
            <p><b>Genre:</b> {epg.currentProgram?.genre}</p>
            <p><b>Parental Rating:</b> {epg.currentProgram?.parentalRating}</p>
            <p><b>Start:</b> {epg.currentProgram?.start}</p>
          </div>
        ))
      )}
    </div>
  </div>
);

const mapStateToProps = (state: AppState) => ({
  loading: state.epg.loading,
  error: state.epg.error,
  epgData: state.epg.epgData,
});

const mapDispatchToProps = {
  fetchEpg: fetchEpgAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(EpgPreview);
