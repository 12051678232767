import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import { AppState } from '@base-reducers/index';
import { FETCH_SCHEMAS } from '@base-store/schemas/types';
import { Schema } from '@base-models/Data/Schema';
import { cmsApi } from '@base-api/cmsApi';
import { IRawSchema } from '@base-models/Data/types';
import { Notifications } from '@base-constants/notifications';
import { displayError } from '@base-utils/Notifications';
import { FETCH_TEMPLATES } from '@base-store/settings/types';

function* fetchDataSchemas() {
  try {
    const projectName = yield select((state: AppState) => state.settings.projectConfig.name);
    const { data: response } = yield call(cmsApi.fetchProjectSchemas, projectName);
    const elements = response.data;
    const schemas = elements.map((item: IRawSchema) => new Schema(item));
    yield put({
      type: FETCH_SCHEMAS.success,
      payload: schemas,
    });
  } catch (e) {
    const message = Notifications.schemasLoadErr;
    yield call(displayError, { message, e });
    yield put({
      type: FETCH_SCHEMAS.fail,
      payload: message,
    });
  }
}

function* schemasSaga() {
  yield takeLatest(FETCH_TEMPLATES.success, fetchDataSchemas);
}

export default schemasSaga;
