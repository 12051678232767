import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { toggleCodeVerificationIsShown } from '@base-actions/user';
import { OneOfTypes } from '@base-models/Data/NodeFactory';
import { portalContextClient } from '@base-context/PortalContext';
import Button from './Button';
import { useAppSelector } from '../hooks/useAppSelector';
import { useAppDispatch } from '../hooks/useAppDispatch';

type Props = {
  navigationItems: OneOfTypes[];
};
const BackButton: FC<Props> = () => {
  const showVerificationScreen = useAppSelector(
    state => state.user.showVerificationScreen,
  );

  const { loadingContent } = useAppSelector(
    state => state.navigation,
  );
  const dispatch = useAppDispatch();
  const history = useHistory();
  const hasRootNavigation = () => {
    if (
      portalContextClient.context?.isNative
      && history.location.pathname === portalContextClient.context?.startingRoute) {
      return true;
    }
    if (
      history.location.pathname.includes(localStorage.getItem('currentMainNavigationItem') ?? 'home')
      || history.location.pathname.includes('home')) {
      return true;
    }
    return false;
  };

  if (loadingContent || hasRootNavigation()) {
    return <div />;
  }

  return (
    <Button
      onClick={() => {
        history.goBack();
        if (showVerificationScreen) {
          dispatch(toggleCodeVerificationIsShown(false));
        }
      }}
      backButton
      testId="app-back-button"
      className="app-back-button"
    />
  );
};
export default BackButton;
