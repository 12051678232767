
export interface IPubSub<T> {
  subscribe: (n: (a: T) => void) => Function
  unsubscribe: (n: (a: T) => void) => void
  notify: (n: T) => boolean
}

export default class PubSub<T> implements IPubSub<T> {
  protected listeners: Set<(n: T) => void> = new Set();

  /**
   * @returns false if there were no listeners, true otherwise
   */
  public notify(action: T): boolean {
    if (!this.listeners.size) return false;
    this.listeners.forEach(callback => callback(action));
    return true;
  }

  /**
   * removes the subscription for the given listener
   */
  public unsubscribe(listener: (n: T) => void) {
    if (this.listeners.has(listener)) {
      this.listeners.delete(listener);
    }
  }

  /**
   * subscribes provided callback to the events.
   * @returns unsub function that can be used to unsubscribe without providing an original instance
   */
  public subscribe(listener: (n: T) => void) {
    if (!this.listeners.has(listener)) {
      this.listeners.add(listener);
    }
    return () => this.unsubscribe(listener);
  }

  public get size() {
    return this.listeners.size;
  }
}
