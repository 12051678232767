export const IS_ONBOARDING_MODAL_ENABLE_KEY = 'isOnboardingModalEnable';

export const isOnboardingModalEnable = () => {
  const value = getIsOnboardingModalEnable();
  return !value || value === 'true';
};

export function setIsOnboardingModalEnable(value: string) {
  localStorage.setItem(IS_ONBOARDING_MODAL_ENABLE_KEY, value);
}

export function getIsOnboardingModalEnable() {
  return localStorage.getItem(IS_ONBOARDING_MODAL_ENABLE_KEY);
}
/**
 * Checks the availability of the storage
 */
export function getLocalStorage(): WindowLocalStorage['localStorage'] | null {
  try {
    localStorage.setItem('ls-available', 'true');
    localStorage.removeItem('ls-available');
    return localStorage;
  } catch (e) {
    return null;
  }
}

/**
 * Provides the fallback if the navigator is not available
 */
export function getBrowserLanguage(defaultLanguage: string): string {
  try {
    return navigator.language.substr(0, 2); // We only need 'en' part of 'en-GB'
  } catch (e) {
    return defaultLanguage;
  }
}
