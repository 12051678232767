import i18n, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import LocizeBackend from 'i18next-locize-backend';

import { defaultFallbackLanguage, SupportedLocales } from './SupportedLocales';
import enUS from './locales/en-US.json';
import deDE from './locales/de-DE.json';

export const namespace = 'translations';

const isDev = process.env.NODE_ENV === 'development';

const resources = {
  [SupportedLocales.deDE]: { [namespace]: deDE },
  [SupportedLocales.enUS]: { [namespace]: enUS },
} as const;

const locizeApiKey = process.env.REACT_APP_LOCIZE_API_KEY as string;
const locizeProjId = process.env.REACT_APP_LOCIZE_PROJECT_ID as string;

const locizeBackendConfig = {
  backend: {
    private: true,
    referenceLng: defaultFallbackLanguage,
    apiKey: locizeApiKey,
    projectId: locizeProjId,
    allowedAddOrUpdateHosts: ['dev.webapp2.com', 'localhost'],
  },
};

const i18nBackendOptions: InitOptions = {
  load: 'currentOnly' as const,
  fallbackLng: defaultFallbackLanguage,
  simplifyPluralSuffix: true,
  ns: [namespace],
  defaultNS: namespace,
  debug: isDev,
  saveMissing: true,
  saveMissingTo: 'all' as const,
  react: {
    wait: true,
    useSuspense: true,
  },
  ...(isDev ? locizeBackendConfig : { resources }),
  interpolation: {
    format(value, format, lng) {
      if (format === 'currency') {
        return new Intl.NumberFormat(lng, { style: 'currency', currency: value.currency }).format(value.value);
      }
      return value;
    },
  },
};

if (isDev) {
  i18n.use(LocizeBackend);
}

i18n.use(initReactI18next).use(LanguageDetector).init(i18nBackendOptions);

export default i18n;
