export default (
  text: string,
  options: {[key: string]: string},
) => {
  const regex = /{([^{}]*)}/g;

  return text.replace(regex, (match, p1) => {
    const newText = options[p1];
    return typeof newText === 'string' ? newText : match;
  });
};
