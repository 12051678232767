import { useQuery } from 'react-query';
import PreviewMode from '@base-utils/PreviewMode';
import { cmsApi, QueryResult } from '@base-api/cmsApi';
import pnavQuery from '@base-api/queries/projectNav';
import { IRawNavigationItem } from '@base-models/Data/types';
import { NodeFactory } from '@base-models/Data/NodeFactory';
import { useAppSelector } from './useAppSelector';


const previewMode = PreviewMode.isEnabled();
const maxInt = Number.MAX_SAFE_INTEGER;
// eslint-disable-next-line max-len
const sortByOrder = (a, b): number => ((a.fields.order || maxInt) > (b.fields.order || maxInt) ? 1 : -1);

const transformTodoNames = (projectName?: string) => (res: QueryResult<IRawNavigationItem[]>) => {
  if (projectName) {
    const items = res.nodes.elements.sort(sortByOrder);
    return NodeFactory.MapList(projectName, items);
  }
  return [];
};

export function useNavigationItemQuery() {
  const language = useAppSelector(state => state.settings.language);
  const projectName = useAppSelector(
    state => state.settings.projectConfig.name,
  );
  return useQuery(
    ['NavigationItem', projectName, language],
    () => cmsApi.graphQLRequest<IRawNavigationItem[]>(
      projectName!,
      pnavQuery(language),
      previewMode,
    ),
    {
      select: transformTodoNames(projectName),
      enabled: Boolean(projectName && language),
    },
  );
}
