
import authConfig from '@base-auth/config';
import { ServiceAuth } from './ServiceAuth';
import AbstractAuthenticationModule from './AbstractAuthenticationModule';
import UrlParamsAuthentication from './UrlParamsAuthentication';
import PreviewModeAuthentication from './PreviewModeAuthentication';
import UniversalLoginAuthentication from './UniversalLoginAuthentication';
import BackendTokenAuthentication from './BackendTokenAuthentication';


const authModules = [
  UniversalLoginAuthentication,
  UrlParamsAuthentication,
  PreviewModeAuthentication,
  BackendTokenAuthentication,
];
const serviceAuthModules = [ServiceAuth];

export default class AuthRouter {
  static verifyAuthFlow(location: Location) {
    try {
      let AuthModule = authModules.find(module => module.ConfirmAuthFlow(location));
      const authType = window.localStorage.getItem(
        AbstractAuthenticationModule.AuthModuleStorage,
      );
      const StoredAuthModule = authModules.find(
        module => module.StorageKey === authType,
      );
      if (AuthModule) {
        window.localStorage.removeItem(
          AbstractAuthenticationModule.AuthModuleStorage,
        );
      }
      if (StoredAuthModule && !AuthModule) {
        AuthModule = StoredAuthModule;
      }
      if (!AuthModule) {
        // No Auth found, use Universal as default
        AuthModule = UniversalLoginAuthentication;
      }
      window.localStorage.setItem(
        AbstractAuthenticationModule.AuthModuleStorage,
        AuthModule.StorageKey,
      );
      return new AuthModule(authConfig, window.localStorage);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Error getting authentication flow', e);
      return null;
    }
  }

  static verifyServiceAuthFlow(location: Location) {
    try {
      const AuthModule = serviceAuthModules.find(module => module.ConfirmAuthFlow(location));
      if (AuthModule) {
        const authModule = new AuthModule(authConfig);
        return authModule;
      }
      return null;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Error getting authentication flow', e);
      return null;
    }
  }
}
