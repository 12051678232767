import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';
import Preloader from '@base-components/Preloader';
import { GetPaymentSessionResponseData } from '@base-api/purchaseAPI/types';
import { useRedirectUrl } from '../../hooks/useRedirectUrl';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { useUpgradePremium } from '../../hooks/useUpgradePremium';

import './style.scss';

export const AdyenRedirect = () => {
  const location = useLocation();
  const history = useHistory();
  const [sessionData] = useLocalStorage<GetPaymentSessionResponseData>('checkoutSession');
  const { getRedirectUrl } = useRedirectUrl();
  const { upgradePremium } = useUpgradePremium();


  useEffect(() => {
    const sessionId = new URLSearchParams(location.search).get('sessionId');
    const redirectResult = new URLSearchParams(location.search).get(
      'redirectResult',
    );
    const createCheckout = async () => {
      const { getPaymentSession: session } = sessionData;

      const checkout = await AdyenCheckout({
        session: {
          id: sessionId,
          sessionData: session.sessionData,
        },
        environment: session.adyenEnvironment,
        clientKey: session.adyenClientApiKey,
        onPaymentCompleted: async response => {
          const status = response.resultCode.toUpperCase();
          if (status === 'AUTHORISED') {
            await upgradePremium(session.reference, status);
          }
          history.push(getRedirectUrl(response.resultCode), { replace: true });
        },
        onError: error => {
          // eslint-disable-next-line no-console
          console.error(error);
          history.push(getRedirectUrl('error'), { replace: true });
        },
      });
      checkout.submitDetails({ details: { redirectResult } });
    };
    if (sessionId && redirectResult) {
      createCheckout();
    } else {
      history.push(getRedirectUrl('error'), { replace: true });
    }
  }, [history, location.search]);

  return (
    <div className="payment-container" data-testid="adyen-redirect-page">
      <Preloader />;
    </div>
  );
};
