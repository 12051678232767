import {
  EpgActionTypes,
  FETCH_EPG,
  FETCH_CURRENT_CHANNEL_EPG,
} from '@base-store/epg/types';

export function fetchEpg(): EpgActionTypes {
  return {
    type: FETCH_EPG.request,
    payload: null,
  };
}

export function fetchCurrentChannelEpg(channelId: string): EpgActionTypes {
  return {
    type: FETCH_CURRENT_CHANNEL_EPG.request,
    payload: {
      channelId,
    },
  };
}
