import RequestActionType from '@base-utils/requestAction';

export interface IProgramVerificationState {
  verifiedPrograms: Record<string, string>
  invalidPINAttempts: number
  locked: boolean
  lockTimestamp: number | null
  personalPin: string | null
  loading: boolean
}

export const SET_PROGRAM_VERIFICATION = 'SET_PROGRAM_VERIFICATION';
export const INVALID_PIN_ENTERED = 'INVALID_PIN_ENTERED';
export const VALID_PIN_ENTERED = 'VALID_PIN_ENTERED';
export const UNLOCK_PIN_VERIFICATION = 'UNLOCK_PIN_VERIFICATION ';
export const FETCH_PERSONAL_PIN = new RequestActionType('FETCH_PERSONAL_PIN');

export interface ISetProgramVerificationAction {
  type: typeof SET_PROGRAM_VERIFICATION,
  payload: {
    programId: string,
    channelId: string,
  }
}

export interface IInvalidPINEnteredAction {
  type: typeof INVALID_PIN_ENTERED,
  payload: null
}

export interface IValidPINEnteredAction {
  type: typeof VALID_PIN_ENTERED,
  payload: null
}

export interface IUnlockPinVerificationAction {
  type: typeof UNLOCK_PIN_VERIFICATION,
  payload: null
}

export interface IFetchPersonalPinAction {
  type: typeof FETCH_PERSONAL_PIN.request
  payload: string | null
}

export type ProgramVerificationActionTypes = ISetProgramVerificationAction
| IInvalidPINEnteredAction
| IValidPINEnteredAction
| IUnlockPinVerificationAction
| IFetchPersonalPinAction;
