import {
  AUTHENTICATION,
  LANGUAGE_CHANGE,
  FONT_SIZE_CHANGE,
  FETCH_CONFIGURATION,
  FETCH_TEMPLATES,
  SettingsActionTypes,
  ISettingsState,
  ACTIVE_PREMIUM_UPGRADE_CHANGE,
  SHOW_APP_DIALOG,
  IDialogState,
  HIDE_APP_DIALOG,
} from '@base-store/settings/types';
import { IProjectConfiguration, IProjectTemplates } from '@base-models/Data/types';
import languageProvider from '@base-utils/LanguageProvider';
import { getUserFontSize } from '@base-utils/BaseFontSize';
import { createWaitPromise } from '@base-utils/waitForData';

const {
  waitForData: waitForKisData,
  resolveData: resolveKisData,
} = createWaitPromise<ISettingsState['KIS']>();

const {
  waitForData: waitForProjectConfig,
  resolveData: resolveProjectConfig,
} = createWaitPromise<IProjectConfiguration | undefined>();

export const initialState: ISettingsState = {
  fontSize: getUserFontSize(),
  language: languageProvider.currentLanguage,
  authenticated: false,
  loginError: null,
  projectTemplates: {
    templates: {},
    globalStyle: '',
  },
  projectConfig: {
    availableLanguages: ['de'],
    defaultLanguage: 'de',
    name: undefined,
    analyticsTool: null,
    templatesUrl: '',
    privateNoteUrl: '',
    termsAndConditionsUrl: '',
    skipConsentPageForIndividualLogin: false,
    isHelpCenterEnabled: false,
  },
  hospitalUuid: '',
  KIS: {
    resolve: waitForKisData,
    enabled: false,
    url: '',
  },
  resolve: waitForProjectConfig,
  configLoading: true,
  configError: null,
  templateError: null,
  settingsError: null,
  activePremiumUpgrade: null,
  dialog: {
    mainContentText: '',
    subText: '',
    show: false,
  },
};

export default (state = initialState, action: SettingsActionTypes): ISettingsState => {
  switch (action.type) {
    case AUTHENTICATION.request: {
      return {
        ...state,
        loginError: null,
      };
    }

    case FONT_SIZE_CHANGE.fail:
    case FETCH_CONFIGURATION.fail:
    case FETCH_TEMPLATES.fail:
    case LANGUAGE_CHANGE.fail:
    case AUTHENTICATION.success:
    case FONT_SIZE_CHANGE.success:
    case ACTIVE_PREMIUM_UPGRADE_CHANGE.success:
    case LANGUAGE_CHANGE.success: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case AUTHENTICATION.fail: {
      return {
        ...state,
        authenticated: false,
        loginError: action.payload as string,
      };
    }

    case FONT_SIZE_CHANGE.request:
    case LANGUAGE_CHANGE.request: {
      return {
        ...state,
        settingsError: null,
      };
    }

    case FETCH_CONFIGURATION.request: {
      return {
        ...state,
        configError: null,
      };
    }

    case FETCH_CONFIGURATION.success: {
      const {
        projectConfig, language, hospitalUuid, KIS,
      } = action.payload as Partial<ISettingsState>;
      const lang = language as string;
      const config = projectConfig as IProjectConfiguration;
      const newKIS = {
        enabled: false,
        resolve: state.KIS.resolve,
        url: '',
        ...KIS,
      };
      resolveKisData(newKIS);
      resolveProjectConfig(projectConfig);

      return {
        ...state,
        projectConfig: config,
        language: lang,
        hospitalUuid: hospitalUuid || '',
        KIS: newKIS,
        configLoading: false,
      };
    }

    case FETCH_TEMPLATES.request: {
      return {
        ...state,
        templateError: null,
      };
    }

    case FETCH_TEMPLATES.success: {
      const { projectTemplates } = action.payload as Partial<ISettingsState>;
      const templates = projectTemplates as IProjectTemplates;
      return {
        ...state,
        projectTemplates: templates,
      };
    }

    case HIDE_APP_DIALOG:
    case SHOW_APP_DIALOG: {
      return {
        ...state,
        dialog: action.payload?.dialog as IDialogState,
      };
    }

    default:
      return state;
  }
};
