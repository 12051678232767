import { QueryClient } from 'react-query';

const reactQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 60,
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
});

export { reactQueryClient };
