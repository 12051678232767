import { fetchPatientMe } from '@base-actions/patient';
import { fetchActivePremiumUpgrade } from '@base-actions/settings';
import purchaseAPI from '@base-api/purchaseAPI';
import { useAuth0 } from '@base-auth/index';
import { useCallback } from 'react';
import { useAppDispatch } from './useAppDispatch';

export const useUpgradePremium = () => {
  const auth0 = useAuth0();
  const dispatch = useAppDispatch();
  const upgradePremium = useCallback(async (id: string, status: string) => {
    await purchaseAPI.updatePurchaseStatus({
      id,
      status,
    });

    if (auth0.currentAuthModule) {
      try {
        // @ts-ignore
        await auth0.currentAuthModule.getAccessTokens({ ignoreCache: true });
        dispatch(fetchPatientMe());
        dispatch(fetchActivePremiumUpgrade());
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }
  }, [auth0]);
  return { upgradePremium };
};
