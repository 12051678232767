import { setCurrentAgeRestriction } from '@base-actions/mediaViewer';
import {
  invalidPINEntered, validPINEntered, unlockPinVerification, fetchPersonalPin,
} from '@base-actions/programVerification';
import { MIN_AGE_SKY } from '@base-constants/epg';
import { IEpgChannelData, PIN_NOT_FOUND } from '@base-models/Data/types';
import { AppState } from '@base-reducers/index';
import { hideMediaPlayer, stopMediaPlayer } from '@base-utils/MediaPlayer';
import { ProgramVerificationManager } from '@base-utils/ProgramVerificationManager';
import { subscribe } from '@bewatec-berlin/redux-subscriber';
import { store } from '@base-store/index';

export const triggerPinVerification = async (channelId: string, next: Function) => {
  if (!channelId) {
    next();
    return;
  }

  const unsubscribe = subscribe('epg.currentChannelEpgData', (state: AppState) => {
    if (state.epg.loading) {
      return;
    }
    const { currentChannelEpgData } = state.epg;
    if (!currentChannelEpgData) {
      next();
      return;
    }
    unsubscribe();
    handleEpgDataChange(currentChannelEpgData, next);
  });
};

const handleEpgDataChange = (
  channelEpgData: IEpgChannelData | undefined,
  next: Function,
) => {
  const parentalRating = channelEpgData?.currentProgram?.parentalRating;
  const programId = channelEpgData?.currentProgram?.programId as string;
  const channelId = channelEpgData?.channelId as string;

  if (!parentalRating) {
    next();
    return;
  }

  if (parentalRating && parentalRating < MIN_AGE_SKY) {
    next();
    return;
  }

  store.dispatch(setCurrentAgeRestriction(parentalRating));

  const isProgramVerified = ProgramVerificationManager.isProgramVerified(programId, channelId);

  if (isProgramVerified) {
    next();
    return;
  }

  stopMediaPlayer();
  setPinVerificationFields(parentalRating);
  resetValidationForm();
  togglePinVerificationLock();

  const { personalPin, loading } = store.getState().programVerification;
  // if personalPin is already fetched (has 4 digit or NotFound value),
  // no need to fetch it again cause it's set only once during invitation flow
  if (!personalPin && !loading) {
    store.dispatch(fetchPersonalPin());

    const unsubscribeProgramVerification = subscribe('programVerification.personalPin', (state: AppState) => {
      if (state.programVerification.personalPin) {
        unsubscribeProgramVerification();
        handlePersonalPinFetch();
      }
    });
  }
};

const setPinVerificationFields = (parentalRating: number) => {
  if (parentalRating >= 18) {
    setAgeRestrictionValues(18);
  } else if (parentalRating >= 16) {
    setAgeRestrictionValues(16);
  } else {
    setAgeRestrictionValues(12);
  }
};

const handlePersonalPinFetch = () => {
  const restrictionForm = document.querySelector('.restriction-form') as HTMLElement;
  // if restrictionForm is visible, show also pinInfoContainer
  if (restrictionForm.style.display === 'block') {
    showRestrictionFormAndPinContainer();
  }
};

const showRestrictionFormAndPinContainer = () => {
  const restrictionForm = document.querySelector('.restriction-form') as HTMLElement;
  restrictionForm.style.display = 'block';

  const { personalPin } = store.getState().programVerification;
  if (personalPin && personalPin !== PIN_NOT_FOUND) {
    const pinInfoContainer = document.querySelector('.pin-info-container') as HTMLElement;
    pinInfoContainer.style.display = 'block';

    const pinSpan = pinInfoContainer.querySelector('.pinValue') as HTMLElement;
    pinSpan.innerHTML = personalPin;
  }
};

const setAgeRestrictionValues = (value: number) => {
  const ageRestrictionBadge = document.getElementsByClassName('age-restriction')[0] as HTMLElement;
  const ageRestrictionNumberEl = document.querySelectorAll('.age-restriction-number');
  if (ageRestrictionNumberEl) {
    ageRestrictionNumberEl[0].textContent = `${value}`;
    ageRestrictionNumberEl[1].textContent = `${value}`;
    ageRestrictionNumberEl[2].textContent = `${value}`;
  }
  if (ageRestrictionBadge) {
    const badgeClasses = ageRestrictionBadge.classList;
    badgeClasses.forEach(cssClass => {
      if (cssClass.startsWith('over')) {
        ageRestrictionBadge.classList.remove(cssClass);
      }
    });

    badgeClasses.add(`over-${value}`);
  }
};

const isPinVerificationLocked = () => store.getState().programVerification.locked;

let intervalId: NodeJS.Timeout | null = null;

const showLock = () => {
  const { lockTimestamp } = store.getState().programVerification;

  if (!lockTimestamp || intervalId !== null) {
    return;
  }

  showLockedMessage();
  updateCountDown(calculateTimeLeft(lockTimestamp));

  startLockTimer(lockTimestamp);
};

const startLockTimer = (lockTimestamp: number) => {
  intervalId = setInterval(() => {
    const remainingMinutes = calculateTimeLeft(lockTimestamp);

    updateCountDown(remainingMinutes);
    const isTimeOver = remainingMinutes <= 0;

    if (isTimeOver) {
      stopLockTimer();
    }
  }, 10000);
};

const stopLockTimer = () => {
  if (intervalId) {
    clearInterval(intervalId);
    intervalId = null;
    store.dispatch(unlockPinVerification());
    hideLockedMessage();
    showRestrictionFormAndPinContainer();
  }
};

export const togglePinVerificationLock = () => {
  if (isPinVerificationLocked()) {
    showLock();
    hideRestrictionFormAndPinContainer();
  }
};

export const updateCountDown = (remainingMinutes: number) => {
  const timeLeftLabel = document.querySelector('.countdown') as HTMLElement;

  if (timeLeftLabel) {
    timeLeftLabel.textContent = ` ${remainingMinutes}`;
  }
};

export const showPinVerification = () => {
  const pinVerificationEl = document.querySelector('.pin-verification') as HTMLElement;

  pinVerificationEl.style.display = 'block';
  showRestrictionFormAndPinContainer();
};

export const hidePinVerification = () => {
  const pinVerificationEl = document.querySelector('.pin-verification') as HTMLElement;

  if (pinVerificationEl) {
    pinVerificationEl.style.display = 'none';
  }
};

export const calculateTimeLeft = (lockTimeStamp: number) => {
  const passedMiliseconds = Date.now() - lockTimeStamp;
  const passedMinutes = Math.floor(passedMiliseconds / 60000);

  return 10 - passedMinutes;
};

export const hideSpinner = () => {
  const confirmText = document.getElementsByClassName('confirm-text')[0] as HTMLElement;
  const spinner = document.getElementsByClassName('spinner-container')[0] as HTMLElement;

  confirmText.style.display = 'block';
  spinner.classList.add('hide-spinner');
};

export const showSpinner = () => {
  const confirmText = document.getElementsByClassName('confirm-text')[0] as HTMLElement;
  const spinner = document.getElementsByClassName('spinner-container')[0] as HTMLElement;

  confirmText.style.display = 'none';
  spinner.classList.remove('hide-spinner');
};

export const showErrorMessage = () => {
  const errorMessage = document.querySelector('.error-message') as HTMLElement;

  errorMessage.style.display = 'inline-block';
};

export const hideErrorMessage = () => {
  const errorMessage = document.querySelector('.error-message') as HTMLElement;

  errorMessage.style.display = 'none';
};

export const showAgeErrorMessage = () => {
  const errorMessage = document.querySelector('.age-restriction-error') as HTMLElement;

  errorMessage.style.display = 'inline-block';
};

export const hideAgeErrorMessage = () => {
  const errorMessage = document.querySelector('.age-restriction-error') as HTMLElement;

  errorMessage.style.display = 'none';
};

export const hideRestrictionFormAndPinContainer = () => {
  const restrictionForm = document.querySelector('.restriction-form') as HTMLElement;
  restrictionForm.style.display = 'none';

  const pinInfoContainer = document.querySelector('.pin-info-container') as HTMLElement;
  pinInfoContainer.style.display = 'none';
};

export const disableConfirmationButton = () => {
  const confirmButton = document.querySelector('.main-button') as HTMLButtonElement;

  confirmButton.disabled = true;
};

export const cleanInputs = () => {
  const inputForm = document.querySelector('.pin-inputs') as HTMLFormElement;

  inputForm.reset();
};

export const resetValidationForm = () => {
  hideMediaPlayer();
  hideAgeErrorMessage();
  showPinVerification();
  hideSpinner();
  disableConfirmationButton();
  hideErrorMessage();
  cleanInputs();
};

export const storeVerifiedProgramData = () => {
  const state = store.getState();
  const { channelId, currentChannelEpgData } = state.epg;
  const programId = currentChannelEpgData?.currentProgram?.programId || '';
  ProgramVerificationManager.storeProgramVerification(programId, channelId);
};

export const invalidPINAttempt = () => {
  store.dispatch(invalidPINEntered());
};

export const validPINAttempt = () => {
  store.dispatch(validPINEntered());
};

export const showLockedMessage = () => {
  const timeLeftLabel = document.querySelector('.content-locked-error') as HTMLElement;
  timeLeftLabel.style.display = 'block';
};

export const hideLockedMessage = () => {
  const timeLeftLabel = document.querySelector('.content-locked-error') as HTMLElement;
  timeLeftLabel.style.display = 'none';
};
