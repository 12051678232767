import {
  trackingEventProcessor,
  Dictionary,
  DictionaryBuilder,
  IEvent,
} from '@bewatec-berlin/cc-metrics-tools/src/types';
import {
  TEMPLATE_NAVIGATE_TO_UUID,
} from '@base-constants/templates';

type node = {
  uuid: string
};

export const navigationDictionary: DictionaryBuilder = (
  trackEvent: trackingEventProcessor,
): Dictionary => ({
  [TEMPLATE_NAVIGATE_TO_UUID]: (event?: IEvent) => {
    // "Click" for the general tracking, when the source is not defined,
    // otherwise use a specific meta-data
    const action = event?.meta?.actionType || 'Click';
    const name = event?.meta?.source || event?.payload;
    trackEvent({
      category: 'Navigation',
      action,
      name,
    });
  },
  LOGOUT_REQUEST: () => trackEvent({
    category: 'LOGOUT',
    action: 'Click',
  }),
});
