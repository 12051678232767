import { ErrorTrackingService } from '@bewatec-berlin/tracking-tools';

const breadcrumbsFilters = ['data'];

const errorTracking = new ErrorTrackingService({
  dsn: process.env.REACT_APP_SENTRY_DSN || '',
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT || '',
  breadcrumbsFilters,
});

export { errorTracking };
