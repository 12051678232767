
/**
 * @module LanguageProvider
 */

import { getLocalStorage, getBrowserLanguage } from '@base-utils/browserUtils';
import PreviewMode from '@base-utils/PreviewMode';
import i18n from '../../i18n/init';
import { SupportedLocales } from '../../i18n/SupportedLocales';

export interface ILanguageProvider {
  currentLanguage: string
  switchLanguage: (lang: string, availableLanguages: string[]) => string
  useDefault: (defaultLang: string, availableLanguages: string[]) => string | undefined
}


/**
 * Takes care of fetching, storing, switching and updating the language setting
 */
export class LanguageProvider implements ILanguageProvider {
  static languageToLocale = {
    de: SupportedLocales.deDE,
    en: SupportedLocales.enUS,
  };

  static LanguageStorageKey = 'appLanguage';

  static DefaultLanguage = 'en';

  private language = 'en';

  private storage: WindowLocalStorage['localStorage'] | null = null;

  private userLanguage!: string;

  public get currentLanguage() {
    return this.language;
  }

  constructor(storage: WindowLocalStorage['localStorage'] | null, userLanguage: string) {
    this.storage = storage;
    this.userLanguage = userLanguage;
    this.initLanguage();
  }

  private getLocale = (language: string) => LanguageProvider.languageToLocale[language]
    || LanguageProvider.languageToLocale[LanguageProvider.DefaultLanguage];

  private initLanguage() {
    if (PreviewMode.isEnabled()) {
      // for the preview mode, language should be picked up from URL
      const queryParams = new URLSearchParams(window.location.search);
      const langQuery = queryParams.get('lang');
      if (langQuery) {
        this.setLanguage(langQuery);
      }
    } else if (this.storage) {
      let savedLanguage = this.storage.getItem(LanguageProvider.LanguageStorageKey);
      if (!savedLanguage) {
        savedLanguage = this.userLanguage;
        this.storage.setItem(LanguageProvider.LanguageStorageKey, savedLanguage);
      }
      this.language = savedLanguage;
      i18n.changeLanguage(this.getLocale(this.language));
    }
  }

  private setLanguage(lang: string) {
    if (this.storage) {
      this.storage.setItem(LanguageProvider.LanguageStorageKey, lang);
    }
    i18n.changeLanguage(this.getLocale(lang));
    this.language = lang;
  }

  public switchLanguage(newLang: string | undefined, availableLanguages: string[]) {
    if (!newLang || !availableLanguages.includes(newLang)) {
      throw new Error(`Incorrect language is provided. Please use the one from the list: ${availableLanguages}`);
    }

    this.setLanguage(newLang);
    return newLang;
  }

  public useDefault(defaultLang: string, availableLanguages: string[]) {
    if (!availableLanguages) return;
    if (!availableLanguages?.includes(this.language)) {
      this.switchLanguage(defaultLang, availableLanguages);
      return defaultLang;
    }
    return this.language;
  }
}

export default new LanguageProvider(
  getLocalStorage(),
  getBrowserLanguage(getBrowserLanguage(LanguageProvider.DefaultLanguage)),
);
