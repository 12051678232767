import React, { ReactNode, SetStateAction } from 'react';

import IconLabel from '@base-components/IconLabel';
import checkbox from '@base-assets/images/icons/checkbox-inactive.svg';
import checkboxActive from '@base-assets/images/icons/checkbox-active.svg';

import './style.scss';

interface ICheckboxProps {
  id: string
  checked: boolean
  content: ReactNode
  handleChange: React.Dispatch<SetStateAction<boolean>>
}

const Checkbox = ({
  id,
  checked,
  content,
  handleChange,
}:ICheckboxProps) => (
  <label htmlFor={id} className="checkbox-label" data-test-id={id}>
    <input
      type="checkbox"
      name={id}
      id={id}
      checked={checked}
      onChange={e => {
        handleChange(e.target.checked as unknown as boolean);
      }}
    />
    <IconLabel
      icon={checked ? checkboxActive : checkbox}
      text={content}
    />
  </label>
);

export default Checkbox;
