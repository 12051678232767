import {
  all, call, put, takeLatest, select,
} from 'redux-saga/effects';
import {
  AUTHENTICATION,
  LANGUAGE_CHANGE,
  FONT_SIZE_CHANGE,
  LanguageChangeAction,
  FontSizeChangeAction,
  FETCH_CONFIGURATION,
  FETCH_TEMPLATES,
  ACTIVE_PREMIUM_UPGRADE_CHANGE,
} from '@base-store/settings/types';
import languageProvider from '@base-utils/LanguageProvider';
import { AppState } from '@base-reducers/index';
import { getProjectTemplates } from '@base-api/templates';
import { setBaseFontSize } from '@base-utils/BaseFontSize';
import { TEMPLATE_CHANGE_FONT_SIZE } from '@base-constants/templates';
import { Notifications } from '@base-constants/notifications';
import { displayError, displayInfo } from '@base-utils/Notifications';
import backendAPI from '@base-api/backendAPI';
import {
  IHospitalData,
  IProjectTemplates,
} from '@base-models/Data/types';
import { defaultTemplatesUrl } from '@base-constants/urls';
import ProjectConfiguration from '@base-models/Data/ProjectConfiguration';
import ProjectTemplates from '@base-models/Data/ProjectTemplates';
import { changeBewatecTabletLanguage } from '@base-api/bewatecTabletAPI/index';
import { fetchPatientMe } from '@base-sagas/patient';
import purchaseAPI from '@base-api/purchaseAPI';
import { tracker } from '../tracking/tracker';
import { portalContextClient } from '../context/PortalContext';

export function* changeFontSize(action: FontSizeChangeAction) {
  try {
    const fontSize = action?.payload.fontSize;
    if (fontSize) {
      yield call(setBaseFontSize, fontSize);
      yield put({
        type: FONT_SIZE_CHANGE.success,
        payload: {
          fontSize,
          settingsError: null,
        },
      });
    }
  } catch (e) {
    const message = Notifications.fontSizeChangeErr;
    yield call(displayError, { message, e });
    yield put({
      type: FONT_SIZE_CHANGE.fail,
      payload: {
        settingsError: message,
      },
    });
  }
}

export function* onAuthentication() {
  const fontSize = yield select((state: AppState) => state.settings.fontSize);
  yield all([
    call(fetchProjectConfiguration),
    call(changeFontSize, {
      type: FONT_SIZE_CHANGE.request,
      payload: { fontSize },
    }),
    call(fetchPatientMe),
    call(fetchActivePremiumUpgrade),
  ]);
  yield call(fetchTemplates);
}

export function* updateLanguage(action: LanguageChangeAction) {
  try {
    const { language } = action.payload;
    const availableLanguages = yield select(
      (state: AppState) => state.settings.projectConfig.availableLanguages,
    );
    const currentLanguage = yield select(
      (state: AppState) => state.settings.language,
    );
    if (language && language !== currentLanguage) {
      languageProvider.switchLanguage(language, availableLanguages);
      changeBewatecTabletLanguage(language);
    }
    yield put({
      type: LANGUAGE_CHANGE.success,
      payload: {
        language,
        settingsError: null,
      },
    });
  } catch (e) {
    const message = Notifications.languageChangeErr;
    yield call(displayError, { message, e });
    yield put({
      type: LANGUAGE_CHANGE.fail,
      payload: {
        settingsError: message,
      },
    });
  }
}

function* fetchProjectTemplates(templatesUrl: string) {
  try {
    return yield call(getProjectTemplates, templatesUrl);
  } catch (e) {
    const message = Notifications.noConfigErr;
    yield call(displayInfo, { message, e });
  }

  try {
    return yield call(getProjectTemplates, defaultTemplatesUrl);
  } catch {
    throw new Error(Notifications.templatesLoadErr);
  }
}

function* fetchTemplates() {
  try {
    const templatesUrl = yield select(
      (state: AppState) => state.settings.projectConfig.templatesUrl,
    );
    const templates: IProjectTemplates = yield call(fetchProjectTemplates, templatesUrl);
    const projectTemplates = new ProjectTemplates(templates);

    yield put({
      type: FETCH_TEMPLATES.success,
      payload: {
        projectTemplates,
      },
    });
  } catch (e) {
    const { message } = e;
    yield call(displayError, { message, e });
    yield put({
      type: FETCH_TEMPLATES.fail,
      payload: {
        templateError: message,
      },
    });
  }
}

function* fetchProjectConfiguration() {
  const configLoaded = yield select(
    (state: AppState) => !state.settings.configLoading,
  );
  if (configLoaded) return;
  let hospitalUuid = '';
  const KIS = {
    enabled: false,
    url: '',
  };
  try {
    const hospitalData: IHospitalData = yield call(backendAPI.getHospitalData);
    const { defaultLanguage, languages } = hospitalData;
    const language = portalContextClient.context?.language
      || languageProvider.useDefault(defaultLanguage, languages);

    if (hospitalData.id) {
      hospitalUuid = hospitalData.id;
    }

    if (hospitalData.kisInterfaceEndpointUrl !== null) {
      KIS.enabled = true;
      KIS.url = hospitalData.kisInterfaceEndpointUrl;
    }

    const config = new ProjectConfiguration(hospitalData);
    tracker.setHospitalName(config.name);

    yield put({
      type: FETCH_CONFIGURATION.success,
      payload: {
        projectConfig: config,
        language,
        hospitalUuid,
        KIS,
      },
    });
  } catch (e) {
    const { message } = e;
    yield call(displayError, { message, e });
    yield put({
      type: FETCH_CONFIGURATION.fail,
      payload: {
        configError: message,
      },
    });
  }
}

function* fetchActivePremiumUpgrade() {
  try {
    const { getActivePremiumUpgrade } = yield call(
      purchaseAPI.getActivePremiumUpgrade,
    );

    yield put({
      type: ACTIVE_PREMIUM_UPGRADE_CHANGE.success,
      payload: {
        activePremiumUpgrade: getActivePremiumUpgrade,
      },
    });
  } catch {
    yield put({
      type: ACTIVE_PREMIUM_UPGRADE_CHANGE.success,
      payload: {
        activePremiumUpgrade: null,
      },
    });
  }
}

function* settingsSaga() {
  yield takeLatest(AUTHENTICATION.success, onAuthentication);
  yield takeLatest(TEMPLATE_CHANGE_FONT_SIZE, changeFontSize);
  yield takeLatest(FONT_SIZE_CHANGE.request, changeFontSize);
  yield takeLatest(LANGUAGE_CHANGE.request, updateLanguage);
  yield takeLatest(ACTIVE_PREMIUM_UPGRADE_CHANGE.request, fetchActivePremiumUpgrade);
}

export default settingsSaga;
