import React, { useEffect } from 'react';
import { useAuth0 } from '../auth/2.0/AuthRouterProvider';

const Logout: React.FC = () => {
  const { loading, currentAuthModule } = useAuth0();
  useEffect(() => {
    if (!loading && currentAuthModule) {
      currentAuthModule.logout();
    }
  }, [loading]);
  return null;
};

export default Logout;
