import React, { useEffect } from 'react';
import { AUTHENTICATION } from '@base-store/settings/types';
import { useHistory } from 'react-router-dom';
import { usePortal } from '@base-context/PortalContext';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { Auth0Context } from './AuthRouterProvider';
import {
  PortalSubscriptionType,
  usePortalSubscription,
} from '../../hooks/usePortalSubscription';

const PortalRouterProvider = ({ children }) => {
  const portal = usePortal();
  const history = useHistory();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch({
      type: AUTHENTICATION.success,
      payload: { authenticated: true },
    });
    if (portal?.startingRoute) {
      history.push(portal.startingRoute);
    }
  }, []);

  usePortalSubscription<string>(
    PortalSubscriptionType.navigateToHome,
    result => {
      if (portal?.startingRoute === `/node/${result.data.message}`) {
        history.push(portal?.startingRoute);
      }
    },
  );

  const value = React.useMemo(
    () => ({
      isAuthenticated: true,
      isConsentGiven: true,
      loading: false,
    }),
    [],
  );

  return (
    <Auth0Context.Provider value={value}>{children}</Auth0Context.Provider>
  );
};

export default PortalRouterProvider;
