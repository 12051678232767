import { Schema } from '@base-models/Data/Schema';
import RequestActionType from '@base-utils/requestAction';

export interface ISchemasState {
  schemas: Record<string, Schema>
  loading: boolean
  loadingError: string | null
}

export const FETCH_SCHEMAS = new RequestActionType('FETCH_SCHEMAS');

export interface IFetchSchemasAction {
  type: typeof FETCH_SCHEMAS.request
  payload: Partial<ISchemasState> | null
}

export type SchemasActionTypes = IFetchSchemasAction;
