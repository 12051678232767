import { redeemVoucherSuccessUrl } from '@base-constants/urls';
import { CurrentNode } from '@base-store/navigation/types';
import i18n from '../../i18n/init';

export const getNodeTitle = (currentNode: CurrentNode) => {
  const { location } = window;

  if (currentNode?.fields?.title) {
    return currentNode.fields.title.value;
  }

  if (currentNode?.fields?.name) {
    return currentNode.fields.name.value;
  }

  if (location.pathname === redeemVoucherSuccessUrl) {
    return i18n.t('redeemVoucherSuccess.title');
  }

  if (location.pathname.includes('payment')) {
    return 'Payment';
  }
};
