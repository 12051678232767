import intlFormat from 'date-fns/intlFormat';
import dateFnsFormat from 'date-fns/format';
import { de, enUS } from 'date-fns/locale';
import { SupportedLocales } from './SupportedLocales';

type DateType = Date | number;

type FormatType = string;

const dateFnsLocalesMap = {
  [SupportedLocales.deDE]: de,
  [SupportedLocales.enUS]: enUS,
} as const;

const format = (
  dateString: DateType,
  dateFormat: FormatType,
  locale: SupportedLocales,
): string => dateFnsFormat(dateString, dateFormat, {
  locale: dateFnsLocalesMap[locale],
});

const defaultIntlFormat = (date: Date, locale: SupportedLocales): string => intlFormat(
  new Date(date),
  {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  },
  {
    locale: locale.toString(),
  },
);

export { format, defaultIntlFormat };
