export interface ITemplateEvent {
  type: string
  payload: Record<string, any>
}

type TemplateEventMetaInfo = {
  eventType: string
  actionType: string
  source: string
  target: string
};

export class TemplateEvent implements ITemplateEvent {
  public type = '';

  public payload = {};

  public meta: TemplateEventMetaInfo = {
    eventType: 'TemplateEvent',
    actionType: '',
    source: '',
    target: '',
  };

  constructor(type: string, payload: ITemplateEvent['payload'] = {}, meta?: Partial<TemplateEventMetaInfo>) {
    this.type = type;
    this.payload = payload;
    if (meta) {
      this.meta = {
        ...this.meta,
        ...meta,
      };
    }
  }

  public toString() {
    return `${this.constructor.name}[${this.type}]`;
  }
}
