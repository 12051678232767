import { IVideoOptions } from '@base-utils/TemplateProcessor/Helpers/mediaPlayer';

export interface IMediaViewerState {
  currentlyPlayingVideo: IVideoOptions | null
  currentAgeRestriction: number
}

export const SET_CURRENTLY_PLAYING_VIDEO = 'SET_CURRENTLY_PLAYING_VIDEO';
export const SET_CURRENT_AGE_RESTRICTION = 'SET_CURRENT_AGE_RESTRICTION';

export interface ISetCurrentlyPlayingVideoAction {
  type: typeof SET_CURRENTLY_PLAYING_VIDEO,
  payload: IVideoOptions | null
}

export interface ISetCurrentAgeRestrictionAction {
  type: typeof SET_CURRENT_AGE_RESTRICTION,
  payload: number,
}

export type MediaViewerActionTypes = ISetCurrentlyPlayingVideoAction
| ISetCurrentAgeRestrictionAction;
