import { CliniserveRequestData, IKISAuthentication, IPatient } from '@base-models/Data/types';
import KISDataProvider from '@base-utils/KISDataProvider';
import { NoKISAccessError, KISDataMissingError } from '@base-models/errors';
import { AppState } from '@base-reducers/index';

export type Store = {
  getState: () => AppState
};

export default class CliniserveKISDataProvider extends KISDataProvider {
  public NoKISAccessError = new NoKISAccessError('KIS is not enabled for this hospital');

  public KISDataMissingError = new KISDataMissingError('There is no KIS data available for this patient');

  protected store!: Store;

  constructor(authentication: IKISAuthentication, store: Store) {
    super(authentication);
    this.store = store;
  }

  public async getCliniserveRequestData(): Promise<CliniserveRequestData> {
    const { url, enabled } = this.store.getState().settings.KIS;
    if (!enabled) {
      throw this.NoKISAccessError;
    }
    let kisData:IPatient | null;
    try {
      kisData = await this.getKISData(url);
    } catch (error) {
      kisData = null;
    }
    if (!kisData?.hospitalization) {
      throw this.KISDataMissingError;
    }
    const location = kisData.hospitalization;

    return {
      ...location.building && { building: location.building },
      ...location.department && { department: location.department },
      ...location.room && { room: location.room },
      ...location.bed && { bed: location.bed },
      ...kisData.insurance?.type && { insuranceType: kisData.insurance?.type },
    };
  }
}
