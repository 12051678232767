import {
  FETCH_CONSENT_PAGE,
  IContentState,
  ContentActionTypes,
  IConsentPageData,
  TOGGLE_PREVIEW_MODE,
} from '@base-store/content/types';

const initialState: IContentState = {
  consentPageData: null,
  loading: true,
  loadingError: null,
  previewMode: false,
};

export default (state = initialState, action: ContentActionTypes): IContentState => {
  switch (action.type) {
    case FETCH_CONSENT_PAGE.request: {
      return {
        ...state,
        loading: true,
        loadingError: null,
      };
    }

    case FETCH_CONSENT_PAGE.success: {
      return {
        ...state,
        consentPageData: action.payload as IConsentPageData,
        loading: false,
        loadingError: null,
      };
    }

    case FETCH_CONSENT_PAGE.fail: {
      return {
        ...state,
        consentPageData: null,
        loading: false,
        loadingError: action.payload as 'string',
      };
    }

    case TOGGLE_PREVIEW_MODE: {
      return {
        ...state,
        previewMode: action.payload as boolean,
      };
    }

    default:
      return state;
  }
};
