import bewatecTabletLanguagesMap from './bewatecTabletLanguagesMap';

const changeBewatecTabletLanguage = (language: string) => {
  const mappedLanguage = bewatecTabletLanguagesMap[language];
  const { Android } = window;
  if (Android && Android.changeSystemLocale) {
    Android.changeSystemLocale(mappedLanguage);
  }
};

export default changeBewatecTabletLanguage;
