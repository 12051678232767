import React, { useEffect } from 'react';
import success from '@base-assets/images/slice@2x.png';
import Button from '@base-components/Button';

import './style.scss';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { AppState } from '@base-reducers/index';
import { LanguageProvider } from '@base-utils/LanguageProvider';
import { getSuccessPageNodeContent as getSuccessPageNodeContentAction } from '@base-actions/redeemVoucher';
import { defaultIntlFormat } from '../../i18n/localizedFormat';

interface IRedeemVoucherProps {
  expiryDate?: Date;
  language: string;
  premiumContentPageUrl: string;
  paymentSuccessPageNodeId: string;
  getSuccessPageNodeContent: typeof getSuccessPageNodeContentAction
}

const RedeemVoucherSuccess = ({
  expiryDate,
  language,
  premiumContentPageUrl,
  paymentSuccessPageNodeId,
  getSuccessPageNodeContent,
}: IRedeemVoucherProps) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (!premiumContentPageUrl) {
      getSuccessPageNodeContent(paymentSuccessPageNodeId);
    }
  }, [paymentSuccessPageNodeId, premiumContentPageUrl]);

  return (
    <div className="redeem-voucher-success">
      <img
        className="redeem-voucher-success-image"
        src={success}
        alt="Success"
      />
      <h1 className="redeem-voucher-success-header">
        {t('redeemVoucherSuccess.heading')}
      </h1>
      <div className="redeem-voucher-success-message">
        <p>{t('redeemVoucherSuccess.premiumAccessMessage')}</p>
        {expiryDate && language && (
          <p
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: t('redeemVoucherSuccess.premiumAccessExpiry', {
                expiryDate: `<b>${defaultIntlFormat(expiryDate, LanguageProvider.languageToLocale[language])}</b>`,
                interpolation: {
                  escapeValue: false,
                },
              }),
            }}
          />
        )}
        <a href={premiumContentPageUrl}>
          <Button testId="redeem-voucher-success-link" actionButton className="redeem-voucher-success-link">
            {t('redeemVoucherSuccess.goToPremiumContent')}
          </Button>
        </a>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  getSuccessPageNodeContent: getSuccessPageNodeContentAction,
};

const mapStateToProps = (state: AppState) => ({
  expiryDate: state.redeemVoucher.premiumExpiry,
  premiumContentPageUrl: state.redeemVoucher.premiumContentPageUrl,
  language: state.settings.language,
  paymentSuccessPageNodeId: state.redeemVoucher.paymentSuccessPageNodeId!,
});

export default connect(mapStateToProps, mapDispatchToProps)(RedeemVoucherSuccess);
