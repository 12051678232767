import {
  INavigationState,
  NavigationActionTypes,
  FETCH_FOLDER_CONTENT,
  FETCH_NODE_CONTENT,
} from '@base-store/navigation/types';
import { Folder } from '@base-models/Data/Folder';
import { TEMPLATE_NAVIGATE_TO_UUID } from '@base-constants/templates';

const initialState: INavigationState = {
  loadingContent: false,
  loadingContentError: null,
  currentNode: null,
};

export default (
  state = initialState,
  action: NavigationActionTypes,
): INavigationState => {
  switch (action.type) {
    case FETCH_FOLDER_CONTENT.request:
    case FETCH_NODE_CONTENT.request: {
      return {
        ...state,
        loadingContent: true,
        loadingContentError: null,
      };
    }

    case TEMPLATE_NAVIGATE_TO_UUID: {
      return {
        ...state,
        loadingContentError: null,
      };
    }

    case FETCH_FOLDER_CONTENT.success:
    case FETCH_NODE_CONTENT.success: {
      return {
        ...state,
        loadingContent: false,
        loadingContentError: null,
        currentNode: action.payload as Folder,
      };
    }

    case FETCH_FOLDER_CONTENT.fail:
    case FETCH_NODE_CONTENT.fail: {
      return {
        ...state,
        loadingContentError: action.payload as string,
        loadingContent: false,
      };
    }

    default:
      return state;
  }
};
