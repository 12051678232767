import React, { FC, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';
import { useTranslation } from 'react-i18next';
import { GetPaymentSessionResponseData, Locale } from '../../api/purchaseAPI/types';
import './style.scss';
import { useRedirectUrl } from '../../hooks/useRedirectUrl';
import { useUpgradePremium } from '../../hooks/useUpgradePremium';

enum CheckoutType {
  DropIn = 'dropin', // drop-in integration
  Card = 'card', // web component integration
}

export type CheckoutProps = {
  sessionData: GetPaymentSessionResponseData;
  locale: Locale;
};

const Checkout: FC<CheckoutProps> = ({ sessionData, locale }) => {
  const paymentContainer = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const { t } = useTranslation();
  const { getRedirectUrl } = useRedirectUrl();
  const { upgradePremium } = useUpgradePremium();

  useEffect(() => {
    if (!paymentContainer.current) {
      return;
    }

    if (!sessionData) {
      return;
    }

    const createCheckout = async () => {
      const { getPaymentSession: session } = sessionData;
      const checkout = await AdyenCheckout({
        environment: session.adyenEnvironment,
        clientKey: session.adyenClientApiKey,
        locale,
        analytics: {
          enabled: false,
        },
        session: {
          id: session.sessionId,
          sessionData: session.sessionData,
          amount: session.amount,
          merchantAccount: session.merchantAccount,
          reference: session.reference,
          returnUrl: session.callbackUrl,
          expiresAt: session.expiresAt,
        },
        onPaymentCompleted: async response => {
          const status = response.resultCode.toUpperCase();
          if (status === 'AUTHORISED') {
            await upgradePremium(session.reference, status);
          }
          history.push(getRedirectUrl(response.resultCode), { replace: true });
        },
        onError: error => {
          // eslint-disable-next-line no-console
          console.error(error);
          if (error.name === 'CANCEL') {
            history.push(getRedirectUrl('Refused'), { replace: true });
          } else {
            history.push(getRedirectUrl('error'), { replace: true });
          }
        },
        paymentMethodsConfiguration: {
          paypal: {
            countryCode: 'DE', // Only needed for test. This will be automatically retrieved when you are in production.
          },
          card: {
            hasHolderName: true,
            holderNameRequired: true,
          },
        },
        showPayButton: true,
      });

      if (paymentContainer.current) {
        checkout.create(CheckoutType.DropIn).mount(paymentContainer.current);
      }
    };

    createCheckout();
  }, [sessionData]);

  return (
    <div className="checkout-wrapper">
      <div className="payment-title">{t('checkoutPage.subTitle')}</div>
      <div className="container">
        <div className="payment-container">
          <div ref={paymentContainer} className="payment" />
        </div>
      </div>
    </div>
  );
};

export default Checkout;
