import { idmServiceUrl } from '@base-constants/urls';
import { IWhitelistedIpsResponse } from '@base-models/Data/types';

export default class IdmServiceAPIDataProvider {
  public verifyIPAccessbyOrganization = async (orgId: string): Promise<boolean> => {
    const response = await fetch(
      `${idmServiceUrl}/organizations/${orgId}/verified-client-ip-address`,
      {
        headers: this.getRequestHeaders(),
      },
    );

    const whiteListedIpsResponse: IWhitelistedIpsResponse = await response.json();
    return whiteListedIpsResponse.success;
  };

  // eslint-disable-next-line class-methods-use-this
  private getRequestHeaders(): Headers {
    return new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
  }
}
