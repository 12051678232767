import { deleteCurrentChannelData, getChannelIds } from '@base-utils/renderEpg';
import { subscribeToCurrentChannelEpgChanges, subscribeToEpgDataChanges } from '@base-utils/subscribeToStoreChanges';

export interface ICurrentChannelOptions {
  channelId?: string;
  lang: string;
  descriptionTranslation?: string;
  nextTranslation?: string;
}

export const loadCurrentChannelEpgData = async ({
  channelId,
  lang,
  descriptionTranslation,
  nextTranslation,
}: ICurrentChannelOptions) => {
  deleteCurrentChannelData(channelId);
  if (channelId) {
    subscribeToCurrentChannelEpgChanges({
      lang,
      descriptionTranslation,
      nextTranslation,
    });
    window.TemplateEvents.fetchCurrentEpgData(channelId);
  }
};

const hasEpgChannel = () => {
  const channelIds = getChannelIds();
  return channelIds.some(id => id);
};

export const loadEpgData = (lang: string) => {
  // setTimeout is used to ensure that rendering is finished
  setTimeout(() => {
    // load epg data if at least one epg channel exists
    if (hasEpgChannel()) {
      window.TemplateEvents.fetchEpgData();
      subscribeToEpgDataChanges(lang);
    }
  }, 0);
};
