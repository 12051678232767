import {
  IRedeemVoucherState,
  PaymentPageNode,
  RedeemVoucherActionTypes,
  SET_PREMIUM_CONTENT_PAGE_URL,
  SET_PREMIUM_EXPIRY_DATE,
  SET_PAYMENT_PAGE_NODE_ID,
} from '@base-store/redeemVoucher/types';

const initialState: IRedeemVoucherState = {
  premiumExpiry: undefined,
  premiumContentPageUrl: '',
  paymentSuccessPageNodeId: '',
  paymentCancelPageNodeId: '',
  paymentFailPageNodeId: '',
};

export default (
  state = initialState,
  action: RedeemVoucherActionTypes,
): IRedeemVoucherState => {
  switch (action.type) {
    case SET_PREMIUM_EXPIRY_DATE: {
      return {
        ...state,
        premiumExpiry: action.payload as Date,
      };
    }

    case SET_PREMIUM_CONTENT_PAGE_URL: {
      return {
        ...state,
        premiumContentPageUrl: action.payload,
      };
    }

    case SET_PAYMENT_PAGE_NODE_ID: {
      return {
        ...state,
        ...action.payload as PaymentPageNode,
      };
    }

    default:
      return state;
  }
};
