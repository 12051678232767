import getDomNode from '@base-utils/getDomNode';
import replace from '@base-utils/Replacer';
import { formatDate, formatHour } from '@base-utils/formatDate';

const findAmount = (radios: HTMLElement) => {
  const checkedRadio = radios?.querySelector<HTMLInputElement>('input[type="radio"]:checked');
  if (checkedRadio) return checkedRadio.id;
  return '';
};

const displayText = (text: string, durationSelector: string) => {
  const container = document.querySelector<HTMLElement>(durationSelector);
  if (container) container.innerText = text;
};

const replaceValues = (
  text: string,
  lang: string,
  radios: HTMLElement,
) => {
  const amount = findAmount(radios);
  const today = new Date();
  const expirationDate = today.setDate(today.getDate() + +amount);
  const options = {
    amount,
    day: `${formatDate(expirationDate, lang)}`,
    hour: `${formatHour(expirationDate, lang)}`,
  };

  return replace(text, options);
};

export const interpolateDuration = async (
  text: string,
  lang: string,
  radiosSelector:string,
  durationSelector: string,
) => {
  try {
    const radios = await getDomNode(radiosSelector) as HTMLElement;
    displayText(replaceValues(text, lang, radios), durationSelector);

    radios.addEventListener('change', () => {
      displayText(replaceValues(text, lang, radios), durationSelector);
    });
  } catch {
    displayText('', durationSelector);
  }
};
