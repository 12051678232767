/**
 * @module Schema
 */

import { ISchema, ISchemaField, IRawSchema } from './types';

/**
 * Defines the rules for querying and building Node-objects.
 */
export class Schema implements ISchema {
  name = '';

  uuid = '';

  fields: ISchemaField[] = [];

  isContainer = false;

  /**
   * @param schema Raw data from the API
   */
  constructor(schema: IRawSchema) {
    this.name = schema.name;
    this.uuid = schema.uuid;
    this.fields = schema.fields;
    this.isContainer = schema.container;
  }
}
