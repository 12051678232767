/**
 *  list is based on mesh languages:
 *  https://github.com/BEWATEC-Berlin/mesh-ui/commit/ae506e64cafc286f7ae9e9e0d516151fce22160b
 *
 *  and input from Christian.Dielitz@Bewatec.com
 */

const bewatecTabletLanguagesMap = {
  de: 'de_DE',
  en: 'en_US',
  ar: 'ar_EG',
  cs: 'cs_CZ',
  da: 'da_DK',
  el: 'el_GR',
  es: 'es_ES',
  fi: 'fi_FI',
  fr: 'fr_FR',
  he: 'he_IL',
  hr: 'hr_HR',
  it: 'it_IT',
  nl: 'nl_NL',
  no: 'nb_NO',
  pl: 'pl_PL',
  pt: 'pt_PT',
  ru: 'ru_RU',
  sv: 'sv_SE',
  tr: 'tr_TR',
  zh: 'zh_CN',
} as const;

export default bewatecTabletLanguagesMap;
