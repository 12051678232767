/**
 * @module Primitives
 */

import Image from '@base-models/Image';
import { escapeQuotes } from '@base-utils/Sanitizers';
import {
  NodeField,
  INode,
  IRawImage,
  ISchemaField,
  IPrimitiveField,
  ILinkedNode,
  IMicronode,
  List,
} from './types';

/**
 * List of all possible types (limited by GenticsMesh CMS)
 */
export type BlockType = 'string' | 'number' | 'date' | 'boolean' | 'html' | 'micronode' | 'node' | 'list' | 'binary';

export type TypesMap = Record<string, (a: NodeField, node?: INode, fieldName?: string, projectName?: string) => any>; // eslint-disable-line

/**
 * Returns a full object-shaped description of a single field.
 * Resolves value of the field by defined map.
 */
export class PrimitiveField implements IPrimitiveField {
  static TypesMap: TypesMap = {
    string: field => field && escapeQuotes(field as string),
    number: field => (field as number),
    date: field => new Date(field as string),
    boolean: field => (field as boolean),
    html: field => (field as string),
    binary: (field: NodeField, node?: INode, fieldName?: string, projectName?: string) => (
      field ? new Image(projectName || 'default', field as IRawImage, node ? node.uuid : '', fieldName) : null
    ),
    list: field => (field as List),
    node: field => (field as ILinkedNode),
    micronode: field => (field as IMicronode),
  };

  type = 'string';

  schemaName = '';

  name = '';

  label = '';

  required = false;

  value = null as any;


  constructor(projectName: string, field: NodeField, schema: ISchemaField, node: INode) {
    this.type = schema.type;
    this.schemaName = schema.name;
    this.name = escapeQuotes(schema.name);
    this.label = escapeQuotes(schema.label);
    this.required = schema.required;
    this.value = PrimitiveField.TypesMap[schema.type](field, node, schema.name, projectName);
  }
}
