import {
  call,
  put,
  takeLeading,
  select,
} from 'redux-saga/effects';
import {
  FETCH_PATIENT_KIS_DATA,
  FETCH_PATIENT_ME,
} from '@base-store/patient/types';
import { IPatient } from '@base-models/Data/types';
import { kisDataProvider } from '@base-auth/Authentication';
import { AppState } from '@base-reducers/index';
import backendAPI from '@base-api/backendAPI';

type KISData = AppState['settings']['KIS'];

function* fetchPatientKISData() {
  try {
    const KISPlainData: KISData = yield select((state: AppState) => state.settings.KIS);
    // Wait for KISData to be fetched and processed
    // KIS data fetch is not called directly from here because there can be multiple consumers
    // Instead we have this promise to wait for
    const resolvedKis: KISData = yield KISPlainData.resolve;
    if (!resolvedKis.enabled) {
      throw new Error('KIS is unavailable for this project');
    }
    const data: IPatient = yield call(kisDataProvider.getKISData, resolvedKis.url);
    if (!data) {
      throw new Error('No KIS connection verified or Server is not available');
    }
    yield put({
      type: FETCH_PATIENT_KIS_DATA.success,
      payload: { data, loadingError: null },
    });
  } catch (e) {
    console.log('Fetch patient kis data failed', e); // eslint-disable-line
    yield put({
      type: FETCH_PATIENT_KIS_DATA.fail,
      payload: {
        loadingError: e,
        data: null,
      },
    });
  }
}

export function* fetchPatientMe() {
  try {
    const data: ReturnType<typeof backendAPI.getMeData> = yield call(backendAPI.getMeData);
    yield put({
      type: FETCH_PATIENT_ME.success,
      payload: { data, loadingError: null },
    });
  } catch (e) {
    console.error('Fetch patient me data failed', e); // eslint-disable-line
    yield put({
      type: FETCH_PATIENT_ME.fail,
      payload: {
        loadingError: e,
        data: null,
      },
    });
  }
}

function* patientSaga() {
  yield takeLeading(FETCH_PATIENT_KIS_DATA.request, fetchPatientKISData);
  yield takeLeading(FETCH_PATIENT_ME.request, fetchPatientMe);
}

export default patientSaga;
