import {
  MediaViewerActionTypes,
  SET_CURRENTLY_PLAYING_VIDEO,
  SET_CURRENT_AGE_RESTRICTION,
} from '@base-store/mediaViewer/types';
import { IVideoOptions } from '@base-utils/TemplateProcessor/Helpers/mediaPlayer';

export function setCurrentlyPlayingVideo(payload: IVideoOptions): MediaViewerActionTypes {
  return {
    type: SET_CURRENTLY_PLAYING_VIDEO,
    payload,
  };
}

export function setCurrentAgeRestriction(payload: number): MediaViewerActionTypes {
  return {
    type: SET_CURRENT_AGE_RESTRICTION,
    payload,
  };
}
