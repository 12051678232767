import {
  UserActionTypes,
  VERIFY_CODE,
  TOGGLE_SHOW_CODE_VERIFICATION_SCREEN,
} from '@base-store/user/types';

export function verifyCode(connectionCode: string): UserActionTypes {
  return {
    type: VERIFY_CODE.request,
    payload: {
      connectionCode,
    },
  };
}


export function toggleCodeVerificationIsShown(shouldShow: boolean): UserActionTypes {
  return {
    type: TOGGLE_SHOW_CODE_VERIFICATION_SCREEN,
    payload: {
      shouldShow,
    },
  };
}
