import auth0 from 'auth0-js';
import Auth0Authentication from '@bewatec-berlin/cc-js-utils/src/auth/index';
import { ITokenData } from '@bewatec-berlin/cc-js-utils/src/auth/Authentication';
import { IBaseAuthentication } from '@base-models/Data/types';
import { AuthenticationError, InitialisationError } from '@base-models/errors';
import { IService } from './Services';

interface IServiceAuthentication {
  readonly isServiceConsentGiven: boolean;
  giveServiceConsent: (value: boolean) => void
  clearServiceConsent: () => void
  authorizeService: (redirectUri: string) => void
}

export interface IToken extends Pick<ITokenData, 'accessToken' | 'state' | 'scope'> {
  appState: {
    targetUrl: string
  }
}

interface IConfig {
  domain: string;
  clientID: string;
  audience: string;
}

class ServiceAuthentication extends Auth0Authentication
  implements IServiceAuthentication, IBaseAuthentication {
  public AuthenticationError = new AuthenticationError(`${this.constructor.name} authentication failed`);

  public InitializationError = new InitialisationError(`webAuth for ${this.constructor.name} is not initialized`);

  private serviceStorage: WindowLocalStorage['localStorage'] | null;

  private service: IService;

  private auth0WebAuth: auth0.WebAuth;

  private auth0AccessToken: string = '';

  private config!: IConfig;

  public authenticated = false;

  constructor(
    storage: WindowLocalStorage['localStorage'] | null,
    authConfig: IConfig,
    service: IService,
  ) {
    // @ts-ignore
    super(storage, authConfig, auth0.WebAuth);
    this.auth0WebAuth = new auth0.WebAuth({
      domain: authConfig.domain,
      clientID: authConfig.clientID,
      audience: authConfig.audience,
      responseType: 'token',
      redirectUri: `${window.location.origin}`,
    });
    this.serviceStorage = storage;
    this.service = service;
    this.config = authConfig;
  }

  public getAccessToken = () => this.auth0AccessToken;

  public get isServiceConsentGiven() {
    if (this.serviceStorage) {
      const consent = this.serviceStorage.getItem(this.service.storageKey);
      if (consent) return JSON.parse(consent);
    }
    return false;
  }

  public giveServiceConsent(value: boolean) {
    if (this.serviceStorage) {
      this.serviceStorage.setItem(this.service.storageKey, value.toString());
    }
  }

  public clearServiceConsent() {
    if (this.serviceStorage) {
      this.serviceStorage.removeItem(this.service.storageKey);
    }
  }

  public isServiceToken(tokenData: IToken) {
    const { targetUrl } = tokenData.appState;
    return !!(targetUrl)?.includes(`service=${this.service.name}`);
  }

  private fetchToken = async (audience: string, scope: string): Promise<string> => (
    new Promise((resolve, reject) => {
      if (this.auth0WebAuth === null) {
        return reject(this.InitializationError);
      }
      this.auth0WebAuth.checkSession(
        { audience, scope },
        (err, res) => {
          if (err) {
            return reject(err);
          }
          return resolve(res.accessToken);
        },
      );
    })
  );

  public authenticate = async () => {
    try {
      this.auth0AccessToken = await this.fetchToken(
        this.config.audience as string,
        this.service.scope,
      );
      this.authenticated = true;
      return true;
    } catch (e) {
      this.authenticated = false;
      throw this.AuthenticationError;
    }
  };

  public authorizeService = (redirectUri: string) => {
    this.Authorize({
      redirectUri,
      scope: this.service.scope,
      targetUrl: `${window.location.href.split('?')[0]}?service=${this.service.name}`,
    });
  };
}

export default ServiceAuthentication;
