import RequestActionType from '@base-utils/requestAction';
import { IPatient } from '@base-models/Data/types';

export type IPatientState = {
  loading: boolean
  loadingError: Error | string | null
  resolve: Promise<IPatient | null>
} & Pick<IPatient, 'kisConnectionVerified' | 'permissions' | 'roles'>;

export const FETCH_PATIENT_KIS_DATA = new RequestActionType('FETCH_PATIENT_KIS_DATA');
export const FETCH_PATIENT_ME = new RequestActionType('FETCH_PATIENT_ME');

export interface IFetchPatientAction {
  type: typeof FETCH_PATIENT_KIS_DATA.request
  payload: {
    loadingError: IPatientState['loadingError']
    data: IPatient | null
  }
}

export interface IFetchPatientMeAction {
  type: typeof FETCH_PATIENT_ME.request
  payload: {
    loadingError: IPatientState['loadingError']
    data: IPatient
  }
}

export type PatientActionTypes = IFetchPatientAction | IFetchPatientMeAction;
