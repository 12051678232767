import { localeLanguageCodeMap } from '@base-utils/localeLanguageCodeMap';

export type TDate = string | number | Date;

interface IDateFormatOptions {
  day?: string,
  month?: string,
  year?: string
  hour?: string,
  minute?: string,
  timeZoneName?: string,
}

const dateOptions = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
};

const hourOptions = {
  hour: '2-digit',
  minute: '2-digit',
};

const timeOptions = {
  ...hourOptions,
  timeZoneName: 'short',
};

const dateTimeOptions = {
  ...dateOptions,
  ...timeOptions,
};

const format = (date: TDate, options: IDateFormatOptions, locale?: string) => {
  if (!date) return;
  if (typeof locale !== 'string') return;
  if ('toLocaleString' in Date) {
    return new Date(date).toLocaleString((locale && localeLanguageCodeMap[locale]) || 'en-US', options);
  }
  return date;
};

const getRemainingTime = (date: TDate) => {
  const futureDate = new Date(date);
  return futureDate.getTime() - Date.now();
};

const getRemainingDaysHoursMins = (date: TDate | undefined | null) => {
  if (!date) {
    return undefined;
  }
  const time = getRemainingTime(date);
  const min = 1000 * 60;
  const hour = min * 60;
  const day = hour * 24;
  return time > 0 ? {
    days: Math.floor(time / day),
    hours: Math.floor((time % day) / hour),
    mins: Math.floor((time % hour) / min),
  } : undefined;
};

const formatHour = (date: TDate, locale?: string) => format(date, hourOptions, locale);

const formatTime = (date: TDate, locale?: string) => format(date, timeOptions, locale);

const formatDate = (date: TDate, locale?: string) => format(date, dateOptions, locale);

const formatDateAndTime = (date: TDate, locale?: string) => format(date, dateTimeOptions, locale);

export {
  getRemainingTime,
  getRemainingDaysHoursMins,
  formatHour,
  formatTime,
  formatDate,
  formatDateAndTime,
};
