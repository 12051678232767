/**
 * Resolves a deep nested property when the path is not 100% defined.
 * To be used as a null-safe mechanism to get props like
 * `myObject.sectionA.subSectionC.propName`
 * where `myObject`, `sectionA`, `subSectionC` or `propName` can be undefined
 * @example
 * `
 * const color = resolveNestedProp(fetchedTheme, null, 'defaultColors', 'navBar', 'background');
 * `
 * @param object Entry-point object
 * @param defaultValue Will be returned if the path can't be resolved
 * @param keys Path to the property, key by key
 * @returns Property value or defaultValue if the path can't be resolved
 */
export default (object: Record<string, any>, defaultValue: any = undefined, ...keys: string[]) => {
  let currentLevel = object;
  if (typeof currentLevel !== 'object' || currentLevel === null) {
    return defaultValue;
  }
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    if (typeof currentLevel[key] !== 'undefined') {
      currentLevel = currentLevel[key];
    } else {
      return defaultValue;
    }
  }
  return currentLevel;
};
