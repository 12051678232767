import React from 'react';
import { usePortal } from '@base-context/PortalContext';
import AuthRouterProvider from './AuthRouterProvider';
import PortalRouterProvider from './PortalRouterProvider';

export const Auth0Provider = ({ children }) => {
  const portal = usePortal();
  return portal?.isNative ? (
    <PortalRouterProvider>{children}</PortalRouterProvider>
  ) : (
    <AuthRouterProvider>{children}</AuthRouterProvider>
  );
};
