import handlebars from 'handlebars';
import { stripPunctuation } from '@base-utils/Sanitizers';

export const renderCheckbox = (
  content: string,
  className: string,
) => {
  const name = stripPunctuation(content);
  return new handlebars.SafeString(`
    <input
      type="checkbox"
      name=${name}
      class=${className}
    >
  `);
};
