import React from 'react';
import { useSelector } from 'react-redux';
import Button from '@base-components/Button';
import IconLabel from '@base-components/IconLabel';
import { helpCentreUrl } from '@base-constants/urls';
import { AppState } from '@base-reducers/index';
import questionMark from '@base-assets/images/icons/question-mark@2x.png';
import { DeviceTypeDetector } from '@base-utils/deviceTypeDetector';
import './style.scss';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { openInAppBrowser } from '@base-utils/PortalPublishEvent';
import useHelpCenterSlug from '../../hooks/useHelpCenterSlug';
import { usePortal } from '../../context/PortalContext';


function HelpCenterButton() {
  const { t } = useTranslation();
  const location = useLocation();
  const slug = useHelpCenterSlug();
  const portal = usePortal();

  const isHelpCenterEnabled = useSelector(
    (state: AppState) => state.settings.projectConfig.isHelpCenterEnabled,
  );

  const currentLanguage = useSelector(
    (state: AppState) => state.settings.language,
  );

  if (!isHelpCenterEnabled || !location.pathname.includes('/node/') || DeviceTypeDetector.isBewatecTablet()) {
    return null;
  }

  const url = `${helpCentreUrl}/${currentLanguage}${slug}`;

  return (
    <div data-test-href={url}>
      <Button
        testId="help-center-button"
        className="help-center-button"
        onClick={() => {
          if (portal?.isNative) {
            openInAppBrowser(url);
          } else {
            window.open(url, '_blank');
          }
        }
        }
      >
        <IconLabel
          icon={questionMark}
          text={t('helpCenterButton')}
          className="img-question-mark"
        />
      </Button>
    </div>
  );
}

export default HelpCenterButton;
