
/**
 *  could be localized
 */
export const Notifications = {
  pageLoadErr: 'Page load failed',
  navLoadErr: 'Loading navigation failed',
  schemasLoadErr: 'Loading schemas failed',
  templatesLoadErr: 'Loading templates failed',
  noConfigErr: 'We couldn\'t find your project, using default instead',
  fontSizeChangeErr: 'Font Size Change failed',
  languageChangeErr: 'Language Change failed',
  loginErr: 'Authorization failed',
  KISDataLoadErr: 'KIS data fetching failed',
  missingIdErr: 'Missing product ID',
  generalErr: 'Something went wrong!',
  codeIsVerified: 'Code has been verified',
  KISDataNotEnabled: 'KIS not enabled for this hospital',
  epgLoadErr: 'Loading EPG data failed',
  epgCurrentLoadErr: 'Loading EPG data for current program failed',
  missingUrl: 'Missing URL for redirection',
};
