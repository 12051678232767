import {
  call,
  put,
  takeLatest,
  select,
} from 'redux-saga/effects';
import { cmsApi } from '@base-api/cmsApi';
import {
  FETCH_NODE_CONTENT,
  FetchNodeAction,
} from '@base-store/navigation/types';
import { NodeFactory, OneOfTypes } from '@base-models/Data/NodeFactory';
import { LANGUAGE_CHANGE } from '@base-store/settings/types';
import { Folder } from '@base-models/Data/Folder';
import NodeQueryBuilder from '@base-utils/NodeQueryBuilder';
import {
  ILinkedNode,
  IRawNode,
  PermissionType,
  RoleType,
} from '@base-models/Data/types';
import { AppState } from '@base-reducers/index';
import { history, store } from '@base-store/index';
import { TEMPLATE_NAVIGATE_TO_UUID } from '@base-constants/templates';
import { mainPageUUID } from '@base-constants/urls';
import { Notifications } from '@base-constants/notifications';
import { displayError } from '@base-utils/Notifications';
import PreviewMode from '@base-utils/PreviewMode';
import { SchemaNames } from '@base-constants/schemas';
import BackendTokenAuthentication from '@base-auth/2.0/BackendTokenAuthentication';
import { IEvent } from '@bewatec-berlin/cc-metrics-tools/src/types';


const SharedLoginServicesFilter = (item: OneOfTypes) => !(
  item instanceof Folder
    && [
      SchemaNames.PremiumUpgrade as string,
      SchemaNames.MyPremiumEntertainment as string,
    ].includes(item.schema.name)
    && BackendTokenAuthentication.isSharedLogin()
);

const MyPremiumUpgradeSchemaFilter = (item: OneOfTypes) => {
  const { activePremiumUpgrade } = store.getState().settings;
  const { permissions, roles } = store.getState().patient;
  return !(
    item instanceof Folder
    && SchemaNames.MyPremiumEntertainment === item.schema.name
    && !Boolean(activePremiumUpgrade?.endDate)
    && (permissions?.includes(PermissionType.Premium) || roles?.includes(RoleType.Premium))
  );
};

export function* getNodeContent(uuid: string) {
  try {
    const isPreviewMode = PreviewMode.isEnabled();
    const language = yield select((state: AppState) => state.settings.language);
    const projectName = yield select((state: AppState) => state.settings.projectConfig.name);
    const request = NodeQueryBuilder.queryNodeById(uuid, language);
    const data = yield call(cmsApi.graphQLRequest, projectName, request, isPreviewMode);
    const contentNode: IRawNode = data.node;
    const result = NodeFactory.MapBySchema(projectName, contentNode);

    if (contentNode.isContainer) {
      (result as Folder).children = NodeFactory
        .MapList(projectName, data.node.children.elements as IRawNode[])
        .filter(SharedLoginServicesFilter)
        .filter(MyPremiumUpgradeSchemaFilter);
    }

    yield put({
      type: FETCH_NODE_CONTENT.success,
      payload: result,
    });
  } catch (e) {
    const message = Notifications.pageLoadErr;
    yield call(displayError, { message, e });
    yield put({
      type: FETCH_NODE_CONTENT.fail,
      payload: message,
    });
  }
}

export function* getMainPage() {
  try {
    const isPreviewMode = PreviewMode.isEnabled();
    const language = yield select((state: AppState) => state.settings.language);
    const projectName = yield select((state: AppState) => state.settings.projectConfig.name);
    const request = NodeQueryBuilder.queryMainPage(language);
    const data = yield call(cmsApi.graphQLRequest, projectName, request, isPreviewMode);
    const mainPage: IRawNode = data.nodes.elements[0];
    const result = NodeFactory.MapBySchema(projectName, mainPage);

    if (mainPage.isContainer) {
      (result as Folder).children = NodeFactory
        .MapList(projectName, data.nodes.elements[0].children.elements as IRawNode[])
        .filter(SharedLoginServicesFilter)
        .filter(MyPremiumUpgradeSchemaFilter);
    }

    yield put({
      type: FETCH_NODE_CONTENT.success,
      payload: result,
    });
  } catch (e) {
    const message = Notifications.pageLoadErr;
    yield call(displayError, { message, e });
    yield put({
      type: FETCH_NODE_CONTENT.fail,
      payload: message,
    });
  }
}

export function* onNodeChange(action: FetchNodeAction) {
  const node = typeof action.payload === 'string' ? { uuid: action.payload }
    : action.payload as ILinkedNode;

  if (node.uuid === mainPageUUID) {
    yield call(getMainPage);
  } else {
    yield call(getNodeContent, node.uuid);
  }
}

export function* onLanguageChange() {
  const schemas = yield select((state: AppState) => state.schemas.schemas);
  if (!schemas) return;

  const currentNode = yield select((state: AppState) => state.navigation.currentNode);
  if (currentNode) {
    yield call(onNodeChange, {
      type: FETCH_NODE_CONTENT.request,
      payload: currentNode,
    });
  }
}

export function* navigateFromTemplate(action: IEvent) {
  const uuid = action.payload as string;
  localStorage.setItem('previousUrl', window.location.href);
  if (action.meta?.actionType === 'NavigationItem') {
    localStorage.setItem('currentMainNavigationItem', uuid);
  }
  history.push(`/node/${uuid}`);
  yield;
}

function* navigationSaga() {
  yield takeLatest(FETCH_NODE_CONTENT.request, onNodeChange);
  yield takeLatest(TEMPLATE_NAVIGATE_TO_UUID, navigateFromTemplate);
  yield takeLatest(LANGUAGE_CHANGE.success, onLanguageChange);
}

export default navigationSaga;
