import backendAPI from '@base-api/backendAPI';
import { toggleCodeVerificationIsShown } from '@base-actions/user';
import { store } from '@base-store/index';
import {
  VALIDATE_CODE_IS_VERIFIED_SUCCESS,
  VALIDATE_CODE_IS_VERIFIED_FAIL,
} from '@base-store/user/types';

async function validateKisConnection(): Promise<boolean> {
  try {
    /* askedForValidation is a flag which helps avoid the helper function to be executed twice.
      * Template handlebars normally is executing it's resolvers twice so it causes to validate
      * the kis connection also when navigating, which causes a bug to show the connection
      * code screen again. This way the flag is put to false when the node is resolved
    */
    const { askedForValidation } = store.getState().user;
    if (askedForValidation) {
      return false;
    }
    const me = await backendAPI.getMeData();
    if (me.kisConnectionVerified) {
      store.dispatch({ type: VALIDATE_CODE_IS_VERIFIED_SUCCESS });
    } else {
      store.dispatch({ type: VALIDATE_CODE_IS_VERIFIED_FAIL });
    }
    return Boolean(me.kisConnectionVerified);
  } catch (e) {
    store.dispatch(toggleCodeVerificationIsShown(false));
    return false;
  }
}

export { validateKisConnection };
