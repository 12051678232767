import React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '@base-reducers/index';
import { SchemaNames, SchemaType } from '@base-constants/schemas';
import { pageHelpCenterMap } from '@base-components/HelpCenterButton/pageHelpCenterMap';
import { Folder } from '@base-models/Data/Folder';
import { Node } from '@base-models/Data/Node';
import { CurrentNode } from '@base-store/navigation/types';

const checkChildrenSchema = (
  node: CurrentNode,
  schemaType: SchemaType,
): boolean => node instanceof Folder
  && node.children?.some(
    child => child instanceof Node && child.schema.name === SchemaNames[schemaType],
  );

const slugMap = {
  [SchemaNames.PremiumUpgrade]: () => pageHelpCenterMap.upgradingToPremium,
  [SchemaNames.MediaAudio]: () => pageHelpCenterMap.listeningRadio,
  [SchemaNames.MediaVideo]: () => pageHelpCenterMap.watchingTV,
  [SchemaNames.Folder]: (node: CurrentNode) => {
    if (checkChildrenSchema(node, 'MediaViewer')) {
      return pageHelpCenterMap.entertainment;
    }
    return '';
  },
  [SchemaNames.MediaViewer]: (node: CurrentNode) => {
    if (checkChildrenSchema(node, 'MediaVideo')) {
      return pageHelpCenterMap.watchingTV;
    }

    if (checkChildrenSchema(node, 'MediaAudio')) {
      return pageHelpCenterMap.listeningRadio;
    }

    return '';
  },
};

export default function useHelpCenterSlug() {
  const currentNode = useSelector(
    (state: AppState) => state.navigation.currentNode,
  );

  return React.useMemo(
    () => {
      const slugHandler = slugMap[currentNode?.schema.name ?? ''];
      if (slugHandler) {
        return slugHandler(currentNode);
      }
      return '';
    }, [currentNode],
  );
}
