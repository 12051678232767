import {
  GET_SUCCESS_PAGE_NODE_CONTENT,
  PaymentPageNode,
  RedeemVoucherActionTypes,
  SET_PREMIUM_CONTENT_PAGE_URL,
  SET_PREMIUM_EXPIRY_DATE,
  SET_PAYMENT_PAGE_NODE_ID,
} from '@base-store/redeemVoucher/types';

export function setPremiumExpiryDate(
  payload: Date,
): RedeemVoucherActionTypes {
  return {
    type: SET_PREMIUM_EXPIRY_DATE,
    payload,
  };
}


export function setPremiumContentPageUrl(
  url: string,
): RedeemVoucherActionTypes {
  return {
    type: SET_PREMIUM_CONTENT_PAGE_URL,
    payload: url,
  };
}

export function getSuccessPageNodeContent(nodeId: string): RedeemVoucherActionTypes {
  return {
    type: GET_SUCCESS_PAGE_NODE_CONTENT.request,
    payload: nodeId,
  };
}

export function setPaymentPageNodeId(page: PaymentPageNode): RedeemVoucherActionTypes {
  return {
    type: SET_PAYMENT_PAGE_NODE_ID,
    payload: page,
  };
}
