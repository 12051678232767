import React from 'react';

import './style.scss';

const Watermark: React.SFC = () => (
  <p className="watermark">
    © BEWATEC Connected.Care GmbH
  </p>
);

export default Watermark;
