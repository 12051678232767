import getDomNode from '@base-utils/getDomNode';

export const applyInputValue = (input: HTMLInputElement, value: string) => {
  switch (input.type) {
    case 'checkbox':
      input.checked = value === 'on';
      break;
    default:
      input.value = value;
  }
};

export const applyInputState = async (name: string, value: any) => {
  const input = await getDomNode(`[name=${name}]`) as HTMLInputElement;
  applyInputValue(input, value);
};

export const getFormData = (form: HTMLFormElement) => {
  const formData = new FormData(form);
  return [...formData.entries()];
};
