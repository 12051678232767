import { shareMagazinesUrl, notFoundUrl, mainPageUUID } from '@base-constants/urls';
import backendAPI from '@base-api/backendAPI';
import { Services } from '@base-models/Data/Services';
import { store, history } from '@base-store/index';
import { IServiceAuthData, IErrorResponse } from '@base-models/Data/types';
import { displayError } from '@base-utils/Notifications';
import { Notifications } from '@base-constants/notifications';
import { DeviceTypeDetector } from '@base-utils/deviceTypeDetector';
import { portalContextClient } from '@base-context/PortalContext';
import { openInAppBrowser } from '@base-utils/PortalPublishEvent';

const openUrl = (url: string, parentUuid?: string, newTab?: boolean) => {
  if (!url) {
    displayError({ message: Notifications.missingUrl });
  } else if (portalContextClient.context?.isNative) {
    window.history.back();
    openInAppBrowser(url);
  } else if (newTab && typeof newTab === 'boolean') {
    window.history.back();
    window.open(url, '_blank');
  } else {
    const isAppLink = /^(?!(http|https):\/\/).+:\/\//;
    const trimmedUrl = url.trim();
    if (isAppLink.test(trimmedUrl)) {
      window.history.back();
    } else {
      const previousUrl = localStorage.getItem('previousUrl');
      /**
       *  when called with just 1 param 'parentUuid' becomes:
       *  '{lookupProperty: function, name: "open_url", hash: {}, data: Object, ...'
       *  so we check if we actually got a string
       *
      */
      const parentUuidString = typeof parentUuid === 'string' ? parentUuid : mainPageUUID;
      window.history.replaceState({}, '', previousUrl || `/node/${parentUuidString}`);
    }
    setTimeout(() => {
      window.location.href = url;
    }, 10);
  }
};

const openSharemagazines = async (parentUuid?: string, newTab?: boolean) => {
  const { hospitalUuid } = store.getState().settings;
  const response = await backendAPI.getServiceAuthData(hospitalUuid, Services.shareMagazines);
  const { token } = response as IServiceAuthData;
  if (token) {
    const redirectionUrl = `${shareMagazinesUrl}?ident=${token}`;
    openUrl(redirectionUrl, parentUuid, newTab);
  } else {
    const { message } = response as IErrorResponse;
    // eslint-disable-next-line
    console.error(message);
    history.push(notFoundUrl);
  }
};

const openCommunicationHelp = async (parentUuid: string, url: string) => {
  if (!url) {
    throw new Error('URL is missing');
  }
  const { language, hospitalUuid } = store.getState().settings;
  const response = await backendAPI.getServiceAuthData(
    hospitalUuid, Services.vidatalk,
  );
  const { token } = response as IServiceAuthData;

  const serviceUrl = `${url}?${token}&language=${language}`;

  if (!token) {
    const { message } = response as IErrorResponse;
    // eslint-disable-next-line
    console.error(message);
    history.push(notFoundUrl);
  }
  if (DeviceTypeDetector.isTouchDevice()) {
    openUrl(serviceUrl, parentUuid, false);
  } else {
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.allow = 'camera; microphone';
    iframe.onload = () => {
      setTimeout(() => {
        openUrl(serviceUrl, parentUuid, true);
        document.body.removeChild(iframe);
      }, 1000);
    };
    iframe.src = serviceUrl;
    document.body.appendChild(iframe);
  }
};

export {
  openUrl,
  openSharemagazines,
  openCommunicationHelp,
};
