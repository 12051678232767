import { IAuthConfig } from '@base-models/Data/types';
import { TokenAuthentication } from './TokenAuthentication';
import AbstractAuthenticationModule from './AbstractAuthenticationModule';

type AuthDataResponse = {
  data: {
    logicalApi_access_token: string;
  };
};

export default class BackendTokenAuthentication extends TokenAuthentication {
  static readonly UrlParamsKey = 'b-token';

  static readonly StorageKey = 'isBackendTokenAuthentication';

  static ConfirmAuthFlow = (location = window.location) => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.has(BackendTokenAuthentication.UrlParamsKey);
  };

  fetchType = 'shared-login' as const;

  constructor(authConfig: IAuthConfig, storage: Storage) {
    super(authConfig, storage);
    this.authType = BackendTokenAuthentication.StorageKey;
  }

  getQueryStringTokenKey = () => BackendTokenAuthentication.UrlParamsKey;

  public static isSharedLogin = (): boolean => Boolean(
    localStorage.getItem(BackendTokenAuthentication.UrlParamsKey)
        && localStorage.getItem(AbstractAuthenticationModule.AuthModuleStorage)
          === BackendTokenAuthentication.StorageKey,
  );

  public static setSharedLogin = (token: string) => {
    localStorage.setItem(BackendTokenAuthentication.UrlParamsKey, token);
  };
}
