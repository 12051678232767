import {
  ProgramVerificationActionTypes,
  SET_PROGRAM_VERIFICATION,
  INVALID_PIN_ENTERED,
  VALID_PIN_ENTERED,
  UNLOCK_PIN_VERIFICATION,
  FETCH_PERSONAL_PIN,
} from '@base-store/programVerification/types';

export function setProgramVerification(
  programId:string,
  channelId: string,
): ProgramVerificationActionTypes {
  return {
    type: SET_PROGRAM_VERIFICATION,
    payload: {
      programId,
      channelId,
    },
  };
}

export function invalidPINEntered(): ProgramVerificationActionTypes {
  return {
    type: INVALID_PIN_ENTERED,
    payload: null,
  };
}

export function validPINEntered(): ProgramVerificationActionTypes {
  return {
    type: VALID_PIN_ENTERED,
    payload: null,
  };
}

export function unlockPinVerification(): ProgramVerificationActionTypes {
  return {
    type: UNLOCK_PIN_VERIFICATION,
    payload: null,
  };
}

export function fetchPersonalPin(): ProgramVerificationActionTypes {
  return {
    type: FETCH_PERSONAL_PIN.request,
    payload: null,
  };
}
