import { useCallback } from 'react';
import { useAppSelector } from './useAppSelector';

export const useRedirectUrl = () => {
  const {
    paymentSuccessPageNodeId,
    paymentFailPageNodeId,
    paymentCancelPageNodeId,
  } = useAppSelector(state => state.redeemVoucher);

  const getRedirectUrl = useCallback(
    (resultCode: string) => {
      switch (resultCode.toLowerCase()) {
        case 'authorised':
        case 'pending':
        case 'received':
          return `/node/${paymentSuccessPageNodeId}`;
        case 'refused':
          return `/node/${paymentFailPageNodeId}`;
        case 'cancelled':
          return `/node/${paymentCancelPageNodeId}`;
        default:
          return `/node/${paymentFailPageNodeId}`;
      }
    },
    [paymentSuccessPageNodeId, paymentFailPageNodeId, paymentCancelPageNodeId],
  );

  return { getRedirectUrl };
};
