enum SupportedLocales {
  enUS = 'en-US',
  deDE = 'de-DE',
}

export const SupportedLocalesArray = Object.values(
  SupportedLocales,
).map(lang => lang.toString());

export const defaultFallbackLanguage = SupportedLocales.enUS;

export { SupportedLocales };
