import handlebars from 'handlebars';

import createCliniserveAPI from '@base-api/cliniserveAPI';
import { KISDataMissingError, NoKISAccessError } from '@base-models/errors';
import { validateKisConnection } from '@base-utils/validateKisConnection';
import { portalContextClient } from '@base-context/PortalContext';
import { openInAppBrowser } from '@base-utils/PortalPublishEvent';
import {
  appendUrl, appendError, rewriteAuthRedirectionHistory, hideLoader,
} from './utils';
import { ServiceCallResolverParams } from './types';
import './style.scss';

export default ({
  className,
  url,
  errorServiceUnavailable,
  errorMissingData,
  allowfullscreen,
}: ServiceCallResolverParams) => {
  rewriteAuthRedirectionHistory();
  (async () => {
    const kisConnectionVerified = await validateKisConnection();
    if (!kisConnectionVerified) {
      return;
    }
    const cliniserveAPI = createCliniserveAPI();
    cliniserveAPI.getCliniserveRequestData()
      .then(data => {
        const cliniserveUrl = `${url.replace(/\/$/, '')}/${data.department}/${data.room}/${data.bed}?useHISNames=true`;
        if (portalContextClient.isNative) {
          openInAppBrowser(cliniserveUrl);
          window.history.back();
        } else {
          appendUrl(className, cliniserveUrl);
        }
        hideLoader();
      })
      .catch(e => {
        hideLoader();
        if (e instanceof NoKISAccessError) {
          appendError(className, errorServiceUnavailable);
          return;
        }
        if (e instanceof KISDataMissingError) {
          appendError(className, errorMissingData);
        }
      });
  })();

  if (portalContextClient.isNative) {
    return new handlebars.SafeString(`
      <div class="${className}-container">
        <div class="loader-container">
          <div class="loader"></div>
        </div>
      </div>
    `);
  }
  return new handlebars.SafeString(`
  <div class="${className}-container">
    <div class="loader-container">
      <div class="loader"></div>
    </div>
    <iframe
      class="${className}"
      ${allowfullscreen ? 'allowfullscreen' : ''}
    >
    </iframe>
  </div>
`);
};
