import { SchemaNames } from '@base-constants/schemas';
import { VIDEO_PLAYER_HTML } from '@base-constants/templates';
import getDomNode from '@base-utils/getDomNode';
import { IMediaOptions } from '@base-utils/TemplateProcessor/Helpers/mediaPlayer';
import getVideoJs from './getVideoJs';

interface IMediaPlaybackOptions extends IMediaOptions {
  schema: string
}

export async function startMediaPlayback({
  src,
  autoplay,
  type,
  schema,
}: IMediaPlaybackOptions) {
  switch (schema) {
    case SchemaNames.MediaVideo:
      playVideo({ src, autoplay, type });
      break;
    case SchemaNames.MediaYTVideo:
      playYTVideo({ src });
      break;
    case SchemaNames.MediaAudio:
      playAudio({ src, autoplay });
      break;
    default:
      break;
  }
}

async function playAudio({ src, autoplay }) {
  const player = await getDomNode('#mediaPlayer') as HTMLAudioElement;
  player.src = src;
  if (autoplay) {
    player.addEventListener('loadedmetadata', () => player.play());
  }
}


async function playYTVideo({ src }) {
  const player = await getDomNode('#mediaPlayer') as HTMLIFrameElement;
  player.src = src;
}


async function playVideo({
  src,
  autoplay,
  type,
}: IMediaOptions) {
  const format = type === 'hls' ? 'application/vnd.apple.mpegurl' : `video/${type}`;
  const videojs = await getVideoJs();
  // we cannot instantiate videojs on the same element again
  // so here we dispose the existing <video-js> element and create a new one
  if (videojs.getPlayers()?.mediaPlayer) {
    videojs.getPlayers().mediaPlayer.dispose();
    const playerContainer = await getDomNode('#playerContainer');
    playerContainer.innerHTML = VIDEO_PLAYER_HTML;
  }

  await getDomNode('#mediaPlayer');
  videojs('mediaPlayer', {
    autoplay,
    sources: [
      {
        src,
        type: format,
      },
    ],
    fluid: true,
    liveui: true,
  });
}

export async function showMediaPlayer() {
  const player = (await getDomNode('#mediaPlayer')) as HTMLVideoElement;
  player.style.display = 'block';
}

export async function hideMediaPlayer() {
  const player = (await getDomNode('#mediaPlayer')) as HTMLVideoElement;
  player.style.display = 'none';
}

export async function stopMediaPlayer() {
  const videojs = await getVideoJs();
  const player = videojs('mediaPlayer');
  if (!player.paused()) {
    player.pause();
  }
}

export async function changeMediaTitle(title: string) {
  const name = (await getDomNode('.player-title')) as HTMLElement;
  name.innerText = title;
}

export function changeRadioBanner(url: string) {
  const node = document.querySelector('.radio-banner') as HTMLElement;
  if (node) {
    node.style.backgroundImage = `url(${url})`;
  }
}

export const setChannelActive = (currentChannel: string) => {
  const channels = document.querySelectorAll<HTMLElement>('.channel');
  channels.forEach(channel => {
    if (channel.dataset.channel === currentChannel) {
      channel.classList.add('active');
    } else {
      channel.classList.remove('active');
    }
  });
};

export const detroyCurrentHlsStream = () => {
  if (window['hls']) {
    window['hls'].destroy();
    window['hls'] = null;
  }
};

export const getCurrentHlsStream = () => window['hls'];
