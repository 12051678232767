import React, { FC, ReactElement } from 'react';

import { ccGooglePlayUrl, ccAppleStoreUrl } from '@base-constants/urls';
import Emails from '@base-constants/emails';
import logo from '@base-assets/images/logo.png';
import success from '@base-assets/images/slice@2x.png';
import Button from '@base-components/Button';
import { DeviceTypeDetector } from '@base-utils/deviceTypeDetector';

import './style.scss';

const DownloadAppSubSection: FC = (): ReactElement => {
  // desktop
  if (!DeviceTypeDetector.isTouchDevice()) {
    return (
      <>
        <a href={ccAppleStoreUrl}>
          <Button testId="download-app" actionButton>
            Im App Store herunterladen
          </Button>
        </a>
        <div style={{ marginTop: 20 }}>
          <a href={ccGooglePlayUrl}>
            <Button testId="download-app" actionButton>
              Im Google Play herunterladen
            </Button>
          </a>
        </div>
      </>
    );
  }

  if (DeviceTypeDetector.android()) {
    return (
      <a href={ccGooglePlayUrl}>
        <Button testId="download-app" actionButton>
          Im Google Play herunterladen
        </Button>
      </a>
    );
  }

  // default as app store
  return (
    <a href={ccAppleStoreUrl}>
      <Button testId="download-app" actionButton>
        Im App Store herunterladen
      </Button>
    </a>
  );
};

const DownloadApp: React.SFC = () => {
  const supportEmail = `mailto:${Emails.Support}?subject=${Emails.SupportSubject}`;
  return (
    <div className="download">
      <img className="download-logo" src={logo} alt="ConnectedCare" />
      <img className="download-success" src={success} alt="Success" />
      <h1 className="download-header">Ihr Benutzerkonto ist bereit</h1>
      <div className="download-instructions">
        <p>
          Nun, da Ihre Anmeldedaten vollständig eingerichtet sind, gehen Sie
          bitte zum App Store, um dort die ConnectedCare Patient App zu laden.
          Anschließend melden Sie sich dann mit Ihrem neuen Benutzerkonto an.
        </p>
        <DownloadAppSubSection />
        <p className="download-contact">
          Sollten Sie Probleme mit Ihrem Benutzerkonto haben, zögern Sie bitte
          nicht, uns{' '}
          <a className="download-support-email" href={supportEmail}>
            zu kontaktieren
          </a>
          .
        </p>
      </div>
    </div>
  );
};

export default DownloadApp;
