import {
  IPatient,
  IPatientAddress,
  IPatientInsurance,
  IPatientConsent,
  IHospitalization,
} from '@base-models/Data/types';

export class Patient implements IPatient {
  constructor(
    public medicalCaseId: string,
    public firstName: string,
    public lastName: string,
    public birthDate: string,
    public gender: string,
    public phoneNumber: string,
    public address: IPatientAddress,
    public insurance?: IPatientInsurance,
    public consent?: IPatientConsent,
    public hospitalization?: IHospitalization | null,
  ) {}
}
