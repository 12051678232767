import Authentication from '@base-auth/Authentication';
import SWManager from '@base-utils/SWManager';

class PreviewMode {
  static StorageKey = 'previewMode';

  static storage = sessionStorage;

  static isEnabled(location = window.location) {
    let isPreviewMode = false;
    const queryParams = new URLSearchParams(location.search);
    const storedPreviewMode = PreviewMode.storage.getItem(PreviewMode.StorageKey) as string;
    const previewModeQuery = queryParams.get('cms-editor-preview');
    if (previewModeQuery) {
      PreviewMode.storage.setItem(PreviewMode.StorageKey, previewModeQuery);
      isPreviewMode = previewModeQuery === 'true';
    } else if (storedPreviewMode) {
      isPreviewMode = storedPreviewMode === 'true';
    }
    return isPreviewMode;
  }

  static disable() {
    PreviewMode.storage.removeItem(PreviewMode.StorageKey);
    SWManager.resetCache();
  }
}

Authentication.OnBeforeLogout(() => PreviewMode.disable());

export default PreviewMode;
