import { WebAuth, AuthOptions } from 'auth0-js';
import { IBaseAuthentication } from '@base-models/Data/types';
import { AuthenticationError, InitialisationError } from '@base-models/errors';
import authConfig from '@base-auth/config';
import UniversalLoginAuthentication from '@base-auth/2.0/UniversalLoginAuthentication';
import { addSeconds, isFuture } from 'date-fns';
import { portalContextClient } from '@base-context/PortalContext';
import { TokenAuthentication } from '@base-auth/2.0/TokenAuthentication';

type Token = {
  accessToken: string;
  expiresIn: number;
};

export default abstract class AbstractAPIAuthentication implements IBaseAuthentication {
  public AuthenticationError = new AuthenticationError(
    `${this.constructor.name} authentication failed`,
  );

  public InitializationError = new InitialisationError(
    `webAuth for ${this.constructor.name} is not initialized`,
  );

  private webAuth: WebAuth | null = null;

  protected token?: Token;

  public authenticated = false;

  private config: AuthOptions;

  private fetchAccessTokenPromise?: Promise<any> | null = null;

  public constructor(config: AuthOptions) {
    this.config = {
      ...config,
      responseType: 'token',
      redirectUri: `${window.location.origin}`,
    };
    this.webAuth = new WebAuth(this.config);
  }

  public getAccessToken = () => this.token?.accessToken as string;

  private fetchToken = async (): Promise<Token> => (
    new Promise((resolve, reject) => {
      const accessToken = window.localStorage.getItem(
        TokenAuthentication.LogicalApiAccessTokenStorageKey,
      );
      const expiresIn = window.localStorage.getItem(
        TokenAuthentication.LogicalApiAccessTokenExpireInStorageKey,
      );
      if (accessToken) {
        return resolve({
          accessToken,
          expiresIn: Number(expiresIn),
        });
      }
      if (this.webAuth === null) {
        return reject(this.InitializationError);
      }
      this.webAuth.checkSession({
        audience: this.config.audience,
        scope: authConfig.defaultScope,
        ...this.getOrganization(),
      },
      (err, res) => {
        if (err) {
          return reject(err);
        }
        res.expiresIn = addSeconds(new Date(), res.expiresIn);
        return resolve(res);
      });
    })
  );

  public async _authenticate() {
    if (this.fetchAccessTokenPromise) {
      return this.fetchAccessTokenPromise;
    }
    if (this.token && isFuture(this.token?.expiresIn)) {
      this.authenticated = true;
      return true;
    }
    try {
      this.fetchAccessTokenPromise = this.fetchToken();
      this.token = await this.fetchAccessTokenPromise;
      this.authenticated = true;
      return true;
    } catch (e) {
      this.authenticated = false;
      throw this.AuthenticationError;
    } finally {
      this.fetchAccessTokenPromise = null;
    }
  }

  private getOrganization = () => {
    const organization = window.localStorage.getItem(
      UniversalLoginAuthentication.Auth0OrganizationIdKey,
    );
    return organization ? { organization } : {};
  };

  public authenticate = async () => {
    if (portalContextClient.context?.isNative
      && portalContextClient.context?.accessToken
    ) {
      this.token = {
        accessToken: portalContextClient.context.accessToken,
        expiresIn: 0,
      };
      this.authenticated = true;
      return true;
    }
    return this._authenticate();
  };
}
