import handlebars from 'handlebars';

import createBestServiceCallAPI, { bestAuth } from '@base-api/bestServiceCallAPI';
import { KISDataMissingError, NoKISAccessError } from '@base-models/errors';
import { validateKisConnection } from '@base-utils/validateKisConnection';
import { appendUrl, appendError, rewriteAuthRedirectionHistory } from './utils';
import { ServiceCallResolverParams } from './types';

export default ({
  className,
  url,
  urlParams,
  language,
  errorServiceUnavailable,
  errorMissingData,
  allowfullscreen,
}: ServiceCallResolverParams) => {
  rewriteAuthRedirectionHistory();

  (async () => {
    const kisConnectionVerified = await validateKisConnection();
    if (!kisConnectionVerified) {
      return;
    }
    if (!bestAuth.isServiceConsentGiven) {
      bestAuth.authorizeService(window.location.origin);
      return null;
    }
    const bestServiceCallAPI = createBestServiceCallAPI();
    bestServiceCallAPI.getPatientLocation()
      .then(data => {
        const params = urlParams.map(p => `${p}=${encodeURIComponent(data?.[p])}`);
        const concatenator = url.includes('?') ? '&' : '?';
        const dynamicUrl = `${url}${concatenator}${params}&language=${language}`.replace(/,/g, '&');
        appendUrl(className, dynamicUrl);
      })
      .catch(e => {
        if (e instanceof NoKISAccessError) {
          appendError(className, errorServiceUnavailable);
          return;
        }
        if (e instanceof KISDataMissingError) {
          appendError(className, errorMissingData);
        }
      });
  })();

  return new handlebars.SafeString(`
  <div class="${className}-container">
    <iframe
      class="${className}"
      ${allowfullscreen ? 'allowfullscreen' : ''}
    >
    </iframe>
  </div>
`);
};
