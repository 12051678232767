import { bestServiceCallResolver, cliniserveServiceCallResolver } from './IFrameResolver';
import { ResolveIframeParams, ServiceType } from './IFrameResolver/types';

export default ({
  className,
  url,
  urlParams,
  language,
  errorServiceUnavailable,
  errorMissingData,
  allowfullscreen,
  serviceType,
}: ResolveIframeParams) => {
  switch (serviceType) {
    case ServiceType.CliniserveServiceCall:
      return cliniserveServiceCallResolver({
        className,
        url,
        urlParams,
        language,
        errorServiceUnavailable,
        errorMissingData,
        allowfullscreen,
      });
    case ServiceType.BestServiceCall:
    default:
      return bestServiceCallResolver({
        className,
        url,
        urlParams,
        language,
        errorServiceUnavailable,
        errorMissingData,
        allowfullscreen,
      });
  }
};
