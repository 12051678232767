/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/button-has-type */
import * as React from 'react';
import { Dialog, DialogContent } from '@reach/dialog';
import { VisuallyHidden } from '@reach/visually-hidden';
import '@reach/dialog/styles.css';
import './style.scss';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '@base-reducers/index';
import { hideAppDialog } from '@base-actions/settings';

const ContentDialog = () => {
  const dispatch = useDispatch();
  const close = () => dispatch(hideAppDialog());

  const dialog = useSelector(
    (state: AppState) => state.settings.dialog,
  );

  return (
    <Dialog
      className="app__dialog-container"
      allowPinchZoom
      isOpen={dialog.show}
      data-test-id="onboarding-dialog"
      aria-label="Dialog section start"
    >
      <button
        className="close-button"
        data-test-id="onboarding-close"
        onClick={close}
      >
        <VisuallyHidden>Close</VisuallyHidden>
        <span aria-hidden>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="m6 6 12 12M18 6 6 18"
              stroke="#042949"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
      </button>
      <DialogContent className="dialog-content" aria-label="Main content">
        <div className="dialog-description">
          <p>{dialog.mainContentText}</p>
          <p>{dialog.subText}</p>
        </div>
      </DialogContent>
    </Dialog>
  );
};
export default ContentDialog;
