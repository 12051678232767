import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import { useAuth0 } from '@base-auth/index';
import ConsentsForm from '@base-components/ConsentsForm';
import { fetchPatientMe as fetchPatientMeAction } from '@base-actions/patient';
import ConnectionCode from '@base-components/ConnectionCode';
import { AppState } from '@base-reducers/index';
import Spinner from '@base-components/Spinner';
import BackendTokenAuthentication from '@base-auth/2.0/BackendTokenAuthentication';
import RedirectHome from '@base-pages/redirectHome';

type LoginState = {
  targetUrl: string;
};

interface LoginProps {
  fetchPatientMe: typeof fetchPatientMeAction;
  kisConnectionVerified?: boolean | null;
  patientLoading: boolean;
  kisEnabled: boolean;
  skipConsent: boolean;
}

const LogIn: React.FC<LoginProps> = ({
  fetchPatientMe,
  kisConnectionVerified,
  patientLoading,
  kisEnabled,
  skipConsent,
}) => {
  const { state } = useLocation<LoginState>();
  const history = useHistory();
  const {
    isAuthenticated,
    isConsentGiven,
    acceptConsent,
    loading,
  } = useAuth0();
  const hasFetchedPatientMe = React.useRef<boolean | null>();
  const url = state?.targetUrl || '/';
  const kisUserCanGoHome = isAuthenticated && isConsentGiven && kisConnectionVerified;
  const nonKisUserCanGoHome = isAuthenticated && isConsentGiven && !kisEnabled;
  const consentStep = isAuthenticated && !isConsentGiven;
  const isSharedLogin = BackendTokenAuthentication.isSharedLogin();
  /**
   * Authentication.Login never resolves the promise so there is no way to fetch patient me data.
   * With the current approach the React.Effect checks when the user is authed and
   * then makes a single request via redux to get the patient me data
  */
  useEffect(() => {
    if (isAuthenticated && !hasFetchedPatientMe.current) {
      hasFetchedPatientMe.current = true;
      fetchPatientMe();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (skipConsent && !isSharedLogin && acceptConsent) {
      acceptConsent();
    }
  }, [skipConsent]);

  if (kisUserCanGoHome || nonKisUserCanGoHome) {
    history.replace('/');
    return null;
  }
  if (patientLoading) {
    return <Spinner fullPage />;
  }

  const handleConsent = (ppConsent: boolean, tcConsent: boolean) => {
    if (ppConsent && tcConsent && acceptConsent) {
      acceptConsent();
      if (kisConnectionVerified || !kisEnabled) {
        history.replace(url);
      }
    }
  };

  const codeStep = isAuthenticated && isConsentGiven && kisEnabled && !kisConnectionVerified;
  if (!loading && codeStep && !isSharedLogin) {
    return (
      <ConnectionCode
        isFirstTime
        onAddLaterClick={() => {
          history.push('/node/home');
        }}
      />
    );
  }

  if (!loading && consentStep) {
    return <ConsentsForm handleConsent={handleConsent} />;
  }

  if (!loading) {
    return <RedirectHome />;
  }

  return <Spinner fullPage />;
};

const mapStateToProps = (state: AppState) => ({
  kisConnectionVerified: state.patient.kisConnectionVerified,
  patientLoading: state.patient.loading,
  kisEnabled: state.settings.KIS.enabled,
  skipConsent: state.settings.projectConfig.skipConsentPageForIndividualLogin,
});

const mapDispatchToProps = {
  fetchPatientMe: fetchPatientMeAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(LogIn);
