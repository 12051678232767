/**
 * @module Types
 * Contains all the interfaces related to the fetched data,
 * used as a one-point of import to avoid import-loops.
 */

/**
 * Represents one item of props `fields` in schema.
 * Does not contain all the possible props, used to simplify the
 * initial mapping manipulations
 */
export interface ISchemaField {
  name: string
  type: string
  label: string
  required: boolean
  allow?: any[]
}

/**
 * Represents an unmapped data coming from the API
 */
export interface IRawSchema {
  name: string
  uuid: string
  container: boolean
  fields: ISchemaField[]
}

/**
 * Represents a Schema object as it's used in this App.
 * Mapping is required to transform from [[IRawSchema]]
 */
export interface ISchema {
  name: string
  uuid: string
  isContainer: boolean
  fields: ISchemaField[]
}

/**
 * Represents an unmapped Image data from the API
 */
export interface IRawImage {
  fileName: string;
  dominantColor?: string;
  width: number;
  height: number;
}

/**
 * Represents an Image object as it's used in this App.
 * Mapping is required to transform from [[IRawImage]]
 */
export interface IImage {
  url: string;
  fileName: string;
  dominantColor?: string;
  width: number;
  height: number;
}

export interface IPDF {
  url: string
  fileName: string
}

export interface IProject extends ISchema { }

export type BreadcrumbItem = {
  uuid: string;
  displayName: string | null;
};

/**
 * Represents `fields` property of the [[ISchema]] as dictionary
 */
export interface IFields extends Record<string, any> {
  name: string;
  order?: number;
  node?: IRawNode;
  image?: {
    fileName: string;
    width: number;
    height: number;
    mimeType?: string;
    dominantColor: string;
  }
}

/**
 * Represents a raw generic Node to process data from the API
 * Requires mapping to transform it to an instance of one of the classes.
 */
export interface IRawNode {
  uuid: string;
  isPublished: boolean;
  language: string;
  availableLanguages: string[];
  displayName: string;
  parent: {
    uuid: string
  };
  children?: {
    elements: IRawNode[]
  };
  isContainer: boolean;
  breadcrumb: BreadcrumbItem[],
  fields: IFields;
  schema: {
    name: string;
  };
}

/**
 * Represents a field of the [[Node]], needs to be mapped from the raw data.
 */
export interface IPrimitiveField {
  type: string
  schemaName: string
  name: string
  label: string
  required: boolean
  value: any
}

/**
 * Represents a field of the type [[Micronode]],
 * content item that do not exist independently but is tied to the parent node,
 * e.g., media element
 */
export interface IMicronode {
  uuid: string
  description: string
  name: string
  fields: ISchemaField[]
}

/**
 * Represents a field of the type [[List]]
 * it allows to specify a list of other field types
 */
export type List = string[] | number[] | IImage[] | boolean[] | ILinkedNode[];

/**
 * List of all possible Schema Field Types (limited by GenticsMesh CMS)
 */
export type NodeField = string | number | IImage | boolean | ILinkedNode | IMicronode | List;

/**
 * Represents a generic non-navigational node.
 * Can be ContentNode or Folder.
 * Requires mapping from the raw data.
 */
export interface INode {
  uuid: string;
  language: string;
  availableLanguages: string[];
  parent: string;
  isContainer: boolean;
  displayName: string;
  name: string;
  image?: IRawImage | null;
  breadcrumb: BreadcrumbItem[];
  schema: { name: string };
  fields: Record<string, IPrimitiveField>
}

/**
 * Represents a `node` property of [[INavigationItem]],
 * contains data about the related node
 */
export interface ILinkedNode extends Partial<Node> {
  uuid: string;
  schema: {
    name: string;
  };
}

/**
 * Represents an unmapped NavItem coming from the API
 */
export interface IRawNavigationItem {
  availableLanguages: string[];
  language: string;
  schema: {
    name: string;
  };
  uuid: string;
  isPublished: boolean;
  isContainer?: boolean;
  fields: {
    displayName: string;
    image: IImage | null;
    name: string;
    url: string | null;
    order: number | null;
    node: ILinkedNode | null;
  }
}

/**
 * Represents a NavigationItem that is used in this App.
 * Requires mapping to be transformed from [[IRawNavigationItem]]
 */
export interface INavigationItem {
  title: string
  uuid: string
  image: IImage | null
  language: string
  isExternalLink: boolean
  active?: boolean
}

/**
 * Represents a NavigationItem linked to another node.
 */
export interface IExternalNavLink extends INavigationItem {
  url: string | null
}

/**
 * Represents a NavigationItem linked to external url.
 */
export interface IInternalNavLink extends INavigationItem {
  node: ILinkedNode | null
}

export interface ITemplate {
  html: string | null
  css: string | null
  js: string | null
  params: Record<string, any>
  schema: string
}

export interface ICompiledTemplate extends Omit<ITemplate, 'html'> {
  html: (n: INode | INavigationItem) => string
}

export interface IProjectTemplates {
  globalStyle: string
  templates: Record<string, ICompiledTemplate | ITemplate>
}

export interface IAnalyticsToolConfig {
  type: string | null
  id?: string | number
}

export interface IProjectConfiguration {
  availableLanguages: string[]
  defaultLanguage: string
  name: string | undefined
  analyticsTool: IAnalyticsToolConfig | null
  templatesUrl: string
  privateNoteUrl: string
  termsAndConditionsUrl: string
  skipConsentPageForIndividualLogin: boolean
  isHelpCenterEnabled: boolean
}

export interface IPatientAddress {
  line1: string
  line2: string
  city: string
  postalCode: string
  country: string
}

export enum InsuranceType {
  PRIVATE = 'private',
  PUBLIC = 'public'
}

export interface IPatientInsurance {
  type?: InsuranceType;
  benefits?: string[];
}

export interface IPatientConsent {
  healthProviders: boolean;
  healthInsurance: boolean;
}

export interface IHospitalization {
  startTime?: string
  endTime?: string
  building?: string
  department?: string
  room?: string
  bed?: string
}

export type CliniserveRequestData = {
  insuranceType?: InsuranceType
} & Omit<IHospitalization, 'startTime' | 'endTime' >;

export enum PermissionType {
  Basic = 'mesh:basic',
  Premium = 'mesh:premium',
  Standard = 'mesh:standard',
  Extended = 'mesh:extended',
}

export enum RoleType {
  Basic = 'accessbasic',
  Premium = 'accesspremium',
}
export interface IPatient {
  medicalCaseId: string
  firstName: string
  lastName: string
  birthDate: string
  gender: string
  phoneNumber: string
  address: IPatientAddress
  insurance?: IPatientInsurance
  consent?: IPatientConsent
  hospitalization?: IHospitalization | null
  permissions?: Array<PermissionType>
  roles?: Array<RoleType>
  kisConnectionVerified?: boolean | null
}

interface IInHospitalConfig {
  analytics: IAnalyticsToolConfig
}

interface IAdmissionAppConfig {
  enabled: boolean
}

interface IHospitalConfigData {
  skipConsentPageForIndividualLogin: boolean
  isHelpCenterEnabled: boolean
}

export interface IHospitalData {
  id: string
  name: string
  kisInterfaceEndpointUrl: string | null
  createdAt: string
  defaultLanguage: string
  languages: string[]
  templatesUrl: string
  patientPortal: IInHospitalConfig
  admissionApp: IAdmissionAppConfig
  privateNoteUrl: string
  termsAndConditionsUrl: string
  hospitalConfig: IHospitalConfigData
}

export interface IAuthConfig {
  domain: string
  clientID: string
  audience: string
  defaultScope: string
}

export interface ITokenData {
  exp: string
  iat: string
  scope: string
  permissions: Array<string>
  sub: string
}


// Used by consumers (app, APIs, etc)
export interface IBaseAuthentication {
  getAccessToken: () => string | Promise<string>
  authenticated: boolean
  authenticate: () => Promise<boolean>
}

// Used by service-classes (Router, Scheduler, etc)
export interface IAuthenticationModule {
  init: () => Promise<boolean>
}

export interface IKISAuthentication extends IBaseAuthentication {
  organization?: string
}

export interface IBackendAPIAuthentication extends IBaseAuthentication {}

export interface IPurchaseAPIAuthentication extends IBaseAuthentication {}

export interface IEpgAPIAuthentication extends IBaseAuthentication {}

export interface IPinAPIAuthentication extends IBaseAuthentication {}

export interface IIdmAPIAuthentication extends IBaseAuthentication {}

type iso = string;

export interface IServiceAuthData {
  token: string
  valid_until: iso | null
}

export interface IErrorResponse {
  name: string
  message: string
}

export interface ISuccessResponse {
  success: boolean
}

interface ICurrentProgram {
  programId: string
  title: string
  originalTitle?: string
  shortDescription?: string
  eventDescription?: string
  start: string
  end: string
  duration: number
  genre: string
  aspectRatio?: string
  parentalRating: number
}

interface INextProgram {
  programId: string
  title: string
  originalTitle: string
  start: string
  end: string
  parentalRating: number
}

export interface IEpgChannelData {
  channelId: string
  channelName: string
  currentProgram?: ICurrentProgram | null
  nextProgram?: INextProgram | null
}

export type EpgData = IEpgChannelData[];

export interface IPinValidityResponse {
  validity: PinValidity
}

export enum PinValidity {
  VALID = 'valid',
  NOT_VALID = 'notValid',
  NOT_VALID_AGE = 'notValidAge',
}

export interface IGetPersonalPinResponse {
  pin: string,
  name?: string,
  message?: string,
}

export const PIN_NOT_FOUND: string = 'PIN_NOT_FOUND';

export interface IWhitelistedIpsResponse {
  success: boolean
}
