export const PatientDataPathMap = {
  // Describes the path to the data required when the key is requested
  PATIENT_ADDRESS_CITY: ['address', 'city'],
  PATIENT_ADDRESS_COUNTRY: ['address', 'country'],
  PATIENT_ADDRESS_LINE1: ['address', 'line1'],
  PATIENT_ADDRESS_LINE2: ['address', 'line2'],
  PATIENT_ADDRESS_POSTAL_CODE: ['address', 'postalCode'],
  PATIENT_BIRTH_DATE: ['birthDate'],
  PATIENT_FIRST_NAME: ['firstName'],
  PATIENT_HOSPITALIZATION_BED: ['hospitalization', 'bed'],
  PATIENT_HOSPITALIZATION_BUILDING: ['hospitalization', 'building'],
  PATIENT_HOSPITALIZATION_DEPARTMENT: ['hospitalization', 'department'],
  PATIENT_HOSPITALIZATION_END: ['hospitalization', 'endTime'],
  PATIENT_HOSPITALIZATION_ROOM: ['hospitalization', 'room'],
  PATIENT_HOSPITALIZATION_START: ['hospitalization', 'startTime'],
  PATIENT_INSURANCE_TYPE: ['insurance', 'type'],
  PATIENT_LAST_NAME: ['lastName'],
  PATIENT_MEDICAL_CASE_ID: ['medicalCaseId'],
  PATIENT_PHONE_NUMBER: ['phoneNumber'],
  NOT_FOUND: ['Placeholder value was not found'],
};

export const PatientSimplifiedLoginPathMap = {
  SL_PATIENT_FIRST_NAME: ['firstName'],
  SL_PATIENT_LAST_NAME: ['lastName'],
  NOT_FOUND: ['Patient details were not found'],
};
