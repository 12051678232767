import {
  IPatientState,
  FETCH_PATIENT_KIS_DATA,
  FETCH_PATIENT_ME,
  PatientActionTypes,
} from '@base-store/patient/types';
import { VERIFY_CODE } from '@base-store/user/types';
import { Patient } from '@base-models/Data/Patient';
import { IPatient, PermissionType, RoleType } from '@base-models/Data/types';
import { createWaitPromise } from '@base-utils/waitForData';

const {
  waitForData: waitForPatientData,
  resolveData: resolvePatientData,
} = createWaitPromise<IPatient | null>();

const initialState: IPatientState = {
  loading: false,
  loadingError: null,
  resolve: waitForPatientData,
  kisConnectionVerified: null,
  permissions: new Array<PermissionType>(),
  roles: new Array<RoleType>(),
};

export default (state = initialState, action: PatientActionTypes): IPatientState => {
  switch (action.type) {
    case FETCH_PATIENT_KIS_DATA.request: {
      return {
        ...state,
        loadingError: null,
        loading: true,
      };
    }

    case FETCH_PATIENT_KIS_DATA.success: {
      let patient: Patient | null = null;
      if (action.payload && action.payload.data) {
        const {
          medicalCaseId,
          firstName,
          lastName,
          birthDate,
          gender,
          phoneNumber,
          address,
          insurance,
          consent,
          hospitalization,
        } = action.payload.data as IPatient;
        patient = new Patient(
          medicalCaseId,
          firstName,
          lastName,
          birthDate,
          gender,
          phoneNumber,
          address,
          insurance,
          consent,
          hospitalization,
        );
        resolvePatientData(patient);
      }

      return {
        ...state,
        loadingError: null,
        loading: false,
      };
    }

    case FETCH_PATIENT_KIS_DATA.fail: {
      resolvePatientData(null);
      return {
        ...state,
        loadingError: action.payload.loadingError as string,
        loading: false,
      };
    }

    case FETCH_PATIENT_ME.request: {
      return {
        ...state,
        loading: true,
      };
    }

    case FETCH_PATIENT_ME.success: {
      const { kisConnectionVerified, permissions, roles } = action.payload.data as IPatient;
      return {
        ...state,
        loading: false,
        kisConnectionVerified,
        permissions,
        roles,
      };
    }

    case VERIFY_CODE.success: {
      return {
        ...state,
        kisConnectionVerified: true,
        loadingError: null,
      };
    }

    case FETCH_PATIENT_ME.fail: {
      return {
        ...state,
        loading: false,
      };
    }

    default:
      return state;
  }
};
