import React, { ReactNode } from 'react';
import logo from '@base-assets/images/logo.png';

import './style.scss';

interface IFormContainerProps {
  children: ReactNode
}

const FormContainer = ({ children }:IFormContainerProps) => (
  <div className="formPage">
    <div className="form-container">
      <div className="form-head">
        <img src={logo} alt="ConnectedCare" />
      </div>
      <form className="form">
        {children}
      </form>
    </div>
  </div>
);

export default FormContainer;
