/**
 * @module FormStateManager
 */

import { getLocalStorage } from '@base-utils/browserUtils';

import Authentication from '@base-auth/Authentication';
import { getFormData, applyInputState } from '@base-utils/FormsDOMHandler';
import resolveNestedProp from '@base-utils/nestedPropResolver';

type Storage = WindowLocalStorage['localStorage'] | null;
type FormState = [string, FormDataEntryValue][];
type StoredState = {
  pages: {
    [uuid: string]: {
      [formId: string]: FormState;
    }
  }
};
export interface IFormStateManager {
  updateFormState: (uuid: string, formId: string, form: HTMLFormElement) => void
  restoreFormState: (uuid: string, formId: string) => void
  clearFormStates: () => void;
}

/**
 * Takes care of handling forms state
 */
export class FormStateManager implements IFormStateManager {
  private storageKey = '';

  private uuid = '';

  private formId = '';

  private storage: Storage = null;

  constructor(storage: Storage, storageKey: string) {
    this.storage = storage;
    this.storageKey = storageKey;
  }

  private static applyFormState(formState: FormState) {
    if (formState) {
      formState.forEach(([name, value]) => applyInputState(name, value));
    }
  }

  private getStoredState = () => {
    if (this.storage) {
      const item = this.storage.getItem(this.storageKey);
      if (item) {
        return JSON.parse(item);
      }
      return null;
    }
    return null;
  };

  private getFormState() {
    const { uuid, formId } = this;
    const storedState = this.getStoredState();
    const formState = resolveNestedProp(storedState, null, 'pages', uuid, formId);
    if (formState) {
      return formState;
    }
    return null;
  }

  private insertFormState(formState: FormState, storedState: StoredState) {
    const { uuid, formId } = this;
    return storedState ? ({
      pages: {
        ...storedState.pages,
        [uuid]: {
          ...storedState.pages[uuid],
          [formId]: formState,
        },
      },
    }) : ({
      pages: {
        [uuid]: {
          [formId]: formState,
        },
      },
    });
  }

  private storeState = (formState: FormState, storedState: StoredState) => {
    if (this.storage) {
      const stateToStore = this.insertFormState(formState, storedState);
      this.storage.setItem(this.storageKey, JSON.stringify(stateToStore));
    }
  };

  public updateFormState(uuid: string, formId: string, form: HTMLFormElement) {
    this.uuid = uuid;
    this.formId = formId;
    const formState = getFormData(form);
    const storedState = this.getStoredState();
    this.storeState(formState, storedState);
  }

  public restoreFormState(uuid: string, formId: string) {
    this.uuid = uuid;
    this.formId = formId;
    const formState = this.getFormState();
    FormStateManager.applyFormState(formState);
  }

  public clearFormStates() {
    if (this.storage) {
      this.storage.removeItem(this.storageKey);
    }
  }
}

const FSManager = new FormStateManager(
  getLocalStorage(),
  'FormsState',
);

Authentication.OnBeforeLogout(() => FSManager.clearFormStates());
Authentication.OnLogin(() => FSManager.clearFormStates());

export default FSManager;
