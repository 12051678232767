
export interface ICacheData {
  date: number
}

export interface IMessage {
  type: string,
  payload: any,
}

export class CachingStrategy {
  static MessageTypes = {
    getCachesStatus: 'getCachesStatus',
    cachesStatus: 'cachesStatus',
    invalidateCaches: 'invalidateCaches',
  };

  static StorageKey = 'app-caches';

  private maxLifetime: number = 0;

  private storage: WindowLocalStorage['localStorage'] = localStorage;

  private cachesData: Record<string, ICacheData> = {};

  private sendMessage: Function = () => { };

  private invalidationTimer!: NodeJS.Timeout;

  private offlineReAttemptInterval = 5000;

  constructor(lifetime: number, storage: WindowLocalStorage['localStorage']) {
    this.maxLifetime = lifetime;
    this.storage = storage;
  }

  private onMessage = (message: IMessage) => {
    // console.log('>> Received from SW', message);
    const { type, payload } = message;
    switch (type) {
      case CachingStrategy.MessageTypes.cachesStatus: {
        this.updateCaches(payload);
        break;
      }
      default:
        break;
    }
  };

  private saveCachesData() {
    this.storage.setItem(CachingStrategy.StorageKey, JSON.stringify(this.cachesData));
  }

  public init(sendMessage: Function, subscribe: Function) {
    this.cachesData = JSON.parse(this.storage.getItem(CachingStrategy.StorageKey) || '{}');
    this.sendMessage = sendMessage;
    subscribe(this.onMessage);
    sendMessage(CachingStrategy.MessageTypes.getCachesStatus);
    this.invalidationTimer = setTimeout(this.attemptScheduledInvalidation, this.maxLifetime);
  }

  private attemptScheduledInvalidation = () => {
    if (window.navigator.onLine) {
      this.updateCaches(Object.keys(this.cachesData));
      this.invalidationTimer = setTimeout(this.attemptScheduledInvalidation, this.maxLifetime);
    } else {
      // eslint-disable-next-line
      console.log('No connection, skipping caches invalidation');
      this.invalidationTimer = setTimeout(
        this.attemptScheduledInvalidation,
        this.offlineReAttemptInterval,
      );
    }
  };

  private invalidateCaches(outDatedCaches: string[]) {
    console.log(`Caches ${outDatedCaches} are outdated, requesting invalidation`); // eslint-disable-line
    this.sendMessage('invalidateCaches', outDatedCaches);
  }

  public updateCaches(caches: string[]) {
    let outDatedCaches: string[] = [];
    caches.forEach(cacheName => {
      const currentDate = (new Date()).getTime();
      this.cachesData[cacheName] = this.cachesData[cacheName] || {
        date: currentDate,
      };
      const cacheAge = currentDate - this.cachesData[cacheName].date;
      if (cacheAge > this.maxLifetime) {
        this.cachesData[cacheName].date = currentDate;
        outDatedCaches.push(cacheName);
      }
    });
    if (outDatedCaches.length) {
      this.invalidateCaches(outDatedCaches);
      outDatedCaches = [];
    }
    this.saveCachesData();
  }

  public cleanInvalidCaches = (existingCaches: string[]) => {
    Object.keys(this.cachesData)
      .filter(name => !existingCaches.includes(name))
      .forEach(name => delete this.cachesData[name]);
    this.saveCachesData();
  };

  public resetExistingCaches = () => {
    const existingCaches = Object.keys(this.cachesData);
    this.invalidateCaches(existingCaches);
    existingCaches.forEach(cacheName => {
      const currentDate = (new Date()).getTime();
      this.cachesData[cacheName] = {
        date: currentDate,
      };
    });
    this.saveCachesData();
  };
}
