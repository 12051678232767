import Authentication from '@base-auth/Authentication';
import { bestAuth } from '@base-api/bestServiceCallAPI';
import { IToken } from '@base-auth/ServiceAuthentication';
import AbstractAuthenticationModule from './AbstractAuthenticationModule';

export class ServiceAuth extends AbstractAuthenticationModule {
  static ConfirmAuthFlow = (location: Location) => {
    const serviceScope = 'patient:get hospitalization:get';
    const { hash } = window.location;
    /**
     *  we check the raw hash for the scope
     *  we could use auth0.parseHash but that is async
     *  and requires more changes to the architecture
     */
    if (hash.includes('access_token=')) {
      const hashScope = decodeURIComponent(location.hash.split('&')[1]).replace(
        'scope=',
        '',
      );
      return hashScope === serviceScope;
    }
    return false;
  };

  // eslint-disable-next-line class-methods-use-this
  authenticate = async () => {
    let isServiceAuth = false;
    const tokenData = await Authentication.ParseHash(window.location.hash);
    if (tokenData) {
      const { appState } = tokenData;
      if (appState.targetUrl) {
        if (bestAuth.isServiceToken((tokenData as unknown) as IToken)) {
          bestAuth.giveServiceConsent(true);
          isServiceAuth = true;
        }
        Authentication.SaveTokenData(tokenData);
      }
      window.location = appState.targetUrl;
      return isServiceAuth;
    }
    return false;
  };

  logout = () => {
    throw new Error('Method not implemented.');
  };
}
