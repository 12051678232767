import {
  ISchemasState,
  SchemasActionTypes,
  FETCH_SCHEMAS,
} from '@base-store/schemas/types';
import { NodeFactory } from '@base-models/Data/NodeFactory';
import NodeQueryBuilder from '@base-utils/NodeQueryBuilder';
import { ISchema } from '@base-models/Data/types';
import { Schema } from '@base-models/Data/Schema';

const initialState: ISchemasState = {
  schemas: {},
  loading: true,
  loadingError: null,
};

export default (state = initialState, action: SchemasActionTypes): ISchemasState => {
  switch (action.type) {
    case FETCH_SCHEMAS.request: {
      return {
        ...state,
        loadingError: null,
      };
    }

    case FETCH_SCHEMAS.success: {
      // Mapping an array to dictionary
      const schemas = (action.payload as Schema[])
        .reduce((result: Record<string, ISchema>, item: ISchema) => ({
          ...result,
          [item.name]: item,
        }), {});
      NodeQueryBuilder.setSchemas(schemas);
      NodeFactory.schemas = schemas;
      return {
        ...state,
        loading: false,
        loadingError: null,
        schemas,
      };
    }

    case FETCH_SCHEMAS.fail: {
      return {
        ...state,
        loadingError: action.payload as string,
        loading: false,
      };
    }

    default:
      return state;
  }
};
