import handlebars from 'handlebars';

export const limitText = (
  description: string,
  limit: number,
) => {
  if (!description) {
    return;
  }
  if (description.length <= limit) {
    return description;
  }
  const limitedText = description.substring(0, limit);
  return new handlebars.SafeString(`${limitedText}...`);
};
