/* eslint-disable implicit-arrow-linebreak */
const matomo = () => window._paq ?? [];

enum MatomoDimensions {
  hospitalNameVisitDimensions = 1,
  hospitalNameActionDimensions = 2,
}

const setCustomDimension = (num: number, value: string) =>
  matomo().push(['setCustomDimension', num, value]);

const setHospitalName = (name = 'unknown hospital') => {
  setCustomDimension(MatomoDimensions.hospitalNameVisitDimensions, name);
  setCustomDimension(MatomoDimensions.hospitalNameActionDimensions, name);
};

export const tracker = { setHospitalName };
