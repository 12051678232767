import {
  IEpgState,
  EpgActionTypes,
  FETCH_EPG,
  FETCH_CURRENT_CHANNEL_EPG,
} from '@base-store/epg/types';
import {
  TEMPLATE_FETCH_EPG,
  TEMPLATE_FETCH_CURRENT_CHANNEL_EPG,
} from '@base-constants/templates';

const initialState: IEpgState = {
  channelId: '',
  epgData: null,
  currentChannelEpgData: null,
  loading: false,
  error: null,
};

export default (state = initialState, action: EpgActionTypes): IEpgState => {
  switch (action.type) {
    case FETCH_EPG.request:
    case TEMPLATE_FETCH_EPG: {
      return {
        ...state,
        error: null,
        loading: true,
      };
    }

    case FETCH_EPG.success: {
      return {
        ...state,
        loading: false,
        error: null,
        ...action.payload,
      };
    }

    case FETCH_EPG.fail:
    case FETCH_CURRENT_CHANNEL_EPG.fail: {
      const error = action.payload as string;
      return {
        ...state,
        error,
        loading: false,
      };
    }

    case FETCH_CURRENT_CHANNEL_EPG.request:
    case TEMPLATE_FETCH_CURRENT_CHANNEL_EPG: {
      return {
        ...state,
        error: null,
        loading: true,
        currentChannelEpgData: null,
        ...action.payload,
      };
    }

    case FETCH_CURRENT_CHANNEL_EPG.success: {
      return {
        ...state,
        loading: false,
        error: null,
        ...action.payload,
      };
    }

    default:
      return state;
  }
};
