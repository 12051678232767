import { cmsApi } from '@base-api/cmsApi';
import NodeQueryBuilder from '@base-utils/NodeQueryBuilder';
import { store } from '@base-store/index';
import PreviewMode from './PreviewMode';

interface INewsArticle {
  uuid: string;
  fields: {
    time: string;
    shortDescription: string;
    news_article_headline: string;
  };
}
interface INewsArticlesList {
  uuid: string;
  displayName: string;
  fields: {
    readMoreText: string;
  };
  children: {
    elements: Array<INewsArticle>;
  };
}

const NEWS_LIST_LENGTH = 3;

const newsListExtractor = (
  rawNews: INewsArticlesList,
  newsListLength: number,
) => {
  const articles = rawNews.children.elements
    .map(article => ({
      uuid: article.uuid,
      ...article.fields,
    }))
    .sort((a, b) => +new Date(b.time) - +new Date(a.time))
    .slice(0, newsListLength);
  return {
    articles,
    newsListUUID: rawNews.uuid,
    displayName: rawNews.displayName,
    readMoreText: rawNews.fields.readMoreText,
  };
};

const getNewsList = async (newsListUUID: string) => {
  const state = store.getState();
  const {
    projectConfig: { name: projectName },
    language,
  } = state.settings;
  const newsRequest = NodeQueryBuilder.queryNewsList(newsListUUID, language);
  const previewMode = PreviewMode.isEnabled();
  if (projectName && language) {
    const newsResult = await cmsApi.graphQLRequest<INewsArticlesList>(
      projectName,
      newsRequest,
      previewMode,
    );
    return newsListExtractor(
      newsResult.nodes.elements[0],
      NEWS_LIST_LENGTH,
    );
  }
};

export { getNewsList };
