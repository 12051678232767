import rootReducer from '@base-reducers/index';
import {
  createStore,
  applyMiddleware,
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import initSubscriber from '@bewatec-berlin/redux-subscriber';
import persistState from 'redux-localstorage';

import settingsSaga from '@base-sagas/settings';
import navigationSaga from '@base-sagas/navigation';
import schemasSaga from '@base-sagas/schemas';
import patientSaga from '@base-sagas/patient';
import userSaga from '@base-sagas/user';
import contentSaga from '@base-sagas/content';
import epgSaga from '@base-sagas/epg';
import programVerificationSaga from '@base-sagas/programVerification';
import redeemVoucherSaga from '@base-sagas/redeemVoucher';
import { createBrowserHistory } from 'history';
import reduxTrackingMiddleware from '../tracking/initTracking';

export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();

const composeEnchancers = composeWithDevTools({
  trace: true,
  traceLimit: 25,
});

export const store = createStore(rootReducer, composeEnchancers(
  // @ts-ignore
  persistState(['programVerification', 'redeemVoucher']),
  applyMiddleware(sagaMiddleware),
  applyMiddleware(reduxTrackingMiddleware),
));

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export const subscribe = initSubscriber(store);

sagaMiddleware.run(settingsSaga);
sagaMiddleware.run(navigationSaga);
sagaMiddleware.run(schemasSaga);
sagaMiddleware.run(patientSaga);
sagaMiddleware.run(userSaga);
sagaMiddleware.run(contentSaga);
sagaMiddleware.run(epgSaga);
sagaMiddleware.run(programVerificationSaga);
sagaMiddleware.run(redeemVoucherSaga);

// Helpers for the debugging - makes 'store' and 'dispatch' accessible from the browsers console
// if (process.env.NODE_ENV === 'development') {
Object.defineProperty(window, 'store', {
  get: () => store.getState(),
});
Object.defineProperty(window, 'dispatch', {
  get: () => store.dispatch,
});
// }
