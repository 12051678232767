import { hot } from 'react-hot-loader/root';
import React, {
  FC, Suspense,
} from 'react';
import { Router } from 'react-router-dom';
import { Auth0Provider } from '@base-auth/index';
import MaintenancePage from '@base-pages/MaintenancePage';
import { history } from '@base-store/index';
import Routes from '@base-pages/index';


const App: FC = () => (
  <Suspense fallback={null}>
    {process.env.REACT_APP_MAINTENANCE_MODE_IS_ENABLED === 'true' ? (
      <MaintenancePage />
    ) : (
      <Router history={history}>
        <Auth0Provider>
          <Routes />
        </Auth0Provider>
      </Router>
    )}
  </Suspense>
);
export default hot(App);
