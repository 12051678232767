import { combineReducers } from 'redux';
import settingsReducer from './settings';
import navigationReducer from './navigation';
import schemasReducer from './schemas';
import patientReducer from './patient';
import userReducer from './user';
import contentReducer from './content';
import epgReducer from './epg';
import mediaViewReducer from './mediaViewer';
import programVerificationReducer from './programVerification';
import redeemVoucherReducer from './redeemVoucher';

const rootReducer = combineReducers({
  settings: settingsReducer,
  navigation: navigationReducer,
  schemas: schemasReducer,
  patient: patientReducer,
  user: userReducer,
  content: contentReducer,
  epg: epgReducer,
  mediaViewer: mediaViewReducer,
  programVerification: programVerificationReducer,
  redeemVoucher: redeemVoucherReducer,
});

export type AppState = ReturnType<typeof rootReducer>;
export default rootReducer;
