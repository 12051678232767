import React from 'react';
import App from '@base-containers/Page/';
import Profile from '@base-pages/profile';
import Logout from '@base-pages/logout';
import { Route, Switch } from 'react-router-dom';

import NotFound from '@base-components/NotFound-404';
import NoAccess from '@base-components/NoAccess';
import HelpCenterButton from '@base-components/HelpCenterButton';
import { PrivateRoute } from '@base-auth/index';
import RedirectHome from '@base-pages/redirectHome';
import LoginPage from './LogIn';
import DownloadApp from './DownloadApp';
import ConnectionCode from './ConnectionCode';
import EpgPreview from './EpgPreview';

export default () => (
  <React.Fragment>
    <Switch>
      <Route path="/log-in" component={LoginPage} />
      <PrivateRoute path="/logout" component={Logout} />
      <PrivateRoute exact path="/profile" component={Profile} />
      <PrivateRoute path="/node" component={App} />
      <PrivateRoute exact path="/" component={RedirectHome} />
      <PrivateRoute exact path="/home" component={RedirectHome} />
      <PrivateRoute exact path="/code" component={ConnectionCode} />
      <Route path="/downloadapp" component={DownloadApp} />
      <Route path="/epg" component={EpgPreview} />
      <Route path="/no-access" component={NoAccess} />
      <Route path="*" component={NotFound} />
    </Switch>
    <HelpCenterButton />
  </React.Fragment>
);
