import {
  call,
  put,
  takeLatest,
  select,
} from 'redux-saga/effects';

import { cmsApi } from '@base-api/cmsApi';
import { FETCH_CONSENT_PAGE, TOGGLE_PREVIEW_MODE, TogglePreviewModeAction } from '@base-store/content/types';
import NodeQueryBuilder from '@base-utils/NodeQueryBuilder';
import { IRawNode } from '@base-models/Data/types';
import { AppState } from '@base-reducers/index';
import PreviewMode from '@base-utils/PreviewMode';
import { waitForProjectConfig } from './helpers';
import { getNodeContent } from './navigation';

export function* fetchConsent() {
  yield call(waitForProjectConfig);
  try {
    const language = yield select((state: AppState) => state.settings.language);
    const projectName = yield select((state: AppState) => state.settings.projectConfig.name);
    const request = NodeQueryBuilder.queryConsentPage(language);
    const previewMode = PreviewMode.isEnabled();
    const data = yield call(cmsApi.graphQLRequest, projectName, request, previewMode);
    const consentPage: IRawNode = data.nodes.elements[0];

    yield put({
      type: FETCH_CONSENT_PAGE.success,
      payload: {
        ...consentPage.fields,
        uuid: consentPage.uuid,
      },
    });
  } catch (e) {
    yield put({
      type: FETCH_CONSENT_PAGE.fail,
      payload: null,
    });
  }
}

export function* togglePreviewMode(action: TogglePreviewModeAction) {
  const { payload } = action;
  if (!payload) {
    PreviewMode.disable();
  }
  const uuid = yield select((state: AppState) => state.navigation.currentNode?.uuid);
  if (uuid) {
    yield call(getNodeContent, uuid);
  }
}

function* navigationSaga() {
  yield takeLatest(FETCH_CONSENT_PAGE.request, fetchConsent);
  yield takeLatest(TOGGLE_PREVIEW_MODE, togglePreviewMode);
}

export default navigationSaga;
