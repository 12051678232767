import {
  IProgramVerificationState,
  ProgramVerificationActionTypes,
  ISetProgramVerificationAction,
  SET_PROGRAM_VERIFICATION,
  INVALID_PIN_ENTERED,
  VALID_PIN_ENTERED,
  UNLOCK_PIN_VERIFICATION,
  FETCH_PERSONAL_PIN,
} from '@base-store/programVerification/types';

const initialState: IProgramVerificationState = {
  verifiedPrograms: {},
  invalidPINAttempts: 0,
  locked: false,
  lockTimestamp: null,
  personalPin: null,
  loading: false,
};

export default (
  state = initialState,
  action: ProgramVerificationActionTypes,
): IProgramVerificationState => {
  switch (action.type) {
    case SET_PROGRAM_VERIFICATION: {
      const { channelId, programId } = action.payload as ISetProgramVerificationAction['payload'];
      const verifiedPrograms = {
        ...state.verifiedPrograms,
        [channelId]: programId,
      };

      return {
        ...state,
        verifiedPrograms,
      };
    }

    case INVALID_PIN_ENTERED: {
      const invalidPINAttempts = state.invalidPINAttempts + 1;
      // ignore 4 or more consecutive invalid attempts. Normally it should not happen,
      // cause user will be blocked to enter the PIN after 3rd invalid attempt
      if (invalidPINAttempts > 3) {
        return state;
      }

      const locked = invalidPINAttempts === 3;
      const lockTimestamp = locked ? Date.now() : null;
      return {
        ...state,
        invalidPINAttempts,
        locked,
        lockTimestamp,
      };
    }

    case UNLOCK_PIN_VERIFICATION: {
      return {
        ...state,
        locked: false,
        invalidPINAttempts: 0,
        lockTimestamp: null,
      };
    }

    case VALID_PIN_ENTERED: {
      return {
        ...state,
        invalidPINAttempts: 0,
      };
    }

    case FETCH_PERSONAL_PIN.request: {
      return {
        ...state,
        loading: true,
      };
    }

    case FETCH_PERSONAL_PIN.success: {
      return {
        ...state,
        personalPin: action.payload as string,
        loading: false,
      };
    }

    case FETCH_PERSONAL_PIN.fail: {
      return {
        ...state,
        personalPin: null,
        loading: false,
      };
    }

    default:
      return state;
  }
};
