import { WebAuth, AuthOptions } from 'auth0-js';
import { IKISAuthentication } from '@base-models/Data/types';
import { AuthenticationError, InitialisationError } from '@base-models/errors';
import { portalContextClient } from '@base-context/PortalContext';

export default class KISAuthentication implements IKISAuthentication {
  public static AuthenticationError = new AuthenticationError('KIS authentication failed');

  public static InitializationError = new InitialisationError('webAuth for KIS is not initialized');

  private webAuth: WebAuth | null = null;

  private accessToken: string = '';

  public authenticated = false;

  public organization: string | undefined;

  public underOrganization: boolean;

  private config: AuthOptions = {
    domain: '',
    clientID: '',
    audience: '',
  };

  public constructor(config: AuthOptions, organization?: string) {
    this.config = {
      ...config,
      responseType: 'token',
      redirectUri: `${window.location.origin}`,
    };
    this.organization = organization;
    this.underOrganization = !!organization;
  }

  public getAccessToken = () => this.accessToken;

  private fetchToken = async (audience: string, scope: string): Promise<string> => (
    new Promise((resolve, reject) => {
      if (this.webAuth === null) {
        return reject(KISAuthentication.InitializationError);
      }
      const options = { audience, scope };
      if (this.underOrganization) {
        options['organization'] = this.organization;
      }
      this.webAuth.checkSession(
        options,
        (err, res) => {
          if (err) {
            return reject(err);
          }
          return resolve(res.accessToken);
        },
      );
    })
  );

  private _authenticate = async () => {
    this.webAuth = new WebAuth(this.config);
    try {
      this.accessToken = await this.fetchToken(this.config.audience as string, 'patient:get');
      this.authenticated = true;
      return true;
    } catch (e) {
      this.authenticated = false;
      throw KISAuthentication.AuthenticationError;
    }
  };

  public authenticate = async () => {
    if (portalContextClient.context?.isNative
      && portalContextClient.context?.accessToken
    ) {
      this.accessToken = portalContextClient.context.accessToken;
      this.authenticated = true;
      return true;
    }
    return this._authenticate();
  };
}
