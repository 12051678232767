import getDomNode from '@base-utils/getDomNode';

async function appendUrl(className: string, url: string) {
  const iframe = await getDomNode(`.${className}`);
  iframe.setAttribute('src', url);
}

async function appendError(className: string, error: string) {
  const container = await getDomNode(`.${className}-container`);
  const iframe = await getDomNode(`.${className}`);
  const content = document.createElement('span');
  content.innerHTML = error;
  container.replaceChild(content, iframe);
}

function rewriteAuthRedirectionHistory() {
  if (window.location.href.includes('service=')) {
    window.history.pushState({}, '', window.location.href.split('?')[0]);
    window.onpopstate = () => {
      window.onpopstate = null;
      window.history.go(-4);
    };
  }
}

async function hideLoader() {
  const container = await getDomNode('.loader-container');
  container.classList.add('hidden');
}

export {
  appendError,
  appendUrl,
  rewriteAuthRedirectionHistory,
  hideLoader,
};
