import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { getInitialContext } from '@ionic/portals';
import { QueryClientProvider } from 'react-query';
import { store } from '@base-store/index';
import {
  PortalInitialContext,
  PortalProvider,
} from '@base-context/PortalContext';
import App from './App';
import { reactQueryClient } from './reactQueryClient';

import './bootstrap';

const initialContext = getInitialContext<PortalInitialContext>();
const context = initialContext?.value ?? { isNative: false };

ReactDOM.render(
  <Provider store={store}>
    <PortalProvider {...context}>
      <QueryClientProvider client={reactQueryClient}>
        <App />
      </QueryClientProvider>
    </PortalProvider>
  </Provider>,
  document.getElementById('root'),
);
