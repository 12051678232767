import purchaseAPI from '@base-api/purchaseAPI';
import {
  GetPaymentSessionResponseData,
  Locale,
} from '@base-api/purchaseAPI/types';
import { history } from '@base-store/index';
import { displayError } from '@base-utils/Notifications';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAppSelector } from './useAppSelector';
import { useAsync } from './useAsync';
import { useLocalStorage } from './useLocalStorage';

export const useCheckoutSession = () => {
  const { t } = useTranslation();
  const { productId } = useParams<{ productId: string }>();
  const language = useAppSelector(state => state.settings.language);
  const [, setCheckoutSession] = useLocalStorage('checkoutSession');


  const getPaymentSession = useCallback(
    () => purchaseAPI.getPaymentSession({
      productId,
      locale: Locale[language],
    }),
    [productId, language],
  );
  const { data, error } = useAsync<GetPaymentSessionResponseData>(getPaymentSession);

  useEffect(() => {
    if (error) {
      displayError({ message: t('premiumUpgrade.paymentSessionError') });
      history.push('/node/home');
    } else {
      setCheckoutSession(data);
    }
  }, [data, error]);
  return { data, language };
};
