import RequestActionType from '@base-utils/requestAction';

export interface IUserState {
  hasVerifiedCode: boolean
  loading: boolean
  showVerificationScreen: boolean
  error: boolean | null
  askedForValidation: boolean
  loadingError: string | null
  isOnboardingModalEnable: boolean,
}

export const VERIFY_CODE = new RequestActionType('VERIFY_CODE');
export const ONBOARDING_CLOSE = 'ONBOARDING_CLOSE';
export const TOGGLE_SHOW_CODE_VERIFICATION_SCREEN = 'TOGGLE_SHOW_CODE_VERIFICATION_SCREEN';
export const VALIDATE_CODE_IS_VERIFIED_FAIL = 'VALIDATE_CODE_IS_VERIFIED_FAIL';
export const VALIDATE_CODE_IS_VERIFIED_SUCCESS = 'VALIDATE_CODE_IS_VERIFIED_SUCCESS';

export interface IVerifyCodeAction {
  type: typeof VERIFY_CODE.request
  payload?: {
    connectionCode: string;
  }
}

export interface IOnboardingCloseAction {
  type: typeof ONBOARDING_CLOSE
  payload?: {
    connectionCode: string;
  }
}
export interface IToggleCodeScreen {
  type: typeof TOGGLE_SHOW_CODE_VERIFICATION_SCREEN
  payload: {
    shouldShow: boolean
  }
}

export type UserActionTypes =
  IVerifyCodeAction |
  IToggleCodeScreen |
  IOnboardingCloseAction;
