import axios from 'axios';
import { displayError } from '@base-utils/Notifications';
import swManager from '@base-utils/SWManager';
import { IAuthConfig } from '@base-models/Data/types';
import { cmsApiConnection } from '@base-api/cmsApiConnection';
import { backendApiUrl } from '@base-constants/urls';
import AbstractAuthenticationModule from './AbstractAuthenticationModule';

export type TokenAuthenticationResponse = {
  logicalApi_access_token: string;
  expires_in: string
};

export abstract class TokenAuthentication extends AbstractAuthenticationModule {
  static readonly LogicalApiAccessTokenStorageKey =
  'LogicalApiTokenAuthentication_logicalApiAccessToken';

  static readonly LogicalApiAccessTokenExpireInStorageKey =
  'LogicalApiTokenAuthentication_logicalApiAccessToken_expire_in';

  protected storage: WindowLocalStorage['localStorage'];

  private _authenticated: boolean = false;

  protected urlToken: string | null = null;

  private logicalApiAccessToken: string | null = null;

  public get authenticated() {
    return this._authenticated;
  }

  abstract getQueryStringTokenKey(): string;

  abstract readonly fetchType: 'shared-login' | 'preview-login';

  constructor(authConfig: IAuthConfig, storage: Storage) {
    super(authConfig);
    this.storage = storage;
    this.init();
  }

  public init = async () => true;

  public authenticate = async () => {
    try {
      const tokenKey = this.getQueryStringTokenKey();
      const queryParams = new URLSearchParams(window.location.search);
      const tokenId = queryParams.get(tokenKey);
      if (tokenId) {
        this.storage.setItem(tokenKey, tokenId);
      }
      this.urlToken = this.storage.getItem(tokenKey);

      await this.getAccessTokens();
      this._authenticated = true;
    } catch (e) {
      this._authenticated = false;
      this.storage.removeItem(
        TokenAuthentication.LogicalApiAccessTokenStorageKey,
      );
      displayError({ e: (e as Error).message });
    }
    return this._authenticated;
  };

  public getAccessTokens = async () => {
    if (
      !this.logicalApiAccessToken
      || !this.verifyAccessToken(this.logicalApiAccessToken)
    ) {
      await this.fetchNewAccessToken();
    }
    return {
      logicalApiAccessToken: this.logicalApiAccessToken,
    };
  };

  private async fetchNewAccessToken() {
    try {
      const { data } = await axios.post<TokenAuthenticationResponse>(
        `${backendApiUrl}/${this.fetchType}/${this.urlToken}/token`,
      );
      this.logicalApiAccessToken = data.logicalApi_access_token;
      this.storage.setItem(
        TokenAuthentication.LogicalApiAccessTokenStorageKey,
        this.logicalApiAccessToken,
      );
      this.storage.setItem(
        TokenAuthentication.LogicalApiAccessTokenExpireInStorageKey,
        data.expires_in,
      );

      cmsApiConnection.setAuthToken(this.logicalApiAccessToken);
      return { logicalApiToken: data.logicalApi_access_token };
    } catch (e) {
      displayError({ e: e.message });
      this.logout();
    }
  }

  public logout() {
    swManager.resetCache();
    if (this.storage) {
      this.storage.clear();
      this.urlToken = '';
      this.logicalApiAccessToken = null;
      setTimeout(() => {
        window.location.href = window.location.origin;
      }, 1000);
    }
  }
}
