import { IProjectTemplates, ITemplate } from '@base-models/Data/types';
import { compileTemplates } from '@base-utils/TemplateProcessor/processor';

export default class ProjectTemplates implements IProjectTemplates {
  globalStyle = '';

  templates: IProjectTemplates['templates'] = {};

  constructor(config: IProjectTemplates) {
    if (config.templates) {
      this.templates = compileTemplates(config.templates as Record<string, ITemplate>);
    }
    if (config.globalStyle) {
      this.globalStyle = config.globalStyle;
    }
  }
}
