import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AUTHENTICATION } from '@base-store/settings/types';
import Authentication from '@base-auth/Authentication';
import { displayError } from '@base-utils/Notifications';
import AuthRouter from '@base-auth/2.0/AuthRouter';
import PreviewMode from '@base-utils/PreviewMode';
import PreviewModeAuthentication from './PreviewModeAuthentication';
import AbstractAuthenticationModule from './AbstractAuthenticationModule';

interface IUseAuth0 {
  acceptConsent?: () => void;
  isAuthenticated: boolean;
  isConsentGiven: boolean;
  loading: boolean;
  currentAuthModule?: AbstractAuthenticationModule;
}

const defaultContext: IUseAuth0 = {
  isAuthenticated: false,
  isConsentGiven: false,
  loading: false,
};
const publicRoutes = ['/downloadapp', '/no-access'];


export const Auth0Context = React.createContext(defaultContext);
export const useAuth0 = (): IUseAuth0 => React.useContext(Auth0Context);
export const authModule = AuthRouter.verifyAuthFlow(window.location);

const AuthRouterProvider = ({ children, dispatch }) => {
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const [isConsentGiven, setIsConsentGiven] = React.useState(PreviewMode.isEnabled() === true);
  const [currentAuthModule, setCurrentAuthModule] = React.useState<AbstractAuthenticationModule>();
  const [loading, setLoading] = React.useState(true);
  const history = useHistory();
  const { location } = window;
  const { pathname } = location;

  React.useEffect(() => {
    const runAuthMethodCheck = async () => {
      const serviceAuthModule = AuthRouter.verifyServiceAuthFlow(
        location,
      );
      if (serviceAuthModule) {
        await serviceAuthModule.authenticate();
      }

      if (authModule) {
        setCurrentAuthModule(authModule);
        await authModule.init();

        if (window.location.pathname === '/logout') {
          authModule.logout();
          return;
        }

        const authenticated = await authModule.authenticate().catch(err => {
          if (err.message === 'access_denied') {
            history.push('/no-access');
          }
          // eslint-disable-next-line no-console
          console.error('ERROR:', err);
        });
        if (authenticated) {
          dispatch({
            type: AUTHENTICATION.success,
            payload: { authenticated },
          });
          setIsAuthenticated(authenticated);
        }
        if (Authentication.ConsentGiven) {
          setIsConsentGiven(true);
        }
        setLoading(false);
        if (
          !!window.localStorage.getItem(PreviewModeAuthentication.StorageKey)
        ) {
          setIsConsentGiven(true);
        }
      } else {
        // TODO: Define later what to show in case of no auth method found
        displayError({ message: 'No Auth Method found' });
      }
    };
    if (!publicRoutes.includes(pathname)) {
      runAuthMethodCheck();
    }
  }, []);

  React.useEffect(() => {
    if (!publicRoutes.includes(pathname)) {
      const queryParams = new URLSearchParams(window.location.search);
      const shouldGiveConsent = isAuthenticated && !isConsentGiven;
      const isSharedLogin = queryParams.has('b-token');
      const isIndividualLogin = queryParams.has('code');
      const isUniversalLogin = isSharedLogin || isIndividualLogin;
      const isHomePage = pathname === '/';

      if (
        !loading
        && ((shouldGiveConsent && isHomePage && isUniversalLogin)
          || (shouldGiveConsent && isHomePage))
      ) {
        history.push('/log-in');
      }
    }
  }, [isAuthenticated, loading, isConsentGiven]);

  const value = React.useMemo(
    () => ({
      isAuthenticated,
      isConsentGiven,
      loading,
      acceptConsent: () => {
        Authentication.GiveConsent(true);
        setIsConsentGiven(true);
      },
      currentAuthModule,
    } as IUseAuth0),
    [isAuthenticated, isConsentGiven, loading],
  );

  return (
    <Auth0Context.Provider value={value}>{children}</Auth0Context.Provider>
  );
};

export default connect(null)(AuthRouterProvider);
