import {
  TEMPLATE_NAVIGATE_TO_UUID,
  TEMPLATE_CHANGE_FONT_SIZE,
  TEMPLATE_FETCH_EPG,
  TEMPLATE_FETCH_CURRENT_CHANNEL_EPG,
} from '@base-constants/templates';
import getRandomDelay from '@base-utils/getRandomDelay';
import FormStateManager from '@base-utils/FormStateManager';
import getDomNode from '@base-utils/getDomNode';
import { mainPageUUID } from '@base-constants/urls';
import { store } from '@base-store/index';
import {
  hidePinVerification,
  showSpinner, hideSpinner,
  showErrorMessage,
  hideErrorMessage,
  showAgeErrorMessage,
  hideAgeErrorMessage,
  hideRestrictionFormAndPinContainer,
  disableConfirmationButton,
  cleanInputs,
  storeVerifiedProgramData,
  invalidPINAttempt,
  validPINAttempt,
  togglePinVerificationLock,
} from '@base-utils/TemplateProcessor/Helpers/verifySkyPin';
import { showMediaPlayer, startMediaPlayback } from '@base-utils/MediaPlayer';
import { IVideoOptions } from '@base-utils/TemplateProcessor/Helpers/mediaPlayer';
import { PinValidity } from '@base-models/Data/types';
import pinApi from '@base-api/pinAPI';
import purchaseAPI from '@base-api/purchaseAPI';
import BackendTokenAuthentication from '@base-auth/2.0/BackendTokenAuthentication';
import { SchemaNames } from '@base-constants/schemas';
import { portalContextClient } from '@base-context/PortalContext';
import { openInAppBrowser } from '@base-utils/PortalPublishEvent';
import {
  upgradeToPremium, redeemVoucher, hideRedeemVoucher,
} from './upgradeToPremium';
import { changeChannel, IChangeChannelOptions } from './changeChannel';

type cls = { new(type: string, payload?: any, meta?: any): any };

export default (Event: cls) => (emit: (n: cls) => void) => ({
  navigateToId: (id: string, element?: string, source?: string) => emit(
    new Event(
      TEMPLATE_NAVIGATE_TO_UUID,
      id,
      {
        actionType: element,
        source,
        target: id,
      },
    ),
  ),

  logout: () => emit(
    new Event('Logout'),
  ),

  setFontSize: (fontSize: string) => emit(
    new Event(TEMPLATE_CHANGE_FONT_SIZE, { fontSize }),
  ),

  displaySearchResults: (inputId: string, itemsListId: string) => {
    const inputNode = document.getElementById(inputId) as HTMLInputElement | null;
    const itemListNode = document.getElementById(itemsListId) as HTMLMediaElement | null;
    if (inputNode && itemListNode) {
      const items = Array.from(itemListNode.childNodes) as HTMLElement[];
      inputNode.addEventListener('keyup', () => {
        const val = inputNode.value.trim().toLowerCase();
        items.forEach(item => {
          const itemText = item.dataset && item.dataset['value'];
          if (itemText) return item.classList.toggle('hidden', !itemText.toLowerCase().includes(val));
        });
      });
    }
  },

  delayAnimation: (classname: string) => {
    const appear = () => `animation-delay: ${getRandomDelay()}ms`;
    const navItems = document.getElementsByClassName(classname);
    [...navItems].forEach(navItem => navItem.setAttribute('style', appear()));
  },

  persistFormState: (formId: string) => {
    (async () => {
      const form = await getDomNode(`#${formId}`) as HTMLFormElement;
      const uuid = form.getAttribute('data-uuid') as string;
      FormStateManager.restoreFormState(uuid, formId);
      form.addEventListener('change', () => FormStateManager.updateFormState(uuid, formId, form));
    })();
  },

  fetchEpgData: () => emit(
    new Event(TEMPLATE_FETCH_EPG),
  ),

  fetchCurrentEpgData: (channelId: string) => emit(
    new Event(TEMPLATE_FETCH_CURRENT_CHANNEL_EPG, { channelId }),
  ),

  switchChannel: (options: IChangeChannelOptions) => {
    changeChannel(options);
  },

  upgradePackage: upgradeToPremium,

  verifyPin: async (pinValue: string) => {
    showSpinner();
    cleanInputs();

    const valid = await pinApi.verifyPin(
      pinValue,
      store.getState().mediaViewer.currentAgeRestriction,
      store.getState().settings.hospitalUuid,
    );

    if (valid === PinValidity.NOT_VALID) {
      // invalid pin
      hideSpinner();
      showErrorMessage();
      disableConfirmationButton();
      invalidPINAttempt();
      togglePinVerificationLock();
      return;
    }

    if (valid === PinValidity.NOT_VALID_AGE) {
      hideRestrictionFormAndPinContainer();
      showAgeErrorMessage();
      invalidPINAttempt();
      togglePinVerificationLock();
      return;
    }

    if (valid === PinValidity.VALID) {
      // valid pin
      hidePinVerification();
      hideSpinner();
      hideErrorMessage();
      hideAgeErrorMessage();
      disableConfirmationButton();
      const {
        src,
        autoplay,
        type,
      } = store.getState().mediaViewer.currentlyPlayingVideo as IVideoOptions;
      startMediaPlayback({
        src, autoplay, type, schema: SchemaNames.MediaVideo,
      });
      showMediaPlayer();
      storeVerifiedProgramData();
      validPINAttempt();
    }
  },

  redeemVoucher: async (voucherCode: string) => redeemVoucher(voucherCode),

  togglePaymentOptions: async () => {
    const hospitalId = store.getState().settings.hospitalUuid;

    const config = await purchaseAPI.getPaymentConfig({ hospitalId });

    const upgradeOptions = document.querySelector<HTMLElement>('#upgrade-options');

    if (upgradeOptions) {
      upgradeOptions.classList.remove('hide');
    }

    if (config.redeemVoucher === false) {
      hideRedeemVoucher();
    }
  },

  redirectSharedLoginHome: () => {
    // Redirects to home if the user is using a shared login.
    if (BackendTokenAuthentication.isSharedLogin()) {
      window.location.href = mainPageUUID;
    }
  },

  openUrl: (url: string) => {
    if (portalContextClient.isNative) {
      return openInAppBrowser(url);
    }
    window.open(url, '_blank');
  },
});
