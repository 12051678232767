import getDomNode from '@base-utils/getDomNode';
import { PatientDataPathMap, PatientSimplifiedLoginPathMap } from '@base-constants/patientDataPathMap';
import { displayError } from '@base-utils/Notifications';
import { containsPlaceholder, replacePlaceholders } from './nodeValuePlaceholderReplacer';
import { resolveSimplifiedLoginPatientName, resolvePatientData } from './dataResolvers';

let uniqId = 0;

export const resolveValue = async (nodeId: string) => {
  try {
    const node: HTMLSpanElement = await getDomNode(`#${nodeId}`);
    node.classList.remove('blank');
    node.parentElement!.classList.add('resolving');
    const { field } = node.dataset;
    if (field) {
      try {
        node.innerText = await (async () => {
          if (PatientDataPathMap[field]) {
            return resolvePatientData(PatientDataPathMap[field]);
          }
          if (PatientSimplifiedLoginPathMap[field]) {
            return resolveSimplifiedLoginPatientName(PatientSimplifiedLoginPathMap[field]);
          }
          return resolvePatientData(PatientDataPathMap.NOT_FOUND);
        })();
      } catch (e) {
        console.log(e); // eslint-disable-line
        node.parentElement!.style.display = 'none';
      }
    }
    node.parentElement!.classList.remove('resolving');
    node.parentElement!.classList.add('resolved');
  } catch (e) {
    displayError({ e: `Node not found - ${nodeId}` });
  }
};

export const resolvePlaceholder = (value: string) => {
  if (containsPlaceholder(value)) {
    const newValue = replacePlaceholders(value, (dataField: string) => {
      const span = document.createElement('span');
      span.innerText = '...';
      span.classList.add('placeholderData');
      span.classList.add('blank');
      span.dataset['field'] = dataField;
      span.id = `placeholderValue_${uniqId++}`;
      resolveValue(span.id);
      return span.outerHTML;
    });

    return newValue;
  }
  return value;
};
