import { formatEpgData } from '@base-utils/EpgFormater';
import getDomNode from '@base-utils/getDomNode';
import { markResolved, markResolving } from '@base-utils/TemplateProcessor/Helpers/templatesDomUtils';
import { getRemainingTime } from '@base-utils/formatDate';
import { EpgData, IEpgChannelData } from '@base-models/Data/types';
import { ICurrentChannelOptions } from './TemplateProcessor/Helpers/loadEpg';

export interface IRenderCurrentChannelEpgOptions extends ICurrentChannelOptions {
  currentChannelEpgData?: IEpgChannelData
}

export interface RenderEpgDataOptions {
  epgData?: EpgData
  channelId: string
  lang: string
}

export const getChannelIds = () => {
  const channels = Array.from(document.getElementsByClassName('channel-data'));
  const channelIds = channels.map(channel => channel.getAttribute('data-channel-id'));
  return channelIds;
};

export const getChannelId = () => {
  const channel = document.querySelector('.current-channel-data');
  if (channel) {
    const channelId = channel.getAttribute('data-current-channel-id');
    return channelId;
  }
};

export const getShortestShow = (epgData: EpgData) => epgData.map(channel => {
  if (channel.currentProgram?.end) {
    return getRemainingTime(channel.currentProgram?.end);
  }
  return Number.MAX_SAFE_INTEGER;
}).sort((a, b) => a - b)[0];

export const deleteCurrentChannelData = async (channelId?: string) => {
  const currentChannelInfoNode = await getDomNode('.current-channel-data');
  currentChannelInfoNode.innerHTML = '';
  if (!channelId) {
    markResolved(currentChannelInfoNode);
  }
};

export const renderEpgData = async ({
  epgData,
  channelId,
  lang,
}: RenderEpgDataOptions) => {
  const channelInfoNode = await getDomNode(`[data-channel-id="${channelId}"]`);
  markResolving(channelInfoNode);
  if (channelInfoNode) {
    if (epgData) {
      const {
        programStart,
        programTitle,
        programAgeRestrictions,
      } = formatEpgData(epgData, channelId, lang);
      channelInfoNode.innerText = `${programStart} ${programTitle} ${programAgeRestrictions}`;
      markResolved(channelInfoNode);
    }
  }
};

export const renderCurrentChannelEpgData = async ({
  currentChannelEpgData,
  lang,
  descriptionTranslation,
  nextTranslation,
}: IRenderCurrentChannelEpgOptions) => {
  const currentChannelInfoNode = await getDomNode('.current-channel-data');
  currentChannelInfoNode.innerHTML = '';
  markResolving(currentChannelInfoNode);
  if (currentChannelEpgData) {
    const {
      programStart,
      nextProgramStart,
      programTitle,
      nextProgramTitle,
      programAgeRestrictions,
      nextProgramAgeRestrictions,
      programDescription,
    } = formatEpgData([currentChannelEpgData], currentChannelEpgData.channelId, lang);
    currentChannelInfoNode.setAttribute('data-current-channel-id', currentChannelEpgData.channelId);
    currentChannelInfoNode.innerHTML = `
      <p class="current-channel-currentProgram">${programStart} ${programTitle} ${programAgeRestrictions}</p>
      <p class="current-channel-description"><b>${programDescription ? descriptionTranslation || 'Description:' : ''} </b>${programDescription}</p>
      <p class="current-channel-nextProgram"><b>${nextProgramTitle ? nextTranslation || 'Next up:' : ''} </b>${nextProgramStart} ${nextProgramTitle} ${nextProgramAgeRestrictions}</p>
    `;
    markResolved(currentChannelInfoNode);
  }
};
