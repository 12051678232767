import handlebars from 'handlebars';
import {
  INode,
  IImage,
  IPDF,
  PermissionType,
  RoleType,
  IRawImage,
  IRawNode,
} from '@base-models/Data/types';
import { formatDate, formatDateAndTime, getRemainingDaysHoursMins } from '@base-utils/formatDate';
import { store } from '@base-store/index';
import { setPaymentPageNodeId } from '@base-actions/redeemVoucher';
import purchaseAPI from '@base-api/purchaseAPI';
import NodeQueryBuilder from '@base-utils/NodeQueryBuilder';
import { cmsApi } from '@base-api/cmsApi';
import { showAppDialog } from '@base-actions/settings';
import { StringMap, TOptions } from 'i18next';
import { interpolateDuration } from './interpolateText';
import renderPdf from './renderPdf';
import resolveIframe from './resolveIframe';
import { loadNews } from './loadNews';
import { loadImage, getImageUrl } from './loadImage';
import { ResolveIframeParams } from './IFrameResolver/types';
import { renderCheckbox } from './checkbox';
import {
  renderVideoPlayer,
  renderYoutubePlayer,
  renderAudioPlayer,
  renderMediaPlayer,
  IMediaOptions,
  IVideoOptions,
  IAudioOptions,
  IPlayerOptions,
} from './mediaPlayer';
import { ICurrentChannelOptions, loadCurrentChannelEpgData, loadEpgData } from './loadEpg';
import { renderNextButton, renderToggleButton } from './renderButtons';
import { openUrl, openSharemagazines, openCommunicationHelp } from './redirections';
import { limitText } from './limitText';
import i18n from '../../../i18n/init';
import { eachSorted, eachSortedByTime } from './sorters';

interface DynamicIframeHelperParams {
  hash: ResolveIframeParams
}

export function registerHelpers() {
  handlebars.registerHelper('each_sorted', (array: INode[], options) => eachSorted(array, options));

  handlebars.registerHelper('each_sorted_by_time', (array: INode[], options) => eachSortedByTime(array, options));

  handlebars.registerHelper('open_url', (url: string, parentUuid?: string, newTab?: boolean) => {
    openUrl(url, parentUuid, newTab);
  });

  handlebars.registerHelper('open_sharemagazines', (parentUuid?: string, newTab?: boolean) => {
    openSharemagazines(parentUuid, newTab);
  });

  handlebars.registerHelper('open_communication_help', (parentUuid: string, url: string) => {
    openCommunicationHelp(parentUuid, url);
  });

  handlebars.registerHelper('load_image', (
    uuid: string,
    image: IImage,
    className: string,
    tag?: string,
    width?: number,
    supportRetina?: boolean,
  ) => loadImage(uuid, image, className, tag, width, supportRetina));

  handlebars.registerHelper('get_image_url', (image: IImage, fixedWidth?: number, supportRetina: boolean = true) => getImageUrl(image, fixedWidth, supportRetina));

  handlebars.registerHelper('load_news', (uuid: string, language: string) => {
    loadNews(uuid, language);
  });

  handlebars.registerHelper(
    'load_pdf',
    (uuid: string, pdf: IPDF, className: string) => new handlebars.SafeString(
      renderPdf(pdf.url, uuid, className),
    ),
  );

  handlebars.registerHelper('video_player', ({
    hash: videoPlayerOptions,
  }) => renderVideoPlayer(videoPlayerOptions as IVideoOptions));

  handlebars.registerHelper('youtube_player', ({
    hash: youtubePlayerOptions,
  }) => renderYoutubePlayer(youtubePlayerOptions as IMediaOptions));

  handlebars.registerHelper('audio_player', ({
    hash: audioPlayerOptions,
  }) => renderAudioPlayer(audioPlayerOptions as IAudioOptions));

  handlebars.registerHelper('media_player', ({
    hash: mediaPlayerOptions,
  }) => renderMediaPlayer(mediaPlayerOptions as IPlayerOptions));

  handlebars.registerHelper('load_epg_data', (lang: string) => loadEpgData(lang));

  handlebars.registerHelper('load_current_epg_data', ({ hash: currentChannelOptions }) => (
    loadCurrentChannelEpgData(currentChannelOptions as ICurrentChannelOptions)
  ));

  handlebars.registerHelper('next_icon', () => renderNextButton());

  handlebars.registerHelper('toggle_button', (className: string) => renderToggleButton(className));

  handlebars.registerHelper('checkbox', (
    content: string,
    className: string,
  ) => renderCheckbox(content, className));

  handlebars.registerHelper('sanitize_number', (number: string) => {
    if (!number) return;
    const sanitizeRegex = new RegExp(/[^\d+]/g);
    return number.replace(sanitizeRegex, '');
  });

  handlebars.registerHelper('limit_text', (description: string, limit: number) => limitText(description, limit));

  handlebars.registerHelper('format_date', (
    date: string,
    locale: string,
  ) => formatDate(date, locale));

  handlebars.registerHelper('format_date_and_time', (
    date: string,
    locale: string,
  ) => formatDateAndTime(date, locale));


  handlebars.registerHelper('dynamic_iframe', (options: DynamicIframeHelperParams) => {
    if (options.hash) {
      return resolveIframe(options.hash);
    }
  });

  handlebars.registerHelper('interpolate_upgrade_duration', (
    text: string,
    language: string,
    radioSelector: string,
    durationSelector: string,
  ) => interpolateDuration(text, language, radioSelector, durationSelector));

  handlebars.registerHelper('translate', (key: string | string[], options?: { hash: TOptions<StringMap> } | undefined) => i18n.t(key, options?.hash));

  handlebars.registerHelper('getPaymentConsent',
    () => new handlebars.SafeString(i18n.t('premiumUpgrade.consent', {
      tcUrl: store.getState().settings.projectConfig.termsAndConditionsUrl,
      rcpUrl: store.getState().settings.projectConfig.termsAndConditionsUrl,
    })));

  handlebars.registerHelper('set_success_page_node_id', (paymentSuccessPageNodeId: string) => {
    store.dispatch(setPaymentPageNodeId({ paymentSuccessPageNodeId }));
  });

  handlebars.registerHelper('set_fail_page_node_id', (paymentFailPageNodeId: string) => {
    store.dispatch(setPaymentPageNodeId({ paymentFailPageNodeId }));
  });

  handlebars.registerHelper('set_cancel_page_node_id', (paymentCancelPageNodeId: string) => {
    store.dispatch(setPaymentPageNodeId({ paymentCancelPageNodeId }));
  });

  handlebars.registerHelper('check_font_size_setting', (value: string) => store.getState().settings.fontSize === value);

  function getPremiumExpiryTime() {
    const remaining = getRemainingDaysHoursMins(
      store.getState().settings.activePremiumUpgrade?.endDate,
    );
    if (!remaining) {
      return i18n.t('myPremiumPage.lessThanMinute');
    }
    const dayText = (remaining.days === 1 ? i18n.t('myPremiumPage.oneDay') : i18n.t('myPremiumPage.days', { days: remaining.days })).concat(' ');
    const hoursText = (remaining.hours === 1 ? i18n.t('myPremiumPage.oneHour') : i18n.t('myPremiumPage.hours', { hours: remaining.hours })).concat(' ');
    const minutesText = remaining.mins === 1 ? i18n.t('myPremiumPage.oneMinute') : i18n.t('myPremiumPage.minutes', { mins: remaining.mins });
    const totalRemaining = `${remaining.days ? dayText : ''}${remaining.days || remaining.hours ? hoursText : ''}${remaining.days || remaining.hours || remaining.mins ? minutesText : i18n.t('myPremiumPage.lessThanMinute')}`;
    return totalRemaining;
  }

  handlebars.registerHelper('get_premium_remaining_time', getPremiumExpiryTime);

  handlebars.registerHelper('has_basic_permission', () => {
    const { permissions, roles } = store.getState().patient;
    return permissions?.includes(PermissionType.Basic) || roles?.includes(RoleType.Basic);
  });

  handlebars.registerHelper('are_equal', (param1, param2) => param1 === param2);

  handlebars.registerHelper('is_vertical', (image: IRawImage) => image.height >= image.width);

  handlebars.registerHelper('is_active_premium', () => {
    purchaseAPI.getActivePremiumUpgrade().then(({ getActivePremiumUpgrade }) => {
      if (!getActivePremiumUpgrade.endDate) {
        const upgradeContainer = document.querySelector('.upgrade-container');
        const loaderContainer = document.querySelector('.loader-container');
        if (upgradeContainer) {
          upgradeContainer.classList.remove('hide');
        }
        if (loaderContainer) {
          loaderContainer.remove();
        }
      } else {
        store.dispatch(showAppDialog(`${i18n.t('myPremiumPage.premiumExists')}`, `${i18n.t('myPremiumPage.expiresIn')} ${getPremiumExpiryTime()}`));
        const state = store.getState();
        const { language } = state.settings;
        const query = NodeQueryBuilder.queryMyPremiumEntertainmentPage(language);
        const { name } = state.settings.projectConfig;
        cmsApi.graphQLRequest(name as string, query, false).then(data => {
          const myPremiumPage: IRawNode = (data as any).nodes.elements[0];
          window.TemplateEvents.navigateToId(myPremiumPage.uuid);
        });
      }
    });
  });
}
