import {
  IUserState,
  VERIFY_CODE,
  UserActionTypes,
  TOGGLE_SHOW_CODE_VERIFICATION_SCREEN,
  VALIDATE_CODE_IS_VERIFIED_SUCCESS,
  VALIDATE_CODE_IS_VERIFIED_FAIL,
  ONBOARDING_CLOSE,
} from '@base-store/user/types';
import { FETCH_PATIENT_ME } from '@base-store/patient/types';
import { IPatient } from '@base-models/Data/types';
import { TEMPLATE_NAVIGATE_TO_UUID } from '@base-constants/templates';
import { FETCH_NODE_CONTENT } from '@base-store/navigation/types';
import { isOnboardingModalEnable } from '@base-utils/browserUtils';


const initialState: IUserState = {
  showVerificationScreen: false,
  hasVerifiedCode: false,
  loading: false,
  error: null,
  askedForValidation: false,
  loadingError: null,
  isOnboardingModalEnable: isOnboardingModalEnable(),
};

export default (state = initialState, action: UserActionTypes): IUserState => {
  switch (action.type) {
    case VERIFY_CODE.request:
      return { ...state, loading: true, error: null };
    case VERIFY_CODE.success:
      return {
        ...state,
        loading: false,
        hasVerifiedCode: true,
        error: null,
        showVerificationScreen: false,
        askedForValidation: false,
      };
    case VERIFY_CODE.fail:
      return {
        ...state,
        loading: false,
        hasVerifiedCode: false,
        error: true,
      };
    case TOGGLE_SHOW_CODE_VERIFICATION_SCREEN:
      return {
        ...state,
        // @ts-ignore
        showVerificationScreen: action.payload.shouldShow,
      };
    case VALIDATE_CODE_IS_VERIFIED_SUCCESS:
      return {
        ...state,
        showVerificationScreen: false,
        hasVerifiedCode: true,
        loading: false,
        askedForValidation: false,
      };
    case VALIDATE_CODE_IS_VERIFIED_FAIL:
      return {
        ...state,
        showVerificationScreen: true,
        hasVerifiedCode: false,
        loading: false,
        askedForValidation: true,
      };
    case FETCH_PATIENT_ME.success: {
      return {
        ...state,
        loading: false,
        // @ts-ignore
        hasVerifiedCode: (action.payload.data as IPatient).kisConnectionVerified,
      };
    }
    case TEMPLATE_NAVIGATE_TO_UUID: {
      return {
        ...state,
        showVerificationScreen: false,
      };
    }
    case FETCH_NODE_CONTENT.success: {
      return {
        ...state,
        askedForValidation: false,
      };
    }
    case ONBOARDING_CLOSE: {
      return {
        ...state,
        isOnboardingModalEnable: false,
      };
    }

    default:
      return state;
  }
};
