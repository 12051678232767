import React, { FC } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AppState } from '@base-reducers/index';
import { displayError, displayInfo } from '@base-utils/Notifications';
import { Notifications } from '@base-constants/notifications';
import ConnectionCode from '@base-components/ConnectionCode';
import Spinner from '@base-components/Spinner';
import RedirectHome from '@base-pages/redirectHome';
import BackendTokenAuthentication from '@base-auth/2.0/BackendTokenAuthentication';


interface ConnectionCodePageProps {
  kisConnectionVerified?: boolean | null;
  loading: boolean;
  kisEnabled: boolean;
  configLoading: boolean;
}

const TIME_TO_REDIRECT_ON_NOTIFICATION = 2000;

const ConnectionCodePage: FC<ConnectionCodePageProps> = ({
  kisConnectionVerified,
  loading,
  kisEnabled,
  configLoading,
}) => {
  const history = useHistory();
  let timeout;
  const verifyingKisState = loading || kisConnectionVerified || !kisEnabled || configLoading;
  function redirectBack() {
    timeout = setTimeout(() => {
      history.goBack();
    }, TIME_TO_REDIRECT_ON_NOTIFICATION);
  }

  React.useEffect(() => {
    if (kisConnectionVerified) {
      displayInfo({ message: Notifications.codeIsVerified });
      redirectBack();
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [kisConnectionVerified]);

  React.useEffect(() => {
    if (!configLoading && !kisEnabled) {
      displayError({ message: Notifications.KISDataNotEnabled });
      redirectBack();
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [kisEnabled, configLoading]);

  if (!loading && BackendTokenAuthentication.isSharedLogin()) {
    return <RedirectHome />;
  }

  if (verifyingKisState) {
    return <Spinner fullPage />;
  }

  return (
    <ConnectionCode onAddLaterClick={redirectBack} />
  );
};

const mapStateToProps = (state: AppState) => ({
  configLoading: state.settings.configLoading,
  kisEnabled: state.settings.KIS.enabled,
  kisConnectionVerified: state.patient.kisConnectionVerified,
  loading: state.patient.loading,
});

export default connect(mapStateToProps)(ConnectionCodePage);
