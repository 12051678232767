export class DeviceTypeDetector {
  // to detect if it is desktop device
  public static isTouchDevice(): boolean {
    let hasTouchScreen = false;
    if ('maxTouchPoints' in navigator) {
      hasTouchScreen = navigator.maxTouchPoints > 0;
    } else {
      const mQ = window.matchMedia && window.matchMedia('(pointer:coarse)');
      if (mQ && mQ.media === '(pointer:coarse)') {
        hasTouchScreen = !!mQ.matches;
      } else if ('orientation' in window) {
        hasTouchScreen = true; // deprecated, but good fallback
      } else {
        // Only as a last resort, fall back to user agent sniffing
        const UA = window.navigator.userAgent;
        hasTouchScreen = /\b(iPhone)\b/i.test(UA)
          || /\b(Android|iPad)\b/i.test(UA);
      }
    }
    return hasTouchScreen;
  }

  public static ios(): boolean {
    const isIOS = /iPad|iPhone/i.test(window.navigator.userAgent);
    const isMac = /Mac OS X/i.test(window.navigator.userAgent);
    const isIpad = isMac && DeviceTypeDetector.isTouchDevice();

    return (isIOS || isIpad);
  }

  public static android(): boolean {
    return /Android/i.test(window.navigator.userAgent);
  }

  public static isBewatecTablet(): boolean {
    const { userAgent } = window.navigator;
    const bewatecDeviceUserAgents = [
      'Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KTHML, like Gecko) Chrome/72.0.3626.121 Safari/537.36',
      'Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/66.0.3359.0 Safari/537.36',
      'Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KTHML, like Gecko) Chrome/72.0.3626.121 Safari/537.36',
      'Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/98.0.4758.101 Safari/537.36',
    ];
    // TODO: once firedragon is available, add it to this check
    return bewatecDeviceUserAgents.indexOf(userAgent) !== -1 || /\b(MediPaD|btab)\b/i.test(userAgent);
  }
}
