/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/button-has-type */
import * as React from 'react';
import { Dialog, DialogContent } from '@reach/dialog';
import { VisuallyHidden } from '@reach/visually-hidden';
import '@reach/dialog/styles.css';
import './style.scss';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '@base-reducers/index';
import { useTranslation } from 'react-i18next';
import { DeviceTypeDetector } from '@base-utils/deviceTypeDetector';

const Onboarding = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const close = () => dispatch({ type: 'ONBOARDING_CLOSE' });
  const loadingRef = React.useRef<HTMLDivElement>(null);
  const isHelpCenterEnabled = useSelector(
    (state: AppState) => state.settings.projectConfig.isHelpCenterEnabled,
  );

  const isOnboardingModalEnable = useSelector(
    (state: AppState) => state.user.isOnboardingModalEnable,
  );

  if (!isHelpCenterEnabled
    || !isOnboardingModalEnable
    || DeviceTypeDetector.isBewatecTablet()
  ) {
    return null;
  }

  const onProgress = (e: React.SyntheticEvent<HTMLVideoElement>) => {
    e.currentTarget.style.opacity = '1';
    if (loadingRef.current) {
      loadingRef.current.style.display = 'none';
    }
  };

  return (
    <Dialog
      className="dialog-container"
      allowPinchZoom
      isOpen={isOnboardingModalEnable}
      data-test-id="onboarding-dialog"
    >
      <button
        className="close-button"
        data-test-id="onboarding-close"
        onClick={close}
      >
        <VisuallyHidden>Close</VisuallyHidden>
        <span aria-hidden>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="m6 6 12 12M18 6 6 18"
              stroke="#042949"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
      </button>
      <DialogContent className="dialog-content">
        <h1 className="dialog-title">{t('onboardingModal.dialog.title')}</h1>
        <p className="dialog-description">
          <span className="title-text">
            {t('onboardingModal.dialog.subtitle')}
          </span>
          {t('onboardingModal.dialog.description')}
        </p>
        <div className="video-container">
          <div className="video-loading" ref={loadingRef}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100"
              height="100"
              viewBox="0 0 100 100"
              fill="none"
            >
              <rect
                x="20.833"
                y="45.833"
                width="41.667"
                height="25"
                rx="6"
                fill="#90ACC9"
              />
              <path
                d="M78.167 48.072v20.523c0 1.696-1.978 2.622-3.28 1.536l-6.5-5.416a2 2 0 0 1-.72-1.537v-9.69a2 2 0 0 1 .72-1.536l-.64-.768.64.768 6.5-5.417c1.302-1.085 3.28-.159 3.28 1.537z"
                fill="#90ACC9"
                stroke="#90ACC9"
                strokeWidth="2"
              />
              <circle cx="29.166" cy="33.333" fill="#90ACC9" r="8.333" />
              <circle cx="54.166" cy="33.333" r="8.333" fill="#90ACC9" />
            </svg>
            <p>Media loading...</p>
          </div>
          <video
            className="video-element"
            controls
            controlsList="nodownload"
            src={t('onboardingModal.videoUrl')}
            width="100%"
            onProgress={onProgress}
          >
            Your browser does not support the video tag.
          </video>
        </div>
      </DialogContent>
    </Dialog>
  );
};
export default Onboarding;
