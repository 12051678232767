import { NodeFactory } from '@base-models/Data/NodeFactory';
import { Notifications } from '@base-constants/notifications';
import { displayError } from '@base-utils/Notifications';
import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';
import NodeQueryBuilder from '@base-utils/NodeQueryBuilder';
import { cmsApi } from '@base-api/cmsApi';
import { AppState } from '@base-reducers/index';
import { IRawNode } from '@base-models/Data/types';
import { Node } from '@base-models/Data/Node';
import { setPremiumContentPageUrl } from '@base-actions/redeemVoucher';
import { GET_SUCCESS_PAGE_NODE_CONTENT, RedeemVoucherActionTypes } from '@base-store/redeemVoucher/types';

export function* getSuccessPageNodeContent(action: RedeemVoucherActionTypes) {
  try {
    const language = yield select((state: AppState) => state.settings.language);
    const projectName = yield select((state: AppState) => state.settings.projectConfig.name);
    const request = NodeQueryBuilder.queryNodeById(action.payload as string, language);
    const data = yield call(cmsApi.graphQLRequest, projectName, request, false);
    const contentNode: IRawNode = data.node;
    const result = NodeFactory.MapBySchema(projectName, contentNode);
    const premiumContentNodeId = (result as Node).fields.premium_link.value.uuid;
    yield put(setPremiumContentPageUrl(`/node/${premiumContentNodeId}`));
  } catch (e) {
    const message = Notifications.pageLoadErr;
    yield call(displayError, { message, e });
  }
}

function* redeemVoucherSaga() {
  yield takeLatest(GET_SUCCESS_PAGE_NODE_CONTENT.request, getSuccessPageNodeContent);
}

export default redeemVoucherSaga;
