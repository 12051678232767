import { GraphQLClient } from 'graphql-request';
import { purchaseApiUrl } from '@base-constants/urls';
import { IPurchaseAPIAuthentication } from '@base-models/Data/types';
import { GraphQLRequestError } from '@base-models/errors';
import {
  Locale,
  RedeemVoucherRequestData,
  RedeemVoucherResponseData,
  GetPaymentConfigRequestData,
  GetPaymentConfigResponseData,
  PaymentConfig,
  GetActivePremiumUpgradeResponseData,
  GetPaymentSessionRequestData,
  CountryCode,
  GetPaymentSessionResponseData,
  UpdatePurchaseStatusRequestData,
  UpdatePurchaseStatusResponseData,
} from './types';

const getPaymentSessionRequest = require('./requests/getPaymentSession.gql').default;

const redeemVoucherRequest = require('./requests/redeemVoucher.gql').default;

const getPaymentConfigRequest = require('./requests/getPaymentConfig.gql').default;

const getActivePremiumUpgradeRequest = require('./requests/getActivePremiumUpgrade.gql').default;

const updatePurchaseStatusRequest = require('./requests/updatePurchaseStatus.gql').default;

export default class PurchaseAPIDataProvider {
  public static GraphQLRequestError: GraphQLRequestError = new GraphQLRequestError('Purchase API request failed');

  public constructor(private authentication: IPurchaseAPIAuthentication) { }

  public getPaymentConfig = async ({
    hospitalId = '',

  }): Promise<PaymentConfig> => {
    const data: GetPaymentConfigRequestData = {
      hospitalId,
    };

    const response = await this
      .requestPurchaseApi<GetPaymentConfigRequestData, GetPaymentConfigResponseData>(
      getPaymentConfigRequest,
      data,
    );
    return response.getPaymentConfig;
  };

  public getPaymentSession = async ({
    productId = '',
    locale = Locale.de,
    countryCode = CountryCode.de,
  }): Promise<GetPaymentSessionResponseData> => {
    const data: GetPaymentSessionRequestData = {
      productId,
      locale,
      countryCode,
    };

    return this.requestPurchaseApi<GetPaymentSessionRequestData, GetPaymentSessionResponseData>(
      getPaymentSessionRequest,
      data,
    );
  };

  public redeemVoucher = async (
    voucherCode: string,
  ): Promise<RedeemVoucherResponseData> => {
    const data: RedeemVoucherRequestData = {
      voucherCode,
    };

    const response = await this
      .requestPurchaseApi<RedeemVoucherRequestData, RedeemVoucherResponseData>(
      redeemVoucherRequest,
      data,
    );

    return response;
  };

  public getActivePremiumUpgrade = async () => this
    .requestPurchaseApi<null, GetActivePremiumUpgradeResponseData>(getActivePremiumUpgradeRequest);

  public updatePurchaseStatus = async (data: UpdatePurchaseStatusRequestData) => this
    .requestPurchaseApi<UpdatePurchaseStatusRequestData,
  UpdatePurchaseStatusResponseData>(updatePurchaseStatusRequest, data);

  private requestPurchaseApi = async<T, K> (qglRequest: any, data?: T): Promise<K> => {
    try {
      await this.authentication.authenticate();
      const graphQLClient = new GraphQLClient(
        `${purchaseApiUrl}/graphql`,
        {
          headers: this.getRequestHeaders(),
          mode: 'cors',
        },
      );
      const variables = data ? { data } : undefined;
      return graphQLClient.request(qglRequest, variables);
    } catch (e) {
      throw PurchaseAPIDataProvider.GraphQLRequestError;
    }
  };

  private getRequestHeaders() {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: `Bearer ${this.authentication.getAccessToken()}`,
      'X-Device-ID': '42',
    };
  }
}
