import { useCallback, useEffect, useState } from 'react';

export const useAsync = <T, E = string>(
  asyncFunction: () => Promise<T>,
  immediate = true,
) => {
  const [status, setStatus] = useState<
  'idle' | 'pending' | 'success' | 'error'
  >('idle');
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<E | null>(null);
  const execute = useCallback(() => {
    setStatus('pending');
    setData(null);
    setError(null);
    return asyncFunction()
      .then((response: any) => {
        setData(response);
        setStatus('success');
      })
      .catch(e => {
        setError(e);
        setStatus('error');
      });
  }, [asyncFunction]);
  useEffect(() => {
    if (immediate) {
      execute();
    }
  }, [execute, immediate]);
  return {
    execute, status, data, error,
  };
};
