import { IProjectConfiguration } from '@base-models/Data/types';
import ProjectTemplates from '@base-models/Data/ProjectTemplates';
import RequestActionType from '@base-utils/requestAction';

export enum FontSize {
  Standard = '16px',
  Large = '20px',
  XLarge = '24px',
}

export type IDialogState = {
  mainContentText?: string;
  subText?: string;
  show: boolean;
};

export interface ISettingsState {
  fontSize: FontSize
  language: string
  authenticated: boolean
  loginError: string | null
  projectTemplates: ProjectTemplates
  projectConfig: IProjectConfiguration
  hospitalUuid: string
  KIS: {
    resolve: Promise<ISettingsState['KIS']>
    enabled: boolean
    url: string
  }
  resolve: Promise<IProjectConfiguration | undefined>
  configLoading: boolean
  configError: string | null
  templateError: string | null
  settingsError: string | null
  activePremiumUpgrade: { endDate: string | null } | null
  dialog: IDialogState
}

export const AUTHENTICATION = new RequestActionType('AUTH');
export const LOGOUT = new RequestActionType('LOGOUT');
export const LANGUAGE_CHANGE = new RequestActionType('LANGUAGE_CHANGE');
export const FONT_SIZE_CHANGE = new RequestActionType('FONT_SIZE_CHANGE');
export const FETCH_CONFIGURATION = new RequestActionType('FETCH_CONFIGURATION');
export const FETCH_TEMPLATES = new RequestActionType('FETCH_TEMPLATES');
export const ACTIVE_PREMIUM_UPGRADE_CHANGE = new RequestActionType('ACTIVE_PREMIUM_UPGRADE_CHANGE');
export const SHOW_APP_DIALOG = 'SHOW_APP_DIALOG';
export const HIDE_APP_DIALOG = 'HIDE_APP_DIALOG';

interface AuthAction {
  type: typeof AUTHENTICATION.request,
  payload: Partial<ISettingsState> | null,
}

interface LogoutAction {
  type: typeof LOGOUT.request,
  payload: null,
}

export interface LanguageChangeAction {
  type: typeof LANGUAGE_CHANGE,
  payload: Partial<ISettingsState>,
}

export interface FontSizeChangeAction {
  type: typeof FONT_SIZE_CHANGE.request,
  payload: Partial<ISettingsState>,
}

export interface FetchConfigurationAction {
  type: typeof FETCH_CONFIGURATION.request,
  payload: Partial<ISettingsState>,
}

export interface FetchTemplatesAction {
  type: typeof FETCH_TEMPLATES.request,
  payload: Partial<ISettingsState>,
}

export interface ActivePremiumUpgradeChangeAction {
  type: typeof ACTIVE_PREMIUM_UPGRADE_CHANGE.request
  payload: Partial<ISettingsState>,
}

export interface ShowAppDialogAction {
  type: typeof SHOW_APP_DIALOG
  payload: Partial<ISettingsState>,
}

export interface HideAppDialogAction {
  type: typeof HIDE_APP_DIALOG
  payload: Partial<ISettingsState>,
}

export type SettingsActionTypes =
  AuthAction |
  LogoutAction |
  LanguageChangeAction |
  FontSizeChangeAction |
  FetchConfigurationAction |
  FetchTemplatesAction |
  ActivePremiumUpgradeChangeAction |
  ShowAppDialogAction |
  HideAppDialogAction;
