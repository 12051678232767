
import { IHospitalization, IKISAuthentication } from '@base-models/Data/types';
import KISDataProvider from '@base-utils/KISDataProvider';
import { NoKISAccessError, KISDataMissingError } from '@base-models/errors';
import { AppState } from '@base-reducers/index';


export type Store = {
  getState: () => AppState
};

export default class BestServiceCallKISDataProvider extends KISDataProvider {
  public NoKISAccessError = new NoKISAccessError('KIS is not enabled for this hospital');

  public KISDataMissingError = new KISDataMissingError('There is no KIS data available for this patient');

  protected store!: Store;

  constructor(authentication: IKISAuthentication, store: Store) {
    super(authentication);
    this.store = store;
  }

  public async getPatientLocation(): Promise<IHospitalization> {
    await this.authentication.authenticate();
    const { url, enabled } = this.store.getState().settings.KIS;

    if (!enabled) {
      throw this.NoKISAccessError;
    }
    const data = await this.getHospitalizationData(url);
    if (data === null) {
      throw this.KISDataMissingError;
    }
    return data;
  }
}
