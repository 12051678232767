import { SchemaNames } from '@base-constants/schemas';
import {
  changeMediaTitle,
  hideMediaPlayer,
  setChannelActive,
  showMediaPlayer,
  startMediaPlayback,
  changeRadioBanner,
} from '@base-utils/MediaPlayer';
import {
  hidePinVerification,
} from '@base-utils/TemplateProcessor/Helpers/verifySkyPin';
import { ICurrentChannelOptions, loadCurrentChannelEpgData } from '@base-utils/TemplateProcessor/Helpers/loadEpg';
import { IMediaOptions } from '@base-utils/TemplateProcessor/Helpers/mediaPlayer';
import { setCurrentlyPlayingVideo } from '@base-actions/mediaViewer';
import { store } from '@base-store/index';
import { skyPinVerificationEnabled } from '../../../feature-toggles-init';

export interface IChangeChannelOptions extends ICurrentChannelOptions, IMediaOptions {
  title: string;
  schema: string;
  posterUrl?: string;
}

export const changeChannel = async ({
  src,
  autoplay,
  type,
  title,
  channelId,
  lang,
  descriptionTranslation,
  nextTranslation,
  schema,
  posterUrl = '',
}: IChangeChannelOptions) => {
  hideMediaPlayer();
  hidePinVerification();
  const isAutoplay = autoplay === 'true';
  if (skyPinVerificationEnabled() && schema === SchemaNames.MediaVideo && channelId) {
    store.dispatch(setCurrentlyPlayingVideo({
      src,
      autoplay,
      type,
      channelId,
      schema,
    }));
  } else {
    // handles audio and video which do not require pin verification process
    showMediaPlayer();
    startMediaPlayback({
      src,
      autoplay: isAutoplay,
      type,
      schema,
    });
  }
  setChannelActive(title);
  changeMediaTitle(title);
  changeRadioBanner(posterUrl);
  loadCurrentChannelEpgData({
    channelId,
    lang,
    descriptionTranslation,
    nextTranslation,
  });
};
