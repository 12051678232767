import { CachingStrategy } from './CachingStrategy';

export interface IStrategy {
  init: (sendMessage: Function, subscribe: Function) => void
  resetExistingCaches: () => void
}

export type SWMessage = {
  data: string
};

export class SWManager {
  private worker: ServiceWorkerRegistration | null = null;

  private messagesCallback: Function = () => { };

  private strategy: IStrategy | null = null;

  constructor(strategy: IStrategy) {
    this.strategy = strategy;
  }

  public async registerWorker(swUrl: string, options: Record<string, string>) {
    return navigator.serviceWorker.register(swUrl, options).then(worker => {
      this.worker = worker;
      navigator.serviceWorker.addEventListener(
        'message',
        (event: SWMessage) => this.messagesCallback(JSON.parse(event.data)),
      );
      return this.worker;
    });
  }

  private waitForWorker = (): Promise<ServiceWorker> => new Promise(resolve => {
    const checkWorker = () => {
      if (this.worker !== null && this.worker.active) {
        resolve(this.worker.active);
      } else {
        setTimeout(checkWorker, 100);
      }
    };
    checkWorker();
  });

  public async runStrategy() {
    if (this.strategy === null) {
      throw new Error('No strategy provided');
    }
    this.strategy.init(this.sendMessage, this.listenToMessages);
  }

  public sendMessage = async (type: string, payload: any = null) => {
    const worker = await this.waitForWorker();
    // console.log('sending msg to SW...', type);
    return worker.postMessage(JSON.stringify({
      type,
      payload,
    }));
  };

  public listenToMessages = (callback: Function) => {
    this.messagesCallback = callback;
  };

  public resetCache = () => {
    if (this.strategy === null) {
      throw new Error('No strategy provided');
    }
    this.strategy.resetExistingCaches();
  };
}

const cacheLifeTime = 10 * 60 * 1000; // 10 mins
const strategy = new CachingStrategy(cacheLifeTime, localStorage);
const swManager = new SWManager(strategy);
export default swManager;

if (!window['cs']) {
  Object.defineProperty(window, 'cs', {
    value: strategy,
  });
}
