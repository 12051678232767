import { IProjectConfiguration, IHospitalData } from '@base-models/Data/types';

export default class ProjectConfiguration implements IProjectConfiguration {
  availableLanguages = ['de'];

  defaultLanguage = 'de';

  name: IProjectConfiguration['name'] = '';

  analyticsTool: IProjectConfiguration['analyticsTool'] = null;

  templatesUrl: IProjectConfiguration['templatesUrl'] = '';

  privateNoteUrl: IProjectConfiguration['privateNoteUrl'] = '';

  termsAndConditionsUrl: IProjectConfiguration['termsAndConditionsUrl'] = '';

  skipConsentPageForIndividualLogin: IProjectConfiguration['skipConsentPageForIndividualLogin'] = false;

  isHelpCenterEnabled: IProjectConfiguration['isHelpCenterEnabled'] = false;

  constructor(config: IHospitalData) {
    if (config.languages) {
      this.availableLanguages = config.languages;
    }
    if (config.patientPortal?.analytics) {
      this.analyticsTool = config.patientPortal.analytics;
    }
    if (config.templatesUrl) {
      this.templatesUrl = config.templatesUrl;
    }
    if (config.hospitalConfig?.skipConsentPageForIndividualLogin) {
      this.skipConsentPageForIndividualLogin = config
        .hospitalConfig.skipConsentPageForIndividualLogin;
    }

    if (config.hospitalConfig?.isHelpCenterEnabled) {
      this.isHelpCenterEnabled = config
        .hospitalConfig.isHelpCenterEnabled;
    }
    this.privateNoteUrl = config.privateNoteUrl;
    this.termsAndConditionsUrl = config.termsAndConditionsUrl;
    this.name = config.name;
  }
}
