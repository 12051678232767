import PreviewMode from '@base-utils/PreviewMode';
import { TokenAuthentication } from './TokenAuthentication';

export default class PreviewModeAuthentication extends TokenAuthentication {
  static readonly UrlParamsKey = 'preview-token';

  static StorageKey = 'isPreviewModeAuthentication';

  static ConfirmAuthFlow = (location = window.location) => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.has(PreviewModeAuthentication.UrlParamsKey);
  };

  fetchType = 'preview-login' as const;

  getQueryStringTokenKey = () => PreviewModeAuthentication.UrlParamsKey;

  constructor(authConfig, storage) {
    super(authConfig, storage);
    this.authType = PreviewModeAuthentication.StorageKey;
  }

  public logout() {
    PreviewMode.disable();
    super.logout();
  }
}
