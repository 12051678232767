import { filterMatchingNodes } from '@base-utils/regexpBuilder';
import navNodeFields from './fragments/navigationItemData';

export default (lang = 'en') => `
{
  nodes(filter: {
    schema: {is: navigationItem}
    fields: {
      navigationItem: {
        displayName: { regex: "${filterMatchingNodes()}" }
      }
    }
  }, lang: ["${lang}"]) {
    elements {
        uuid
        isPublished
        language
        availableLanguages
        schema {
          name
        }
        ${navNodeFields}
      }
  }
}
`;
