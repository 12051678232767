import { subscribe } from '@bewatec-berlin/redux-subscriber';

import { AppState } from '@base-reducers/index';
import { getRemainingTime } from '@base-utils/formatDate';
import {
  getChannelIds,
  getChannelId,
  getShortestShow,
  renderEpgData,
  renderCurrentChannelEpgData,
  IRenderCurrentChannelEpgOptions,
} from '@base-utils/renderEpg';
import { SchemaNames } from '@base-constants/schemas';
import { hidePinVerification, triggerPinVerification } from './TemplateProcessor/Helpers/verifySkyPin';
import { startMediaPlayback } from './MediaPlayer';
import { IVideoOptions } from './TemplateProcessor/Helpers/mediaPlayer';

let epgDataChangeTimerId: any = null;
let epgCurrentChannelDataChangeTimerId: any = null;

export const subscribeToEpgDataChanges = (lang: string) => {
  const unsubscribe = subscribe('epg.epgData', (state: AppState) => {
    unsubscribe();
    const { epgData } = state.epg;
    const channelIds = getChannelIds();
    if (channelIds.length && epgData) {
      const featuredChannelsEpg = epgData.filter(channel => channelIds.includes(channel.channelId));
      if (featuredChannelsEpg.length) {
        featuredChannelsEpg.map(channel => renderEpgData({
          epgData: featuredChannelsEpg,
          channelId: channel.channelId,
          lang,
        }));
        const remainingTime = getShortestShow(featuredChannelsEpg);
        if (epgDataChangeTimerId) {
          // clear out previous timer in case it has not executed yet
          clearTimeout(epgDataChangeTimerId);
        }
        epgDataChangeTimerId = setTimeout(() => {
          epgDataChangeTimerId = null;
          window.TemplateEvents.fetchEpgData();
          subscribeToEpgDataChanges(lang);
        }, remainingTime);
      }
    }
  });
};

export const subscribeToCurrentChannelEpgChanges = (options: IRenderCurrentChannelEpgOptions) => {
  const unsubscribe = subscribe('epg.currentChannelEpgData', (state: AppState) => {
    const { currentChannelEpgData } = state.epg;
    if (currentChannelEpgData) {
      renderCurrentChannelEpgData({ currentChannelEpgData, ...options });
      triggerPinVerification(currentChannelEpgData.channelId, () => {
        const currentlyPlayingVideo = state.mediaViewer.currentlyPlayingVideo as IVideoOptions;
        hidePinVerification();
        startMediaPlayback({
          src: currentlyPlayingVideo.src,
          autoplay: currentlyPlayingVideo.autoplay,
          type: currentlyPlayingVideo.type,
          schema: SchemaNames.MediaVideo,
        });
      });
      unsubscribe();
      if (currentChannelEpgData.currentProgram?.end) {
        const remainingTime = getRemainingTime(currentChannelEpgData.currentProgram?.end);
        if (epgCurrentChannelDataChangeTimerId) {
          // clear out previous timer in case it has not executed yet
          clearTimeout(epgCurrentChannelDataChangeTimerId);
        }
        epgCurrentChannelDataChangeTimerId = setTimeout(() => {
          const currentChannelId = getChannelId();
          if (currentChannelId === currentChannelEpgData.channelId) {
            window.TemplateEvents.fetchCurrentEpgData(currentChannelEpgData.channelId);
            subscribeToCurrentChannelEpgChanges(options);
          }
        }, remainingTime);
      }
    }
  });
};
