import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ICompiledTemplate } from '@base-models/Data/types';
import { fetchNodeContent } from '@base-actions/navigation';
import { WakeLock } from '@base-components/WakeLock';
import BackendTokenAuthentication from '@base-auth/2.0/BackendTokenAuthentication';
import ConnectionCode from '@base-components/ConnectionCode';
import { toggleCodeVerificationIsShown } from '@base-actions/user';
import { DeviceTypeDetector } from '@base-utils/deviceTypeDetector';
import { CurrentNode } from '@base-store/navigation/types';
import { SchemaNames } from '@base-constants/schemas';
import { Folder } from '@base-models/Data/Folder';
import { Node as CMSNode } from '@base-models/Data/Node';

import PageViewport from './viewport';
import { useAppSelector } from '../../hooks/useAppSelector';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { getNodeTitle } from './getNodeTitle';

const Node: React.FC = () => {
  const loadingContent = useAppSelector(
    state => state.navigation.loadingContent,
  );
  const loadingContentError = useAppSelector(
    state => state.navigation.loadingContentError,
  );
  const loadingSchemas = useAppSelector(state => state.schemas.loading);
  const currentNode = useAppSelector(state => state.navigation.currentNode);
  const schemas = useAppSelector(state => state.schemas.schemas);
  const hasVerifiedCode = useAppSelector(state => state.user.hasVerifiedCode);
  const showVerificationScreen = useAppSelector(
    state => state.user.showVerificationScreen,
  );

  const templates = useAppSelector(
    state => state.settings.projectTemplates.templates as Record<
    string,
    ICompiledTemplate
    >,
  );
  const dispatch = useAppDispatch();
  const { nodeId } = useParams<{ nodeId: string }>();
  const history = useHistory();
  const title = getNodeTitle(currentNode);

  if (
    showVerificationScreen
    && !hasVerifiedCode
    && !BackendTokenAuthentication.isSharedLogin()
  ) {
    return (
      <ConnectionCode
        onAddLaterClick={() => {
          window.location.replace(
            localStorage.getItem('previousUrl') as string,
          );
          dispatch(toggleCodeVerificationIsShown(false));
        }}
      />
    );
  }
  const wakeLockEnabled = (node: CurrentNode): boolean => {
    if (node?.schema.name === SchemaNames.MediaAudio) {
      return true;
    }

    if (node?.schema.name === SchemaNames.MediaViewer && node?.isContainer) {
      return (node as Folder).children?.some(
        e => (e instanceof CMSNode && (e.schema.name === SchemaNames.MediaAudio))
      );
    }
    return false;
  };
  return (
    <>
      {wakeLockEnabled(currentNode) && DeviceTypeDetector.ios() && (
        <WakeLock title={title} />
      )}
      <PageViewport
        loadingContentError={loadingContentError}
        loading={loadingSchemas || loadingContent}
        currentNode={currentNode}
        routedNodeId={nodeId}
        schemas={schemas}
        templates={templates}
        requestNode={() => dispatch(fetchNodeContent(nodeId))}
        goHome={() => history.push('/')}
      />
    </>
  );
};

export default Node;
