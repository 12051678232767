const regExp = /<\{([A-Z_| ]+)\}>/g;

export const containsPlaceholder = (value: string) => regExp.test(value);

export const replacePlaceholders = (value: string, replaceCallback: (word: string) => string) => {
  regExp.lastIndex = 0;
  let match = regExp.exec(value);
  let newStr = value;
  while (match != null) {
    const matchingStr = match[0];
    const matchingWord = match[1];
    const ending = newStr.substring(match.index + matchingStr.length);
    const replacement = replaceCallback(matchingWord);
    newStr = `${newStr.slice(0, match.index)}${replacement}${ending}`;
    regExp.lastIndex = 0;
    match = regExp.exec(newStr);
  }
  return newStr;
};
