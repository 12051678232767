import { EpgData } from '@base-models/Data/types';
import { formatHour, TDate } from '@base-utils/formatDate';

export interface IEpgDetails {
  programStart: TDate
  nextProgramStart: TDate
  programTitle: string
  nextProgramTitle: string
  programAgeRestrictions: string
  nextProgramAgeRestrictions: string
  programDescription: string
}

const MIN_AGE = 12;

export const formatEpgData = (
  epg: EpgData, channelId: string, lang: string,
): IEpgDetails => {
  const [channel] = epg.filter(channelData => channelData.channelId === channelId);
  const start = channel?.currentProgram?.start || '';
  const nextStart = channel?.nextProgram?.start || '';
  const programTitle = channel?.currentProgram?.title || '';
  const nextProgramTitle = channel?.nextProgram?.title || '';
  const parentalRating = channel?.currentProgram?.parentalRating || '';
  const nextParentalRating = channel?.nextProgram?.parentalRating || '';
  const programDescription = channel?.currentProgram?.shortDescription || '';
  return {
    programStart: formatHour(start, lang) || '',
    nextProgramStart: formatHour(nextStart, lang) || '',
    programTitle,
    nextProgramTitle,
    programAgeRestrictions: parentalRating >= MIN_AGE ? `[Ü${parentalRating}]` : '',
    nextProgramAgeRestrictions: nextParentalRating >= MIN_AGE ? `[Ü${parentalRating}]` : '',
    programDescription,
  };
};
