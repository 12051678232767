import {
  ContentActionTypes,
  FETCH_CONSENT_PAGE,
  TOGGLE_PREVIEW_MODE,
} from '@base-store/content/types';

export function fetchConsentPage(): ContentActionTypes {
  return {
    type: FETCH_CONSENT_PAGE.request,
    payload: null,
  };
}

export function togglePreviewMode(payload: boolean): ContentActionTypes {
  return {
    type: TOGGLE_PREVIEW_MODE,
    payload,
  };
}
