import React, { FC } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, AnyAction } from 'redux';
import cn from 'classnames';

import { AppState } from '@base-reducers/index';
import { changeLanguage } from '@base-actions/settings';
import { usePortal } from '@base-context/PortalContext';
import style from './style.module.scss';

interface IProps {
  currentLanguage: string
  availableLanguages: string[]
  switchLanguage: (language: string) => void
  className?: string
  inverted?: boolean
}

const LanguageSwitch: FC<IProps> = ({
  currentLanguage,
  availableLanguages,
  switchLanguage,
  className,
  inverted,
}) => {
  const portal = usePortal();
  if (portal?.isNative) {
    return null;
  }

  return (
    <div
      className={cn(style.languageSwitch, inverted && style.languageSwitchInverted, className)}
      onClick={() => {}}
    >
      <div className={style.iconContainer}>
        <div className={cn(style.globe, inverted && style.globeInverted)} />
        <span className={style.lang}>{currentLanguage?.toUpperCase()}</span>
        <div className={cn(style.chevron, inverted && style.chevronInverted)} />
      </div>
      <ul className={cn(style.list, 'app-language-list')}>
        {availableLanguages
          .filter((lang: string) => lang !== currentLanguage)
          .map((lang: string) => (
            <li
              key={lang}
              className={`${style.listItem} app-language-item`}
            >
              <div className={style.iconContainer} onClick={() => switchLanguage(lang)}>
                <span className={style.lang}>{lang.toUpperCase()}</span>
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  currentLanguage: state.settings.language,
  availableLanguages: state.settings.projectConfig.availableLanguages,
});


const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
  switchLanguage: changeLanguage,
}, dispatch);

export default (connect(mapStateToProps, mapDispatchToProps)(LanguageSwitch));
