const SKY_PIN_VERIFICATION_ENABLED = 'skyPinVerificationEnabled';

export const skyPinVerificationEnabled = () => window['ftoggles'][SKY_PIN_VERIFICATION_ENABLED] === true;

const ftoggles = {
  [SKY_PIN_VERIFICATION_ENABLED]: true,
};

Object.freeze(ftoggles);

if (!window['ftoggles']) {
  Object.defineProperty(window, 'ftoggles', {
    configurable: false,
    value: ftoggles,
    writable: false,
  });
}
