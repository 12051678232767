
/**
 *   based on https://raw.githubusercontent.com/BEWATEC-Berlin/mesh-ui/develop/src/app/core/providers/i18n/translations_json/lang.translations.json
 *   and
 *   http://www.i18nguy.com/unicode/language-identifiers.html
 */

export const localeLanguageCodeMap = {
  de: 'de-DE',
  en: 'en-US',
  ar: 'ar-AE',
  cs: 'cs',
  da: 'da-DK',
  el: 'el-GR',
  es: 'es-ES',
  fi: 'fi-FI',
  fr: 'fr-FR',
  he: 'he',
  hr: 'hr-HR',
  it: 'it-IT',
  nl: 'nl-NL',
  pl: 'pl',
  ru: 'ru-RU',
  sv: 'sv-SE',
  tr: 'tr-TR',
  zh: 'zh-Hant',
};
