import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, AnyAction } from 'redux';

import { AppState } from '@base-reducers/index';
import { fetchConfiguration } from '@base-actions/settings';
import { fetchConsentPage as fetchConsentPageAction } from '@base-actions/content';
import Button from '@base-components/Button';
import Checkbox from '@base-components/Checkbox';
import { IConsentPageData } from '@base-store/content/types';
import Spinner from '@base-components/Spinner';
import FormContainer from '@base-components/FormContainer';
import info from '@base-assets/images/icons/circle-information.svg';
import IconLabel from '@base-components/IconLabel';
import CustomConsentsFormContainer from './CustomConsentFormContainer';

import './style.scss';

interface IConsentPageProps {
  data: IConsentPageData | null
  dataLoading: boolean
  configLoading: boolean
  fetchConfig: typeof fetchConfiguration
  fetchConsentPage: typeof fetchConsentPageAction
  handleConsent: (ppConsent: boolean, tcConsent: boolean) => void
  termsAndConditionsUrl: string
  privateNoteUrl: string
  language: string
  projectName: string
}

const ConsentsForm = ({
  data,
  dataLoading,
  configLoading,
  termsAndConditionsUrl,
  privateNoteUrl,
  fetchConfig,
  fetchConsentPage,
  handleConsent,
  language,
  projectName,
}:IConsentPageProps) => {
  useEffect(() => {
    fetchConfig();
  }, []);

  useEffect(() => {
    fetchConsentPage();
  }, [language]);

  useEffect(() => {
    const vh = window.innerHeight;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, []);

  const [ppConsent, setPPConsent] = useState(false);
  const [tcConsent, setTCConsent] = useState(false);

  const disableConsentButton = (
    (!data?.ppCheckboxHidden && !ppConsent)
    || (!data?.tcCheckboxHidden && !tcConsent)
  );

  const ppContent = (
    <>
      {data?.ppCheckbox || 'Ich akzeptiere die'}
      <a
        href={privateNoteUrl}
        rel="noopener noreferrer"
        target="_blank"
        data-test-id="privacy-note-url"
      >
        {data?.ppLink || 'Datenschutzbestimmungen'}
      </a>
    </>
  );

  const tcContent = (
    <>
      {data?.tcCheckbox || 'Ich stimme den Allgemeinen'}
      <a
        href={termsAndConditionsUrl}
        rel="noopener noreferrer"
        target="_blank"
        data-test-id="terms-conditions-url"
      >
        {data?.tcLink || 'Geschäftsbedingungen'}
      </a>
      {!data?.tcCheckbox && 'zu'}
    </>
  );

  const consentCheckboxes = (
    <div className="consents">
      {configLoading ? <Spinner /> : (
        <>
          {data?.ppCheckboxHidden ? (
            <IconLabel
              icon={info}
              text={ppContent}
              className="hint"
            />
          ) : (
            <Checkbox
              id="ppconsent"
              handleChange={setPPConsent}
              content={ppContent}
              checked={ppConsent}
            />
          )}
          {data?.tcCheckboxHidden ? (
            <IconLabel
              icon={info}
              text={tcContent}
              className="hint"
            />
          ) : (
            <Checkbox
              id="tcconsent"
              handleChange={setTCConsent}
              content={tcContent}
              checked={tcConsent}
            />
          )}
        </>
      )}
    </div>
  );

  const consentButton = (
    <Button
      className="consent-button"
      disabled={disableConsentButton}
      onClick={() => handleConsent(
        data?.ppCheckboxHidden || ppConsent,
        data?.tcCheckboxHidden || tcConsent,
      )}
      testId="give-consent"
      actionButton
      submit
    >
      {data?.buttonText || 'Fortfahren'}
    </Button>
  );

  if (dataLoading) {
    return <Spinner fullPage />;
  }

  if (!data) {
    return (
      <FormContainer>
        {consentCheckboxes}
        {consentButton}
      </FormContainer>
    );
  }

  return (
    <CustomConsentsFormContainer
      data={data}
      projectName={projectName}
      consentButton={consentButton}
      language={language}
    >
      {consentCheckboxes}
    </CustomConsentsFormContainer>
  );
};

const mapStateToProps = (state: AppState) => ({
  language: state.settings.language,
  data: state.content.consentPageData,
  dataLoading: state.content.loading,
  configLoading: state.settings.configLoading,
  termsAndConditionsUrl: state.settings.projectConfig.termsAndConditionsUrl,
  privateNoteUrl: state.settings.projectConfig.privateNoteUrl,
  projectName: state.settings.projectConfig.name!,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
  fetchConfig: fetchConfiguration,
  fetchConsentPage: fetchConsentPageAction,
}, dispatch);

export default (connect(mapStateToProps, mapDispatchToProps)(ConsentsForm));
