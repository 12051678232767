import Noty from 'noty';

import 'noty/lib/noty.css';
import 'noty/lib/themes/relax.css';

interface IOptions {
  message?: string
  e?: Error | string
  type?: Noty.Type
  timeout?: number
}

const displayNoty = ({
  message, e, type, timeout = 3000,
}: IOptions) => {
  if (message) {
    const noty = new Noty({
      type,
      theme: 'relax',
      text: message,
      timeout,
      closeWith: ['button'],
    });
    noty.show();
  }

  if (e && process.env.REACT_APP_DEBUG_MODE && process.env.NODE_ENV !== 'test') {
    console.error(e); // eslint-disable-line
  }
};

export const displayError = ({ message, e, timeout = 3000 }: IOptions) => {
  displayNoty({
    message, e, type: 'error', timeout,
  });
};

export const displayInfo = ({ message, e }: IOptions) => {
  displayNoty({ message, e, type: 'info' });
};
