import React, { FC } from 'react';
import style from './style.module.css';
import styleNotFound from '../NotFound-404/style.module.scss';

const NotAccessImage: FC = () => (
  <svg className={styleNotFound.notFoundImage} width="78" height="78" viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="39" cy="39" r="39" fill="#90ACC9" />
    <path fillRule="evenodd" clipRule="evenodd" d="M29.136 24.894a3.051 3.051 0 0 0-4.272 0 2.95 2.95 0 0 0 0 4.213L34.897 39l-10.033 9.894a2.95 2.95 0 0 0 0 4.212 3.051 3.051 0 0 0 4.272 0l10.033-9.893 9.695 9.56a3.051 3.051 0 0 0 4.272 0 2.95 2.95 0 0 0 0-4.213L43.441 39l9.695-9.56a2.95 2.95 0 0 0 0-4.213 3.051 3.051 0 0 0-4.272 0l-9.695 9.56-10.033-9.893z" fill="#fff" />
  </svg>
);

const NoAccess: FC = () => (
  <div className={styleNotFound.notFoundContainer}>
    <div className={styleNotFound.content}>
      <NotAccessImage />
      <h1 className={`${styleNotFound.headline} ${style.headline}`}>
        Access denied
      </h1>
      <div className={styleNotFound.text}>
        This application can only be accessed from the hospital network.
        Please contact the hospital for support.
      </div>
    </div>
  </div>
);

export default NoAccess;
