import { PatientDataPathMap, PatientSimplifiedLoginPathMap } from '@base-constants/patientDataPathMap';
import resolveNestedProp from '@base-utils/nestedPropResolver';
import { NoKISAccessError, NoKISConnectionVerified } from '@base-models/errors';
import { store } from '@base-store/index';
import { fetchPatientKISData } from '@base-actions/patient';
import backendAPI from '@base-api/backendAPI';
import { getNamefromToken } from '@base-api/backendAPI/tokenDecode';

export const resolvePatientData = async (keys: string[]) => {
  if (keys === PatientDataPathMap.NOT_FOUND) {
    return PatientDataPathMap.NOT_FOUND[0];
  }

  store.dispatch(fetchPatientKISData());
  const patient = await store.getState().patient.resolve;
  const me = await backendAPI.getMeData();
  if (!me.kisConnectionVerified) {
    throw new NoKISConnectionVerified('User has not verified code to connect with KIS data');
  }
  if (!patient) {
    throw new NoKISAccessError(
      'Patient data couldn\'t be resolved due to network issues or non existent KIS interface',
    );
  }
  return resolveNestedProp(patient, PatientDataPathMap.NOT_FOUND[0], ...keys);
};

export const resolveSimplifiedLoginPatientName = async (keys: string[]) => {
  if (keys === PatientSimplifiedLoginPathMap.NOT_FOUND) {
    return PatientSimplifiedLoginPathMap.NOT_FOUND[0];
  }

  const idToken = window.localStorage.getItem('idToken');
  const userData = getNamefromToken(idToken!);

  return userData[keys[0]];
};
