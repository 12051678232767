import React from 'react';
import cn from 'classnames';

import style from './style.module.scss';

interface IProp {
  className?: string;
  id?: string;
  content?: string;
  children?: any;
  disabled?: boolean;
  onClick?: any;
  testId?: string;
  backButton?: boolean;
  actionButton?: boolean;
  submit?: boolean;
  href?: string;
}

const Button = ({
  className,
  content,
  children,
  disabled,
  onClick,
  testId,
  backButton,
  actionButton,
  id,
  submit,
  href,
}: IProp) => {
  const btnClassName = cn(style.button, className, 'app-icon', {
    [style.backButton]: backButton,
    [style.actionButton]: actionButton,
    [style.linkButton]: href,
  });
  if (href) {
    return (
      <a href={href} className={btnClassName || 'button'} data-test-id={testId}>
        <span>{children || content}</span>
      </a>
    );
  }
  return (
    /* eslint-disable react/button-has-type */
    <button
      disabled={disabled}
      className={btnClassName || 'button'}
      onClick={onClick}
      type={submit ? 'submit' : 'button'}
      data-test-id={testId}
      id={id}
    >
      <span>{children || content}</span>
    </button>
  );
};

export default Button;
