import React from 'react';
import cn from 'classnames';

import style from './style.module.scss';

interface ISpinnerProps {
  className?: string
  small?: boolean
  fullPage?: boolean
}

const Spinner = ({
  className,
  small,
  fullPage,
}: ISpinnerProps) => {
  const spinnerClassName = cn(className, {
    [style.small]: small,
    [style.fullPage]: fullPage,
  });
  return (
    <div className={spinnerClassName}>
      <div className={style.spinner} />
    </div>
  );
};

export default Spinner;
