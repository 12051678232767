import {
  call,
  put,
  takeLeading,
} from 'redux-saga/effects';
import {
  FETCH_PERSONAL_PIN,
} from '@base-store/programVerification/types';
import pinApi from '@base-api/pinAPI';


function* fetchPersonalPin() {
  try {
    const pin: string = yield call(pinApi.getPersonalPin);
    yield put({
      type: FETCH_PERSONAL_PIN.success,
      payload: pin,
    });
  } catch (e) {
    yield put({
      type: FETCH_PERSONAL_PIN.fail,
    });
  }
}

function* programVerificationSaga() {
  yield takeLeading(FETCH_PERSONAL_PIN.request, fetchPersonalPin);
}

export default programVerificationSaga;
